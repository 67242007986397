/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable react/no-unused-state */

/* eslint-disable react/state-in-constructor */

/* eslint-disable react/no-did-update-set-state */
import React, { Component, ErrorInfo } from 'react';

import Error from '../../UI/Error/Error';

interface Props {
  children: React.ReactNode;
  path?: string;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null,
    errorInfo: null,
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps: Props) {
    const { path } = this.props;

    if (path !== prevProps.path) {
      this.setState({ hasError: false, error: null, errorInfo: null });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo, error });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Error message="Contact developers to fix this page." title="Unexpected error" withRefreshButton={false} />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
