import { immerable } from 'immer';

import { emptyLocaleData } from '@app/constants';
import { LocaleName } from '@app/types';
import { getLocaleName } from '@utils/common';

export class Tag implements ITag {
  id!: string;
  names = [emptyLocaleData];
  editorialOnly = false;
  isLive = false;
  ref = '';

  get primaryName(): string {
    return getLocaleName(this.names);
  }

  [immerable] = true;
}

export interface ITag {
  id: string;
  names: Omit<LocaleName, 'isPrimary'>[];
  editorialOnly: boolean;
  isLive: boolean;
  ref: string;
}
