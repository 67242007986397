import axios from 'axios';
import { plainToInstance } from 'class-transformer';

import { AnyObject, PagingType } from '@app/types';
import { IPartner, Partner, PartnerMini } from '@models/Partner';
import API from '@services/api';

import { API_BASE_URL } from './constants';

export const PARTNERS_URL = `${API_BASE_URL}/v1/partners`;

class PartnersAPI extends API {
  constructor() {
    super(PARTNERS_URL);
  }

  fetchPartners = async <Params extends AnyObject>(params: Params, page?: number): Promise<PagingType<Partner>> => {
    const data = await this.fetchPaginatedData<IPartner, Params>(params, { page }, null);
    const records = plainToInstance(Partner, data.records);

    return { ...data, records };
  };

  fetchPartner = async (id: string, populate?: boolean): Promise<Partner> => {
    const data = await this.fetchData<IPartner>(id, populate);
    return plainToInstance(Partner, data);
  };

  fetchPartnersMini = async <Params extends AnyObject>(
    params: Params,
    page?: number
  ): Promise<PagingType<PartnerMini>> => {
    const data = await this.fetchPaginatedData<PartnerMini, Params>(params, { page }, null, '/mini');
    const records = plainToInstance(PartnerMini, data.records);

    return { ...data, records };
  };

  updateMany = async (data: Partner[]) => {
    return axios.patch(`${PARTNERS_URL}/update/many`, { items: data });
  };
}

export default new PartnersAPI();
