import Section from '@components/UI/Section/Section';
import Button from '@components/UI/buttons/Button/Button';

interface ErrorProps {
  title?: string;
  message?: string;
  withRefreshButton?: boolean;
}

const Error = ({ title, message = 'Unexpected error', withRefreshButton = true }: ErrorProps): JSX.Element => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <div className="mx-14 text-gray-900 sm:mx-48">
      <Section className="py-28 sm:py-40">
        <div className="flex flex-col items-center justify-between gap-y-10">
          {title && <h3>{title}</h3>}
          <div>{message}</div>
          {withRefreshButton && <Button onClick={handleClick}>Refresh</Button>}
        </div>
      </Section>
    </div>
  );
};

export default Error;
