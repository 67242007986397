import React from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

interface SectionProps {
  children: React.ReactNode;
  className?: string;
}

const Section = ({ children, className }: SectionProps): JSX.Element => {
  return (
    <section className={twMerge(cn('my-12 rounded-lg px-6 py-12 shadow-default', className))} data-testid="section-ui">
      {children}
    </section>
  );
};

export default Section;
