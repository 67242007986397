import { immerable } from 'immer';

import { Track } from '@models/Track';

export class TrackGroup implements ITrackGroup {
  id!: string;
  ref = '';

  [immerable] = true;
}

export interface ITrackGroup {
  id: string;
  ref: string;
}

export class TrackGroupWithTracks extends TrackGroup implements ITrackGroupWithTracks {
  tracks!: Track[];

  [immerable] = true;
}

export interface ITrackGroupWithTracks extends ITrackGroup {
  tracks: Track[];
}
