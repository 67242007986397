import cn from 'classnames';

interface SpinnerProps {
  size?: number;
  color?: string;
  className?: string;
}

const Spinner = ({ size = 12, color = 'gray', className }: SpinnerProps): JSX.Element => {
  return (
    <svg
      aria-label="spinner"
      className={cn(`animate-spin overflow-hidden h-${size} w-${size} text-gray`, className)}
      fill="none"
      role="status"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <span className="sr-only">Loading...</span>
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="gray" strokeWidth="4" />
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill={color}
      />
    </svg>
  );
};

export default Spinner;
