import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

import { AnyAbility } from '@casl/ability';

interface Props {
  children: React.ReactNode;
}

interface UseAbilityProps {
  withAbility: (...args: [action: string, subject: string, field?: string]) => boolean | null;
  withoutAbility: (...args: [action: string, subject: string, field?: string]) => boolean | null;
  setAbility: Dispatch<SetStateAction<AnyAbility | null>>;
  ability: AnyAbility | null;
}

interface AbilityContextProps {
  ability: AnyAbility | null;
  setAbility: Dispatch<SetStateAction<AnyAbility | null>>;
}

export const AbilityContext = createContext({} as AbilityContextProps);

export const useAbility = (): UseAbilityProps => {
  const { ability, setAbility } = useContext(AbilityContext);
  const withAbility = (...args: [action: string, subject: string, field?: string]) => ability && ability.can(...args);
  const withoutAbility = (...args: [action: string, subject: string, field?: string]) =>
    ability && ability.cannot(...args);

  return { ability, withAbility, withoutAbility, setAbility };
};

export const AbilityProvider = ({ children }: Props): JSX.Element => {
  const [ability, setAbility] = useState<AnyAbility | null>(null);

  const contextValue = useMemo(() => ({ ability, setAbility }), [ability]);

  return <AbilityContext.Provider value={contextValue}>{children}</AbilityContext.Provider>;
};

export default AbilityContext;
