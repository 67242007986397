import { QueryClient, useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { QueryKeys } from '@app/constants';
import useToast from '@hooks/useToast';
import { Track } from '@models/Track';
import trackGroupsApi from '@services/track-groups-api';
import TracksApi from '@services/tracks-api';

import { BreadcrumbLink } from '@components/UI/Breadcrumb/Breadcrumb';
import Spinner from '@components/UI/Spinner/Spinner';

import TrackGroupDetailContainer from '@pages/track-group/TrackGroupDetailPage/TrackGroupDetailContainer/TrackGroupDetailContainer';

export const breadcrumbLinks: BreadcrumbLink[] = [
  {
    label: 'Tracks',
    path: '/track-editor',
  },
  {
    label: 'Edit track group',
  },
];

const TrackGroupEditPage = (): JSX.Element => {
  const { trackGroupId } = useParams<{ trackGroupId: string }>();
  const navigate = useNavigate();
  const { showSuccessToast } = useToast();
  const queryClient = new QueryClient();

  const {
    mutate: updateTrackGroupWithTacks,
    isLoading: isMutationLoading,
    isError: isMutationError,
    isSuccess,
  } = useMutation((tracks: Track[]) => trackGroupsApi.updateTrackGroup(trackGroupId!, tracks));

  const filters = { group: trackGroupId };

  const { data, isLoading } = useQuery<Track[]>(
    [QueryKeys.TrackGroup, { group: trackGroupId }],
    () => TracksApi.fetchRecords<Track>(filters),
    {}
  );

  const onSave = (tracks: Track[]) => {
    updateTrackGroupWithTacks(tracks);
  };

  if (isSuccess) {
    queryClient.removeQueries([QueryKeys.TrackGroup, { group: trackGroupId }]);
    navigate(`/track-groups/${trackGroupId}`);
    showSuccessToast('Track group updated successfully');
  }
  if (isMutationError) throw new Error();

  if (isLoading) return <Spinner />;

  return (
    <TrackGroupDetailContainer
      breadcrumbLinks={breadcrumbLinks}
      isSaving={isMutationLoading}
      tracks={data}
      onSave={onSave}
    />
  );
};

export default TrackGroupEditPage;
