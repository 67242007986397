import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { useAbility } from '@contexts/AbilityContext';
import { Abilities, Entities } from '@utils/ability';

import SvgIcon, { SvgIconNameType } from '@components/UI/SvgIcon/SvgIcon';

export interface NavItem {
  name: string;
  href: string;
  icon: SvgIconNameType;
  entity: Entities;
}

export enum MenuName {
  Content = 'Content',
  Editorial = 'Editorial',
  Settings = 'Settings',
  Playlists = 'Playlists',
}

export interface MainNavItemProps {
  name: MenuName;
  href: string;
  icon: SvgIconNameType;
  subItems: NavItem[];
  isCollapsed: boolean;
  expandedMenu: string;
  onClick?: () => void;
}

const MainNavItem = ({
  name,
  href,
  icon,
  subItems,
  isCollapsed,
  expandedMenu,
  onClick,
}: MainNavItemProps): JSX.Element => {
  const { withAbility } = useAbility();

  return (
    <>
      <NavLink
        key={name}
        className={({ isActive }) =>
          cn('duration-400 my-2 ml-6.5 text-lg opacity-60 transition-all ease-in-out hover:opacity-100', {
            '!opacity-100': isActive && !subItems?.length,
          })
        }
        role="link"
        to={href}
      >
        <div className="flex h-7 items-center" title={name} onClick={onClick}>
          {isCollapsed && (
            <span>
              <SvgIcon name={icon} />
            </span>
          )}
          <p className={cn('ml-3 whitespace-nowrap text-white', { hidden: isCollapsed })}>{name}</p>
        </div>
      </NavLink>
      {expandedMenu === name &&
        subItems?.map(
          (item) =>
            withAbility(Abilities.Read, item.entity) && (
              <NavLink
                key={item.name}
                className={({ isActive }) =>
                  cn(
                    'duration-400 my-2 opacity-60 transition-all ease-in-out hover:opacity-100',
                    !isCollapsed && 'ml-3',
                    {
                      '!opacity-100': isActive,
                    }
                  )
                }
                role="link"
                to={item.href}
              >
                <div
                  className={cn('flex h-6 items-center', isCollapsed ? 'ml-7' : 'ml-9')}
                  title={item.name}
                  onClick={onClick}
                >
                  <span>
                    <SvgIcon name={item.icon} size={1.2} />
                  </span>
                  <p className={cn('ml-3 whitespace-nowrap text-white', { hidden: isCollapsed })}>{item.name}</p>
                </div>
              </NavLink>
            )
        )}
    </>
  );
};
export default MainNavItem;
