import { useEffect } from 'react';

import { useUpdateEffect } from 'usehooks-ts';

import useToast from './useToast';
import { usePreviousValue } from './utility/usePreviousValue';

export const useFormValidationAlert = (
  isValid: boolean,
  submitCount: number,
  errorMessage = 'Please fill all the required fields'
) => {
  const { showErrorToast, closeToast } = useToast();
  const prevIsValid = usePreviousValue(isValid);

  useUpdateEffect(() => {
    if (submitCount > 0 && !prevIsValid && isValid) {
      closeToast();
    }
  }, [isValid, submitCount]);

  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      showErrorToast(errorMessage);
    }
  }, [isValid, submitCount, showErrorToast, errorMessage]);
};
