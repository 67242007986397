import { useCallback, useState } from 'react';

type UseLocalStorageData<ValueType> = [ValueType, (value: ValueType) => void];

export const useLocalStorage = <ValueType>(key: string, initialValue?: ValueType): UseLocalStorageData<ValueType> => {
  const [state, setState] = useState(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      try {
        setState(value);
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    [key]
  );

  return [state, setValue];
};
