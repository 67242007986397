import { useState } from 'react';

import cn from 'classnames';

import { useAbility } from '@contexts/AbilityContext';
import { useMediaStream } from '@hooks/useMediaStream';
import useToast from '@hooks/useToast';
import { Abilities, TRACK } from '@utils/ability';
import { getMediaUrl } from '@utils/files';

import MediaPreviewThumbnail from '@components/UI/MediaPreviewThumbnail/MediaPreviewThumbnail';
import Player from '@components/UI/Player/Player';

export interface TrackPreviewThumbnailProps {
  mediaId: string;
  thumbnailSrc: string;
  isAtmos?: boolean;
  size?: string;
  className?: string;
}

const TrackPreviewThumbnail = ({
  mediaId,
  thumbnailSrc,
  isAtmos,
  size = '50px',
  className = '',
}: TrackPreviewThumbnailProps): JSX.Element => {
  const { withAbility } = useAbility();
  const { showErrorToast } = useToast();

  const { mediaStreamData, isMediaStreamError: isWithFetchError } = useMediaStream(
    { id: mediaId, type: 'track' },
    { refetchOnWindowFocus: false, retryDelay: 500 }
  );

  const [shouldPlay, setShouldPlay] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);
  const [isPlayerWithError, setIsPlayerWithError] = useState<boolean>(false);

  const hasAbility = !!withAbility(Abilities.Read, TRACK);

  const handleClick = () => {
    if (!hasAbility) return;

    if (isAtmos || !mediaStreamData || !mediaStreamData.length) {
      if (isAtmos) {
        showErrorToast('Atmos tracks are not previewable in the web tool');
      } else if (!mediaStreamData || !mediaStreamData.length) {
        showErrorToast('Error when playing track. Try again later');
      }
      setIsPlayerWithError(true);
      return;
    }

    if (!shouldPlay) {
      const htmlAudioElements = document.getElementsByTagName('audio');
      if (htmlAudioElements.length > 0) {
        for (let i = 0; i < htmlAudioElements.length; i += 1) {
          htmlAudioElements[i].pause();
          htmlAudioElements[i].remove();
        }
      }

      setShouldPlay(true);
      setIsPlaying(true);
    } else if (shouldPlay && !isPlaying) {
      setIsPlaying(true);
    } else if (isPlaying) setIsPlaying(false);
  };

  const handleOnReady = () => setIsReadyToPlay(true);
  const handleError = () => setIsPlayerWithError(true);
  const handlePause = () => {
    setShouldPlay(false);
    setIsPlaying(false);
  };

  const isLoading = shouldPlay && !isReadyToPlay;
  const isWithError = isWithFetchError || isPlayerWithError;

  return (
    <div className={cn(className)} style={{ width: size }}>
      <MediaPreviewThumbnail
        isLoading={isLoading}
        isPlaying={isPlaying}
        isPreviewable={hasAbility}
        isWithError={isWithError}
        size="100%"
        thumbnailSrc={thumbnailSrc}
        onClick={handleClick}
      />
      {hasAbility && shouldPlay && !isWithError && (
        <Player
          autoplay
          playing={isPlaying}
          url={getMediaUrl(mediaStreamData!)}
          onError={handleError}
          onPause={handlePause}
          onReady={handleOnReady}
        />
      )}
    </div>
  );
};

export default TrackPreviewThumbnail;
