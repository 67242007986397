import React, { Fragment, useState } from 'react';

import { Transition } from '@headlessui/react';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

export interface TooltipProps {
  children: React.ReactNode;
  content?: string | React.ReactNode;
  wrapClassName?: string;
  className?: string;
  disabled?: boolean;
}

const Tooltip = ({ content, className, wrapClassName, disabled, children }: TooltipProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className={cn('relative inline-block', wrapClassName)}>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-0"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        show={!!content && show}
      >
        <div
          className={twMerge(
            'pointer-events-none absolute -top-12 left-1/2 z-50 -translate-x-1/2 whitespace-nowrap rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-900 shadow-lg',
            className
          )}
          role="tooltip"
        >
          {content}
        </div>
      </Transition>
      <div
        className="cursor-default"
        onMouseEnter={() => setShow(!disabled && true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
