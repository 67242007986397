import { memo, useEffect, useState } from 'react';

import { OptionType } from '@app/types';

import Select from '@components/UI/Select/Select';

export interface ActionSelectProps<TValue = string> {
  options: OptionType<TValue>[];
  onSelect: (value: TValue) => void;
  defaultValue?: TValue;
  label?: string;
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  id?: string;
  required?: boolean;
}

const ActionSelect = <TValue extends string = string>({
  options,
  onSelect,
  className,
  buttonClassName,
  labelClassName,
  label,
  defaultValue,
  id,
  required = false,
}: ActionSelectProps<TValue>): JSX.Element => {
  const [selected, setSelected] = useState<OptionType<TValue> | null>(null);

  useEffect(() => {
    if (defaultValue) {
      return setSelected(options.find((opt) => opt.value === defaultValue)!);
    }

    if (required && options.length) {
      setSelected(options[0]);
    }
  }, [options, defaultValue, required]);

  const handleChange = (option: OptionType<TValue>) => {
    setSelected(option);
    onSelect(option.value);
  };

  return (
    <Select
      buttonClassName={buttonClassName}
      className={className}
      id={id}
      label={label}
      labelClassName={labelClassName}
      options={options}
      required={required}
      value={selected}
      onChange={handleChange}
    />
  );
};

export default memo(ActionSelect) as typeof ActionSelect;
