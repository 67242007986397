import { addSeconds, format } from 'date-fns';

export const toUTC = (date: Date, hours?: number): Date => {
  const dateHours = hours ?? date.getHours();
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), dateHours));
};

export const convertUTCToLocalDate = (date: Date | null): Date | null => {
  if (!date) return null;
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const convertLocalToUTCDate = (date: Date | null, hours: number): Date | null => {
  if (!date) return null;
  return toUTC(date, hours);
};

export const convertSecondsToTimestamp = (seconds: number, formatString = 'mm:ss'): string => {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, formatString);
};

export const formatDate = (date?: string | Date | null, template = 'dd MMM yyyy'): string => {
  if (!date) return '';
  return format(new Date(date), template);
};

export const formatMillisecondsToHours = (duration: number): string => {
  if (!duration) return '0h 0m 0s';
  const seconds = duration / 1000;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}h ${minutes}m ${Math.floor(seconds % 60)}s`;
};
