import { useEffect, useState } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useForm, useWatch } from 'react-hook-form';

import { QueryKeys } from '@app/constants';
import { OptionType } from '@app/types';
import { useCountries } from '@contexts/CountryContext';
import useSearchData from '@hooks/useSearchData';
import { Track } from '@models/Track';
import TracksAPI from '@services/tracks-api';
import { searchFormResolver as resolver } from '@utils/validation';

import AvailabilityWidget from '@components/UI/AvailabilityWidget/AvailabilityWidget';
import EmptyDroppableArea from '@components/UI/EmptyDroppableArea/EmptyDroppableArea';
import Section from '@components/UI/Section/Section';
import Spinner from '@components/UI/Spinner/Spinner';
import SvgIcon from '@components/UI/SvgIcon/SvgIcon';
import Table from '@components/UI/Table/Table';
import DynamicInputField from '@components/form-fields/DynamicInputField/DynamicInputFieldDeprecated';
import SingleSelectField from '@components/form-fields/SingleSelectField/SingleSelectField';

import TrackGroupContentRow from '@pages/track-group/TrackGroupDetailPage/TrackGroupContentRow/TrackGroupContentRow';

const DROPPABLE_ID_SOURCE = 'track-source';
const DROPPABLE_ID_DESTINATION = 'track-destination';

interface FormValues {
  ids?: string;
  name?: string;
  region?: string;
}

const defaultValues: FormValues = {
  name: '',
  ids: '',
  region: undefined,
};

const searchOptions: OptionType<keyof Pick<FormValues, 'ids' | 'name'>>[] = [
  { label: 'Name', value: 'name' },
  { label: 'ID', value: 'ids' },
];

const removeTrackFromList = (list: Track[], movingTrack: Track) => list.filter((track) => track.id !== movingTrack.id);

const pushTrackToList = (list: Track[], movingTrack: Track, index: number) => [
  ...list.slice(0, index),
  movingTrack,
  ...list.slice(index),
];

export interface TrackGroupContentContainerProps {
  tracks: Track[];
  isViewOnly?: boolean;
  onTracksChange: (tracks: Track[]) => void;
}

const TrackGroupContentContainer = ({
  tracks: destinationList,
  isViewOnly = false,
  onTracksChange: setDestinationListChange,
}: TrackGroupContentContainerProps): JSX.Element => {
  const { regionOptions } = useCountries();

  const { handleSubmit, setValue, control, getValues } = useForm<FormValues>({
    resolver,
    defaultValues,
  });
  const formValues = useWatch({ control });

  const [sourceList, setSourceList] = useState<Track[]>([]);

  const { data, fetchData, isLoading } = useSearchData<Track>(
    { queryKey: QueryKeys.Tracks, populate: true },
    TracksAPI.fetchTracks,
    formValues
  );

  useEffect(() => {
    handleSubmit(fetchData)();
  }, [formValues, handleSubmit, fetchData]);

  useEffect(() => {
    if (!data) return;
    setSourceList(data);
  }, [data]);

  const handleRemoveClick = (idx: number) => {
    const movingTrack = destinationList[idx];
    setDestinationListChange(removeTrackFromList(destinationList, movingTrack));
    setSourceList(pushTrackToList(sourceList, movingTrack, 0));
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || source.droppableId === destination.droppableId) return;

    if (source.droppableId === DROPPABLE_ID_SOURCE) {
      const movingTrack = sourceList[source.index];
      setSourceList(removeTrackFromList(sourceList, movingTrack));
      setDestinationListChange(pushTrackToList(destinationList, movingTrack, destination.index));
    } else {
      const movingTrack = destinationList[source.index];
      setDestinationListChange(removeTrackFromList(destinationList, movingTrack));
      setSourceList(pushTrackToList(sourceList, movingTrack, destination.index));
    }
  };

  return (
    <Section className="py-8 px-6">
      <div className="flex flex-col gap-8">
        <DragDropContext onDragEnd={onDragEnd}>
          <h1>Track group contents</h1>
          <div className="flex gap-14">
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex justify-between border-b border-b-gray-200 pb-2.5">
                <p className="font-bold">Current items</p>
                <p>{destinationList.length} items</p>
              </div>
              <div className="max-h-[33rem] overflow-y-auto">
                <Table
                  columns={[{}, {}, {}, {}]}
                  droppableId={DROPPABLE_ID_DESTINATION}
                  emptyStateComponent={
                    <EmptyDroppableArea>
                      <div className="align-center flex w-full flex-col justify-center p-7">
                        <SvgIcon className="m-auto mb-4" color="#000" name="track" size={2.3} />
                        <p className="mb-3 text-center font-semibold text-blue-600">Build your track group here</p>
                        <p className="text-center">Use the right column to search, then drag tracks to this side</p>
                      </div>
                    </EmptyDroppableArea>
                  }
                  entities={destinationList}
                  headClassName="border-b-0"
                  RowComponent={TrackGroupContentRow}
                  rowProps={{
                    isDraggable: true,
                    onRemove: handleRemoveClick,
                    isEditMode: isViewOnly,
                  }}
                />
              </div>
              {!!destinationList.length && (
                <AvailabilityWidget
                  asSection
                  contents={destinationList}
                  title={
                    (
                      <div className="mb-5 flex flex-row gap-2">
                        <SvgIcon color="blue" name="info" />
                        <p className="font-bold">Track group availability</p>
                      </div>
                    ) as JSX.Element
                  }
                />
              )}
            </div>
            {!isViewOnly && (
              <div className="flex flex-1 flex-col gap-6">
                <div className="flex w-full border-b border-b-gray-200 pb-2.5">
                  <p className="font-bold">Add new items</p>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col items-end justify-between gap-10 sm:flex-row">
                    <DynamicInputField
                      isActionAsPlaceholder
                      merged
                      required
                      className="w-2/3"
                      label="Search"
                      options={searchOptions}
                      selectClassName="w-1/3"
                      setValue={setValue}
                      values={getValues()}
                    />
                    <SingleSelectField
                      className="w-1/3"
                      control={control}
                      label="Region"
                      name="region"
                      options={regionOptions}
                    />
                  </div>
                  {data && (
                    <>
                      <div className="flex pt-2">
                        <div className="text-sm">{`${data.length} search matches`}</div>
                      </div>
                      <div className="max-h-[33rem] overflow-y-auto">
                        <Table
                          columns={[{}, {}, {}, {}]}
                          droppableId={DROPPABLE_ID_SOURCE}
                          entities={sourceList}
                          headClassName="border-b-0"
                          RowComponent={TrackGroupContentRow}
                          rowProps={{
                            isDraggable: true,
                          }}
                        />
                      </div>
                    </>
                  )}
                  {isLoading && (
                    <div className="flex items-center justify-center py-6">
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DragDropContext>
      </div>
    </Section>
  );
};

export default TrackGroupContentContainer;
