import { memo, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { useAbility } from '@contexts/AbilityContext';
import { useAuth } from '@contexts/AuthContext';
import { Abilities, Entities } from '@utils/ability';

import MainNavItem, { MainNavItemProps, MenuName, NavItem } from '@components/UI/MainNav/MainNavItem';
import SvgIcon from '@components/UI/SvgIcon/SvgIcon';

export const contentSubItems: NavItem[] = [
  { name: 'Artists', href: '/artist-editor', icon: 'artist', entity: Entities.Artist },
  { name: 'Tracks', href: '/track-editor', icon: 'track', entity: Entities.Track },
  { name: 'Albums', href: '/album-editor', icon: 'album', entity: Entities.Album },
  { name: 'Videos', href: '/video-editor', icon: 'video', entity: Entities.Video },
  { name: 'Ingestion', href: '/ingestion', icon: 'download', entity: Entities.Ingestion },
];

export const editorialSubItems: NavItem[] = [
  { name: 'Playlists', href: '/playlist-editor', icon: 'playlist', entity: Entities.Playlist },
  { name: 'Programmed Lists', href: '/programmed-list-menu', icon: 'list', entity: Entities.ProgrammedList },
  { name: 'Blog Posts', href: '/blog-post-editor', icon: 'post', entity: Entities.BlogPost },
  { name: 'Genres', href: '/genre-editor', icon: 'genre', entity: Entities.Genre },
  { name: 'Featured Tag Lists', href: '/featured-tag-editor', icon: 'newsfeed', entity: Entities.FeaturedTag },
];

export const settingsSubItems: NavItem[] = [
  { name: 'Users', href: '/settings/users', icon: 'profile', entity: Entities.User },
  { name: 'Labels', href: '/label-editor', icon: 'path', entity: Entities.DeliveryChannel },
];

export const partnerSettingsSubItems: NavItem[] = [
  { name: 'Users', href: '/settings/users', icon: 'profile', entity: Entities.User },
  { name: 'Reset password', href: '/settings/user/reset-password', icon: 'repeat', entity: Entities.PasswordManager },
];

interface NavigationItem extends Partial<MainNavItemProps> {
  entity: Entities;
}

export const navigationItems: NavigationItem[] = [
  {
    name: MenuName.Content,
    href: '/artist-editor',
    icon: 'napster-mix',
    entity: Entities.Artist,
    subItems: contentSubItems,
  },
  {
    name: MenuName.Editorial,
    href: '/playlist-editor',
    icon: 'controls',
    entity: Entities.Playlist,
    subItems: editorialSubItems,
  },
  { name: MenuName.Settings, href: '#', icon: 'settings', entity: Entities.Settings, subItems: settingsSubItems },
];

const partnerNavigationItems: NavigationItem[] = [
  { name: MenuName.Playlists, href: '/playlist-editor', icon: 'playlist', entity: Entities.Playlist },
  {
    name: MenuName.Settings,
    href: '#',
    icon: 'settings',
    entity: Entities.Settings,
    subItems: partnerSettingsSubItems,
  },
];

const getNavigationItems = (isNapsterPartner: boolean): NavigationItem[] => {
  return isNapsterPartner ? navigationItems : partnerNavigationItems;
};

interface MainNavProps {
  isHamburgerOpen: boolean;
  setIsHamburgerOpen: (bool: boolean) => void;
}

const MainNav = ({ isHamburgerOpen, setIsHamburgerOpen }: MainNavProps): JSX.Element => {
  const { withAbility } = useAbility();
  const { user } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [expandedMenu, setExpandedMenu] = useState<MenuName>(MenuName.Content);

  return (
    <>
      <nav aria-label="Sidebar" className="sticky top-0 hidden max-h-screen bg-brand-dark lg:block">
        <div
          className={twMerge(
            cn('duration-400 flex w-20 flex-col pt-6 transition-all ease-in-out', {
              'w-[14rem]': !isCollapsed,
            })
          )}
        >
          <div className="flex flex-col">
            {getNavigationItems(!!user?.isNapsterPartner).map(
              (navItem, idx) =>
                withAbility(Abilities.Read, navItem.entity) && (
                  <MainNavItem
                    {...(navItem as MainNavItemProps)}
                    key={idx}
                    expandedMenu={expandedMenu}
                    isCollapsed={isCollapsed}
                    onClick={() => {
                      setIsHamburgerOpen(false);
                      setExpandedMenu((navItem as MainNavItemProps).name);
                    }}
                  />
                )
            )}
          </div>
          <div className="my-6 ml-6">
            <button
              className="w-fit rounded-full border border-white p-1.5 opacity-60 hover:opacity-100"
              onClick={() => setIsCollapsed((bool) => !bool)}
            >
              <SvgIcon name={isCollapsed ? 'arrow-right' : 'arrow-left'} size={1.1} />
            </button>
          </div>
        </div>
      </nav>
      <nav
        aria-label="Hamburger"
        className={`${
          isHamburgerOpen ? 'left-0 opacity-100' : '-left-full opacity-0'
        } lg:hidden" fixed z-50 flex h-screen flex-col bg-brand-dark p-10 transition-all duration-500 ease-in-out`}
      >
        {getNavigationItems(!!user?.isNapsterPartner).map(
          (navItem, idx) =>
            withAbility(Abilities.Read, navItem.entity) && (
              <MainNavItem
                {...(navItem as MainNavItemProps)}
                key={idx}
                isCollapsed={!isHamburgerOpen}
                onClick={() => setIsHamburgerOpen(false)}
              />
            )
        )}
      </nav>
    </>
  );
};

export default memo(MainNav);
