import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '@contexts/AuthContext';

import UserAvatar from '../UserAvatar/UserAvatar';

interface HeaderProps {
  isHamburgerOpen?: boolean;
  setIsHamburgerOpen?: () => void;
}

const logoSourse = `${window.location.origin}/static/napster-logo.svg`;

const Header = ({ isHamburgerOpen, setIsHamburgerOpen }: HeaderProps): JSX.Element => {
  const { user } = useAuth();

  const displayName = user?.firstName ? [user.firstName, user.lastName].join(' ') : user?.nickname;

  return (
    <header className="flex w-full shrink-0 items-center bg-brand bg-gradient py-6 pl-[calc(1.5rem-1px)] pr-6">
      <div className="mr-2 block lg:hidden">
        <span className="h-6 w-6 text-lg" onClick={setIsHamburgerOpen}>
          <FontAwesomeIcon color="white" icon={isHamburgerOpen ? faTimes : faBars} />
        </span>
      </div>
      <a href="/">
        <img alt="napster" src={logoSourse} style={{ width: '132px', height: '32px' }} />
      </a>
      {displayName && (
        <div className="ml-auto">
          <UserAvatar name={displayName} />
        </div>
      )}
    </header>
  );
};

export default Header;
