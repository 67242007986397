import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import useToast from '@hooks/useToast';
import { Track } from '@models/Track';
import trackGroupsApi from '@services/track-groups-api';

import { BreadcrumbLink } from '@components/UI/Breadcrumb/Breadcrumb';

import TrackGroupDetailContainer from '@pages/track-group/TrackGroupDetailPage/TrackGroupDetailContainer/TrackGroupDetailContainer';

export const breadcrumbLinks: BreadcrumbLink[] = [
  {
    label: 'Tracks',
    path: '/track-editor',
  },
  {
    label: 'Add a new track group',
  },
];

const TrackGroupNewPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { showSuccessToast } = useToast();

  const {
    data: mutationData,
    mutate: createTrackGroupWithTacks,
    isLoading: isMutationLoading,
    isError: isMutationError,
    isSuccess,
  } = useMutation(trackGroupsApi.createTrackGroupWithTacks);

  const onSave = (tracks: Track[]) => {
    createTrackGroupWithTacks(tracks);
  };

  if (isSuccess) {
    navigate(`/track-groups/${mutationData?.data.id}`);
    showSuccessToast('Track group created successfully');
  }
  if (isMutationError) throw new Error();

  return <TrackGroupDetailContainer breadcrumbLinks={breadcrumbLinks} isSaving={isMutationLoading} onSave={onSave} />;
};

export default TrackGroupNewPage;
