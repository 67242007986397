import { ReactNode, useEffect, useState } from 'react';

import cn from 'classnames';

import useToast from '@hooks/useToast';

import SvgIcon from '@components/UI/SvgIcon/SvgIcon';
import Button from '@components/UI/buttons/Button/Button';

export interface CopiableTableProps {
  className?: string;
  inline?: boolean;
  tableRef: React.RefObject<HTMLTableElement>;
}

const CopiableTable = ({ className, inline = false, tableRef }: CopiableTableProps): JSX.Element => {
  const [copied, setCopied] = useState<boolean>(false);
  const { showErrorToast, showSuccessToast } = useToast();

  useEffect(() => {
    if (!copied) return;

    const timeoutId = setTimeout(() => {
      setCopied(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [copied]);

  const copyContent = async (ev: React.SyntheticEvent) => {
    ev.stopPropagation();
    if (!tableRef.current) return;
    const table = tableRef.current.cloneNode(true) as HTMLTableElement;

    const header = table.querySelector('thead');
    if (header) {
      const firstColumnHeader = header.querySelector('th:first-child');
      if (firstColumnHeader) {
        firstColumnHeader.remove();
        const rows = table.querySelectorAll('tr');
        rows.forEach((row) => {
          const firstColumn = row.querySelector('td:first-child');
          if (firstColumn) {
            firstColumn.remove();
          }
        });
      }
    }
    setCopied(true);
    const clipboardData = new ClipboardItem({
      'text/html': new Blob([table.outerHTML], { type: 'text/html' }),
      'text/plain': new Blob([table.textContent!], { type: 'text/plain' }),
    });

    navigator.clipboard
      .write([clipboardData])
      .then(() => {
        showSuccessToast('Content copied to clipboard');
      })
      .catch(() => {
        showErrorToast('Unable to copy content to clipboard');
      });
  };

  const CopyButton = ({ children }: { children: ReactNode }) => (
    <Button className="align-center flex flex-row" size="small" onClick={copyContent}>
      <span className="mr-3">{copied ? 'Copied' : 'Copy results'}</span>
      {children}
    </Button>
  );

  return (
    <span
      aria-label={`Text copy ${inline ? 'inline ' : ''}wrap`}
      className={cn(className, { 'flex cursor-pointer': inline })}
      role="presentation"
      onClick={(ev) => inline && copyContent(ev)}
    >
      <CopyButton>
        <SvgIcon
          aria-label={copied ? 'Copied' : 'Copy'}
          color={inline ? '#2259FF' : '#FFF'}
          name={copied ? 'check' : 'copy'}
          size={inline ? 1.2 : 1.0}
          viewBox={copied ? '0 0 512 512' : '0 0 16 20'}
        />
      </CopyButton>
    </span>
  );
};

export default CopiableTable;
