import { plainToInstance } from 'class-transformer';

import { PagingType } from '@app/types';
import { Country, ICountry } from '@models/Country';
import API from '@services/api';

import { API_BASE_URL } from './constants';

const COUNTRIES_URL = `${API_BASE_URL}/v1/countries`;

class CountriesAPI extends API {
  url = COUNTRIES_URL;

  constructor() {
    super(COUNTRIES_URL);
  }
  fetchCountries = async <Params>(
    params: Params,
    page?: number,
    populate?: boolean | null,
    itemsPerPage?: number
  ): Promise<PagingType<Country>> => {
    const data = await this.fetchPaginatedData<ICountry, Params>(params, { page, itemsPerPage }, populate || null);
    const countries = plainToInstance(Country, data.records);

    return {
      ...data,
      records: countries,
    };
  };
}

export default new CountriesAPI();
