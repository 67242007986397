import Tag from '@components/UI/Tag/Tag';

export interface ExplicitTagProps {
  isExplicit?: boolean;
}

const ExplicitTag = ({ isExplicit = false }: ExplicitTagProps): JSX.Element => {
  if (!isExplicit) return <div />;
  return (
    <Tag ariaLabel="Explicit" bgClass="bg-black h-fit !py-0.5 !px-1 !text-xs">
      E
    </Tag>
  );
};

export default ExplicitTag;
