import { ReactNode, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { entries } from 'lodash';

import { useCountries } from '@contexts/CountryContext';
import { Album, AlbumCountry } from '@models/Album';
import { Country } from '@models/Country';
import { Track, TrackCountry } from '@models/Track';
import { Video, VideoCountry } from '@models/Video';
import { isExplicit } from '@utils/common';

import ExplicitTag from '@components/UI/ExplicitTag/ExplicitTag';
import Section from '@components/UI/Section/Section';
import TrackPreviewThumbnail from '@components/UI/TrackPreviewThumbnail/TrackPreviewThumbnail';
import ArtistNameLabel from '@components/form-fields/ArtistNameLabel/ArtistNameLabel';
import ConditionalWrapper from '@components/utils/ConditionalWrapper/ConditionalWrapper';

import VideoPreviewThumbnail from '../VideoPreviewThumbnail/VideoPreviewThumbnail';
import {
  RegionsWithCountriesAvailabilityType,
  resolveAvailableCountries,
  resolveRegionAvailability,
  resolveRegionTooltip,
  resolveRegionsWithCountriesAvailability,
} from './AvailabilityWidget.utils';
import { AvailabilityEnum, AvailabilityWidgetField } from './AvailabilityWidgetField/AvailabilityWidgetField';

export interface AvailabilityWidgetProps {
  contents: (Track | Album | Video)[];
  title: ReactNode;
  filterCountries?: (countries: Country[]) => Country[];
  showFirstContentMeta?: boolean;
  asSection?: boolean;
}

const AvailabilityWidget = ({
  contents,
  title,
  filterCountries,
  showFirstContentMeta = false,
  asSection = false,
}: AvailabilityWidgetProps): JSX.Element => {
  const { countries: apiCountries, isLoading: isCountriesLoading } = useCountries();
  const [exceptionsCount, setExceptionsCount] = useState<number>(0);
  const [regionsWithCountries, setRegionsWithCountries] = useState<RegionsWithCountriesAvailabilityType>({});

  const countries: Country[] = useMemo(
    () => (filterCountries ? filterCountries(apiCountries) : apiCountries),
    [apiCountries]
  );

  const [firstContent] = contents;
  const firstContentIsExplicit = useMemo(
    () => isExplicit<TrackCountry | VideoCountry | AlbumCountry>(firstContent?.countryData),
    [firstContent?.countryData]
  );
  const firstContentIsAlbum = useMemo(() => firstContent instanceof Album, [firstContent]);
  const firstContentIsTrack = useMemo(() => firstContent instanceof Track, [firstContent]);

  useEffect(() => {
    if (Object.keys(apiCountries).length === 0) return;
    const availableCountries = resolveAvailableCountries(contents, countries);
    const regionsWithCountriesAvailability = resolveRegionsWithCountriesAvailability(availableCountries, countries);
    setRegionsWithCountries(regionsWithCountriesAvailability);
    setExceptionsCount(
      entries(regionsWithCountriesAvailability).filter(
        ([, { availableCountries: regionAvailableCountries, unavailableCountries }]) =>
          !!regionAvailableCountries.length && !!unavailableCountries.length
      ).length
    );
  }, [contents, isCountriesLoading]);

  if (contents.length === 0) {
    return <p>No data to display</p>;
  }

  const renderAvailableRegions = (): JSX.Element => (
    <>
      {entries(regionsWithCountries).map(([region, { availableCountries, unavailableCountries }]) => {
        const available = resolveRegionAvailability(availableCountries, unavailableCountries);
        const tooltip = resolveRegionTooltip(region, availableCountries, unavailableCountries);

        return <AvailabilityWidgetField key={region} available={available} name={region} tooltip={tooltip} />;
      })}
    </>
  );

  const renderExceptionCountries = (): JSX.Element => (
    <>
      {entries(regionsWithCountries).map(([, { availableCountries, unavailableCountries }]) => {
        if (!availableCountries.length) return [];

        return unavailableCountries.map((country) => (
          <AvailabilityWidgetField
            key={country.id}
            available={AvailabilityEnum.NOT_AVAILABLE}
            name={country.name}
            tooltip={`Not available in ${country.name}`}
          />
        ));
      })}
    </>
  );

  return (
    <ConditionalWrapper condition={asSection} wrapper={(children) => <Section className="p-6">{children}</Section>}>
      <div className="flex w-max flex-col">
        {title}
        {contents.length === 1 && showFirstContentMeta && !firstContentIsAlbum && (
          <div className="flex flex-row">
            <div className="mr-3">
              {firstContentIsTrack ? (
                <TrackPreviewThumbnail
                  isAtmos={(firstContent as Track).isAtmos}
                  mediaId={firstContent.id}
                  thumbnailSrc={firstContent.primaryImageUrl}
                />
              ) : (
                <VideoPreviewThumbnail
                  isExplicit={firstContentIsExplicit}
                  mediaId={firstContent.id}
                  mediaName={firstContent.displayName}
                  thumbnailSrc={firstContent.primaryImageUrl}
                />
              )}
            </div>
            <div className="mb-5 flex flex-row items-center gap-2 font-bold">
              <span>{firstContent.displayName}</span>
              <span className="pb-5">
                <ExplicitTag isExplicit={firstContentIsExplicit} />
              </span>
              <span className="font-normal">by </span>
              <span>
                {firstContent.contributors
                  .filter((artist) => artist.isMainArtist || artist.isFeaturedArtist)
                  .map((artist) => (
                    <ArtistNameLabel
                      key={artist.value.id}
                      displayName={artist.value.displayName}
                      id={artist.value.id}
                    />
                  ))}
              </span>
            </div>
          </div>
        )}
        <div className="flex flex-row">
          <div className="flex flex-col">
            <p className="mb-3">Availability:</p>
            <div className="min-w-72 grid grid-cols-2">{renderAvailableRegions()}</div>
          </div>
        </div>
        <div className="flex flex-row">
          {exceptionsCount ? (
            <div className="flex flex-col">
              <p className="mb-3">Exceptions:</p>
              <div className={cn('grid w-60 grid-cols-2')}>{renderExceptionCountries()}</div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default AvailabilityWidget;
