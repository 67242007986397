import { InfiniteData, useInfiniteQuery } from 'react-query';

import { FetchPaginatedDataType, LoadMoreType, PageParams, PagingType, SortParams } from '@app/types';
import { getNextPageParam, getPreviousPageParam } from '@utils/pagination';

interface InfiniteQueryType<Model> {
  paginatedData: InfiniteData<PagingType<Model>> | undefined;
  fetchPaginatedData: () => FetchPaginatedDataType<Model>;
  loadMore: () => LoadMoreType<Model>;
  fetchNextPage: () => LoadMoreType<Model>;
  fetchPreviousPage: () => LoadMoreType<Model>;
  pageParams?: number;
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  isError: boolean;
  error: Error | null;
  remove: () => void;
}

export const usePaginatedData = <Model, Params>(
  query: { queryKey: string; populate?: boolean | null; sortParams?: SortParams } & PageParams,
  fetchModelData: (
    params: Params,
    pageParams?: PageParams,
    populate?: boolean | null,
    sortParams?: SortParams
  ) => Promise<PagingType<Model>>,
  params: Params,
  options = {}
): InfiniteQueryType<Model> => {
  const { queryKey, populate, sortParams, itemsPerPage, fetchAllSubsequentPages } = query;

  const {
    data: paginatedData,
    refetch: fetchPaginatedData,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    isError,
    error,
    remove,
  } = useInfiniteQuery<PagingType<Model>, Error>(
    queryKey,
    ({ pageParam }) =>
      fetchModelData({ ...params }, { itemsPerPage, fetchAllSubsequentPages, ...pageParam }, populate, sortParams),
    { enabled: false, refetchOnWindowFocus: false, ...options, getPreviousPageParam, getNextPageParam }
  );

  return {
    paginatedData,
    fetchPaginatedData,
    loadMore: fetchNextPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isFetching,
    isError,
    error,
    remove,
  };
};
