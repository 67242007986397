import axios from 'axios';

import { FIVE_MINUTES_IN_MILLISECONDS } from '../services/constants';

export const setAuthorizationHeader = (token?: string): void => {
  localStorage.setItem('token', token ?? '');

  axios.defaults.headers.common.authorization = `Bearer ${token}`;
  axios.interceptors.request.use((config) => {
    config.timeout = FIVE_MINUTES_IN_MILLISECONDS;
    config.timeoutErrorMessage = 'Timeout exceeded. Try again later';

    return config;
  });
};
