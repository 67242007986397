import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { OptionType } from '@app/types';
import { IngestionLocaleMap } from '@models/IngestionLocaleMap';
import API from '@services/ingestion-locale-map-api';
import { convertToOptions } from '@utils/options';

import { useAuth } from './AuthContext';

interface Props {
  children: React.ReactNode;
}

interface LocaleContextProps {
  locales: string[];
}

export const LocaleContext = createContext<LocaleContextProps>({
  locales: [],
});

export const useLocales = (): LocaleContextProps & { localeOptions: OptionType[] } => {
  const { locales } = useContext(LocaleContext);
  const localeOptions = convertToOptions(locales);

  return { locales, localeOptions };
};

export const LocaleProvider = ({ children }: Props): JSX.Element => {
  const { user } = useAuth();
  const { data } = useQuery(
    'locales',
    () =>
      API.fetchPaginatedData({
        general: true,
        getAll: true,
        sortType: 'ASCENDING',
        sortProperty: 'locale',
      }),
    { enabled: !!user, refetchOnWindowFocus: false }
  );
  const [locales, setLocales] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      const localesResponse = data.records as IngestionLocaleMap[];
      setLocales(localesResponse.map((item) => item.locale));
    }
  }, [data]);

  const contextValue = useMemo(() => ({ locales }), [locales]);

  return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
};

export default LocaleContext;
