import { useEffect } from 'react';

import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import { ToastType } from '@app/types';

interface ToastProps {
  message: string;
  status: ToastType;
  onClose: () => void;
  autoDeleteTime?: number;
  errors?: string[];
}

const toastTypes = {
  success: {
    background: 'bg-green-50',
    icon: faCheckCircle,
    color: '#00a656',
  },
  error: {
    background: 'bg-red-50',
    icon: faTimes,
    color: 'red',
  },
};

const Toast = ({ message, status, onClose, autoDeleteTime, errors = [] }: ToastProps): JSX.Element => {
  useEffect(() => {
    if (autoDeleteTime) setTimeout(onClose, autoDeleteTime);
  }, [onClose, autoDeleteTime]);

  return (
    <div className={cn('flex max-w-full break-words rounded-md px-4 py-5', toastTypes[status].background)} role="alert">
      <span className="mx-2">
        <FontAwesomeIcon color={toastTypes[status].color} icon={toastTypes[status].icon} />
      </span>
      <div className="ml-1 mr-3 text-gray-600">
        {message}
        {errors.map((err) => (
          <div key={err}>- {err}</div>
        ))}
      </div>
      <span className="mx-2 cursor-pointer">
        <FontAwesomeIcon className="text-gray-600" data-testid="close" icon={faTimes} onClick={onClose} />
      </span>
    </div>
  );
};

export default Toast;
