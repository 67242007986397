import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { plainToInstance } from 'class-transformer';
import { uniqBy } from 'lodash';
import { useQuery } from 'react-query';

import { SUPPORT_COUNTRIES_FOR_NAPSTER } from '@app/constants';
import { OptionType } from '@app/types';
import { Country } from '@models/Country';
import API from '@services/countries-api';
import { convertToOptions } from '@utils/options';

import { useAuth } from './AuthContext';

interface Props {
  children: React.ReactNode;
}

interface CountryContextProps {
  countries: Country[];
}

type UseCountriesResponse = CountryContextProps & {
  countryOptions: OptionType[];
  regions: string[];
  regionOptions: OptionType[];
  regionsWithCountries: Record<string, Country[]>;
  isLoading: boolean;
};

type UseCountriesAvailability = CountryContextProps & {
  countriesAvailability: Country[];
  countryOptionsAvailability: OptionType[];
  regionsAvailability: string[];
};

export const CountryContext = createContext<CountryContextProps>({
  countries: [],
});

export const useCountries = (): UseCountriesResponse => {
  const { countries } = useContext(CountryContext);
  const countryOptions = convertToOptions(countries.map(({ name }) => name));
  const regions = uniqBy(countries, 'region')
    .map(({ region }) => region)
    .filter((value) => value);
  const regionOptions = convertToOptions(regions);
  const regionsWithCountries = regions.reduce((acc, region) => {
    acc[region] = countries.filter((country) => country.region === region);
    return acc;
  }, {} as Record<string, Country[]>);

  return { countries, countryOptions, regions, regionOptions, regionsWithCountries, isLoading: !countries.length };
};

export const useCountriesAvailability = (): UseCountriesAvailability => {
  const { countries } = useContext(CountryContext);
  const countriesAvailability = countries.filter(({ name }) => SUPPORT_COUNTRIES_FOR_NAPSTER.includes(name));
  const countryOptionsAvailability = convertToOptions(countriesAvailability.map(({ name }) => name));
  const regionsAvailability = uniqBy(countriesAvailability, 'region')
    .map(({ region }) => region)
    .filter((value) => value);
  return { countries, countriesAvailability, countryOptionsAvailability, regionsAvailability };
};

export const CountryProvider = ({ children }: Props): JSX.Element => {
  const { user } = useAuth();
  const { data } = useQuery('countries', () => API.fetchCountries({}, 1, null, 100), {
    enabled: !!user,
    refetchOnWindowFocus: false,
  });
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    if (data) {
      const countriesResponse = plainToInstance(Country, data.records);
      setCountries(countriesResponse);
    }
  }, [data]);

  const contextValue = useMemo(() => ({ countries }), [countries]);

  return <CountryContext.Provider value={contextValue}>{children}</CountryContext.Provider>;
};

export default CountryContext;
