/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { plainToInstance } from 'class-transformer';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { useLocalStorage } from '@hooks/useLocalStorage';
import { User } from '@models/User';
import API from '@services/users-api';
import { UserRole, getAbility } from '@utils/ability';
import { setAuthorizationHeader } from '@utils/auth';

import Error from '../components/UI/Error/Error';
import { useAbility } from './AbilityContext';

export enum AuthType {
  AUTH0,
  OKTA,
}

interface Props {
  children: React.ReactNode;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  authType: AuthType | null;
  logout: () => void;
}

export const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  setUser: () => null,
  logout: () => null,
  authType: null,
} as AuthContextProps);

export const useAuth = (): AuthContextProps => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const location = useLocation();
  const { setAbility } = useAbility();
  const [storedUser, setStoredUser] = useLocalStorage('user-data', null);
  const [authType, setAuthType] = useState<AuthType | null>(null);
  const [user, setUser] = useState<User | null>(() => {
    if (storedUser) {
      const userData = plainToInstance(User, storedUser as Record<string, unknown>);
      setAbility(getAbility(userData.roles as UserRole[]));
      return userData;
    }
    return null;
  });

  const isAuthenticated = authType !== null;

  const auth0 = useAuth0();
  const { authState: oktaAuthState, oktaAuth } = useOktaAuth();

  const {
    isError,
    data: userProfile,
    refetch: fetchProfile,
  } = useQuery('profile', API.fetchProfile, { enabled: false });

  /* -------------------------------------------------------------------
   * Login/Authenticate logic 
  ------------------------------------------------------------------- */

  const reset = () => {
    setAuthType(null);
    setUser(null);
    setStoredUser(null);
  };

  const loginWithOkta = useCallback(() => {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
  }, [oktaAuth.setOriginalUri, oktaAuth.signInWithRedirect]);

  const loginWithAuth0 = useCallback(() => {
    auth0.loginWithRedirect();
  }, [auth0.loginWithRedirect]);

  const logout = useCallback(() => {
    if (authType === AuthType.OKTA) {
      oktaAuth.signOut();
    } else if (authType === AuthType.AUTH0) {
      auth0.logout();
    }
    reset();
  }, [authType, auth0.logout, oktaAuth.signOut]);

  const setupGlobalAuth = useCallback(async () => {
    if (auth0.isAuthenticated) {
      const accessToken = await auth0.getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        },
      });
      setAuthorizationHeader(accessToken);
      setAuthType(AuthType.AUTH0);
    } else if (oktaAuthState?.isAuthenticated) {
      setAuthorizationHeader(oktaAuthState.accessToken?.accessToken);
      setAuthType(AuthType.OKTA);
    }
  }, [oktaAuthState, auth0]);

  /* -------------------------------------------------------------------
   * Effects
  ------------------------------------------------------------------- */

  /**
   * Check if we are authenticated. If we are not authenticated
   * trigger the appropriate authentication flow okta or auth0
   */
  useEffect(() => {
    // If we are already authenticated do nothing OR if the okta
    // provider data has not yet loaded
    if (!!auth0.user || isAuthenticated || !oktaAuthState) return;
    if (auth0.isLoading) return;

    if (
      location.pathname.endsWith('partners') ||
      (user?.email &&
        !(
          user.email.endsWith('@napster.com') ||
          user.email.endsWith('@qawolf.com') ||
          user.email.endsWith('@rhapsody.com')
        ))
    ) {
      // If we are hitting the partners URL we want to try and login with
      // Auth0. We only want to trigger a new login if we are not already
      // authenticated
      !auth0.isAuthenticated && loginWithAuth0();
    } else {
      // If we are not hitting the external URL we want to try and login
      // with Okta. We only want to trigger a login if we are not already
      // authenticated
      !oktaAuthState?.isAuthenticated && !oktaAuth.isLoginRedirect() && loginWithOkta();
    }
  }, [
    isAuthenticated,
    location.pathname,
    !oktaAuthState,
    oktaAuthState?.isAuthenticated,
    oktaAuth?.isLoginRedirect,
    auth0,
    loginWithOkta,
    loginWithAuth0,
  ]);

  /**
   * Once we are authenticated set the axios authorization header and
   * set the global isAuthenticated state to true
   */
  useEffect(() => {
    if (auth0.isLoading) return;
    if (!auth0.isAuthenticated && !oktaAuthState?.isAuthenticated) return;
    setupGlobalAuth();
  }, [auth0.isAuthenticated, oktaAuthState?.isAuthenticated, auth0.isLoading]);

  oktaAuth.tokenManager.on('renewed', async () => {
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    setAuthorizationHeader(accessToken.accessToken);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setupGlobalAuth();
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  /**
   * Once we are authenticated we need to fetch the users profile from the Admin
   * API. We only want to fetch it if there is not already a user object in state
   */
  useEffect(() => {
    if (user || !isAuthenticated) return;

    // If the user profile has been successfully fetch set it in
    // state and set the users global ability state
    if (userProfile) {
      setUser(plainToInstance(User, userProfile));
      setStoredUser(userProfile);
      setAbility(getAbility(userProfile.roles));
    } else {
      fetchProfile();
    }
  }, [fetchProfile, user, userProfile, isAuthenticated]);

  /* -------------------------------------------------------------------
   * Render 
  ------------------------------------------------------------------- */

  const contextValue = useMemo(
    () => ({ isAuthenticated, user, setUser, authType, logout }),
    [user, isAuthenticated, authType, logout]
  );

  if (isError) {
    return <Error message="Cannot load user profile." title="Profile error" />;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
