import { FieldError, FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';

import Input, { InputComponentProps } from '@components/UI/Input/Input';

interface InputFieldProps extends Pick<InputComponentProps, 'onKeyDown'> {
  id?: string;
  label?: string;
  className?: string;
  fieldClassName?: string;
  type?: 'text' | 'search' | 'password' | 'email' | 'number';
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  error?: FieldError;
}

const InputField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  id,
  className,
  fieldClassName,
  placeholder,
  maxLength,
  onKeyDown,
  error,
  required = false,
  type = 'text',
  disabled = false,
}: InputFieldProps & UseControllerProps<TFieldValues, TName>): JSX.Element => {
  const { field, fieldState } = useController({ name, control });

  return (
    <Input
      aria-invalid={fieldState.isTouched && !!fieldState.error}
      className={className}
      disabled={disabled}
      error={error || fieldState.error}
      fieldClassName={fieldClassName}
      id={id}
      inputRef={field.ref}
      label={label}
      maxLength={maxLength}
      name={field.name}
      placeholder={placeholder}
      required={required}
      type={type}
      value={field.value}
      onBlur={field.onBlur}
      onChange={field.onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export default InputField;
