import { useCallback, useContext } from 'react';

import ToastContext from '@contexts/ToastContext';

interface UseToastData {
  showSuccessToast: (message: string) => void;
  showErrorToast: (message?: string, errors?: string[]) => void;
  closeToast: () => void;
}

const useToast = (): UseToastData => {
  const { setToast, closeToast } = useContext(ToastContext);

  const showSuccessToast = useCallback(
    (message: string) => {
      setToast({
        message,
        status: 'success',
      });
    },
    [setToast]
  );

  const showErrorToast = useCallback(
    (message = 'Unexpected error. Try again later', errors?: string[]) => {
      setToast({
        message,
        status: 'error',
        errors,
      });
    },
    [setToast]
  );

  return { showSuccessToast, showErrorToast, closeToast };
};

export default useToast;
