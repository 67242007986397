import { useNavigate } from 'react-router-dom';

import Button from '@components/UI/buttons/Button/Button';

interface FormControlsLayoutProps {
  isSaving: boolean;
  disabled?: boolean;
  saveBtnLabel?: string;
  cancelBtnLabel?: string;
  onCancel?: () => void;
}

const FormControlsLayout = ({
  isSaving,
  disabled,
  onCancel,
  saveBtnLabel = 'Save',
  cancelBtnLabel = 'Cancel',
}: FormControlsLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const onClick = () => navigate(-1);

  return (
    <div className="mt-12 flex justify-end gap-2">
      <Button variant="secondary" onClick={onCancel || onClick}>
        {cancelBtnLabel}
      </Button>
      <Button disabled={disabled} isLoading={isSaving} type="submit">
        {saveBtnLabel}
      </Button>
    </div>
  );
};

export default FormControlsLayout;
