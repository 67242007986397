import { Type } from 'class-transformer';
import { immerable } from 'immer';

import { Locales, emptyLocaleData } from '@app/constants';
import { GenericModelFormData, Id, LocaleData } from '@app/types';
import { getLocaleName } from '@utils/common';

export class Genre implements IGenre {
  id!: Id;
  names: LocaleData[] = [{ ...emptyLocaleData, locale: Locales.Default }];
  descriptions: LocaleData[] = [{ ...emptyLocaleData, locale: Locales.Default }];

  get displayName(): string {
    return getLocaleName(this.names);
  }

  [immerable] = true;
}

export interface IGenre {
  id: Id;
  names: LocaleData[];
  descriptions: LocaleData[];
  updatedBy?: string;
  createdOn?: string;
  updatedOn?: string;
}

export class OrderedGenre implements IOrderedGenre {
  id!: Id;
  order!: number;

  @Type(() => Genre)
  value!: Genre;
}

export interface IOrderedGenre {
  id: Id;
  order: number;
  value: Genre;
}

export interface GenreFieldData extends GenericModelFormData {
  order: number;
}

export const orderedGenreToFormData = (genre: OrderedGenre): GenreFieldData => {
  return {
    id: genre?.id,
    order: genre?.order,
    displayName: genre?.value?.displayName,
  };
};

export const genreToFormData = (genre: Genre, order: number): GenreFieldData => {
  return {
    id: genre.id,
    displayName: genre.displayName,
    order,
  };
};
