import { RefObject, createRef, useState } from 'react';

import captureVideoFrame from 'capture-video-frame';
import cn from 'classnames';
import { noop } from 'lodash';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import PlayerControls from './PlayerControls';
import PlayerProgressBar from './PlayerProgressBar';

interface PlayerProps extends ReactPlayerProps {
  url: string;
  playing?: boolean;
  autoplay?: boolean;
  playerRef?: RefObject<ReactPlayer>;
  hidden?: boolean;
  withHtmlControls?: boolean;
  withControls?: boolean;
  withProgressBar?: boolean;
  containerClassName?: string;
  onClick?: () => void;
  onScreenCaputure?: (base64: string, type: string) => void;
}

const Player = ({
  url,
  playing: externalPlaying,
  autoplay = false,
  playerRef: externalRef,
  withHtmlControls = false,
  withControls = false,
  withProgressBar = false,
  containerClassName = '',
  onClick = noop,
  onScreenCaputure = noop,
  ...reactPlayerEventProps
}: PlayerProps): JSX.Element => {
  const internalRef = createRef<ReactPlayer>();

  const [internalPlaying, setInternalPlaying] = useState<boolean>(autoplay);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);

  const ref = externalRef || internalRef;

  const handleControlPlay = () => setInternalPlaying(true);
  const handleControlPause = () => setInternalPlaying(false);
  const handleControlRelativeSeek = (jump: number) => {
    const seekTime = (ref.current?.getCurrentTime() ?? 0) + jump;
    ref.current?.seekTo(seekTime);
    setTimestamp(seekTime);
  };
  const handleControlScreenCapture = () => {
    const frame = captureVideoFrame(ref.current?.getInternalPlayer());
    onScreenCaputure(frame.dataUri, frame.blob.type);
  };
  const handleProgressBarAbsoluteSeek = (seekTo: number) => {
    ref.current?.seekTo(seekTo);
    setTimestamp(seekTo);
  };

  const playing = externalPlaying !== undefined ? externalPlaying : internalPlaying;

  const handleClick = () => {
    if (playing) handleControlPause();
    else handleControlPlay();
    onClick();
  };

  return (
    <>
      <div className={cn('player-wrapper cursor-pointer', containerClassName)} onClick={handleClick}>
        <ReactPlayer
          {...reactPlayerEventProps}
          ref={ref}
          config={{
            file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
            },
          }}
          controls={withHtmlControls}
          height="100%"
          playing={playing}
          url={url}
          width="100%"
          onDuration={(duration) => setTotalDuration(duration)}
          onProgress={({ playedSeconds }) => setTimestamp(playedSeconds)}
        />
      </div>
      {withControls && (
        <PlayerControls
          onPause={handleControlPause}
          onPlay={handleControlPlay}
          onRelativeSeek={handleControlRelativeSeek}
          onScreenCapture={handleControlScreenCapture}
        />
      )}
      {withProgressBar && (
        <PlayerProgressBar timestamp={timestamp} total={totalDuration} onAbsoluteSeek={handleProgressBarAbsoluteSeek} />
      )}
    </>
  );
};

export default Player;
