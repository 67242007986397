import { useState } from 'react';

import cn from 'classnames';

import VideoPreview from '@components/UI/VideoPreview/VideoPreview';
import Modal from '@components/UI/modals/Modal/Modal';

import MediaPreviewThumbnail from '../MediaPreviewThumbnail/MediaPreviewThumbnail';

export interface VideoPreviewThumbnailProps {
  mediaId: string;
  mediaName: string;
  artistName?: string;
  isExplicit: boolean;
  thumbnailSrc: string;
  size?: string;
  className?: string;
}

const VideoPreviewThumbnail = ({
  mediaId,
  mediaName,
  artistName,
  thumbnailSrc,
  isExplicit = false,
  size = '64px',
  className = '',
}: VideoPreviewThumbnailProps): JSX.Element => {
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  const handleClick = () => {
    setOpenPreviewModal(!openPreviewModal);
  };

  const handleCloseModal = () => setOpenPreviewModal(false);

  return (
    <div className={cn(className)} style={{ width: size }}>
      <MediaPreviewThumbnail size="100%" thumbnailSrc={thumbnailSrc} onClick={handleClick} />
      <Modal open={openPreviewModal} setOpen={setOpenPreviewModal} onClose={handleCloseModal}>
        <VideoPreview
          autoplay
          artistName={artistName}
          asSection={false}
          isExplicit={isExplicit}
          mediaId={mediaId}
          mediaName={mediaName}
        />
      </Modal>
    </div>
  );
};

export default VideoPreviewThumbnail;
