import { plainToInstance } from 'class-transformer';

import { PagingType } from '@app/types';
import { ITag, Tag } from '@models/Tag';
import API from '@services/api';

import { API_BASE_URL } from './constants';

export const TAGS_URL = `${API_BASE_URL}/v1/tags`;

class TagsAPI extends API {
  constructor() {
    super(TAGS_URL);
  }

  fetchTags = async <Params>(params: Params, page?: number, populate?: boolean | null): Promise<PagingType<Tag>> => {
    const data = await this.fetchPaginatedData<ITag, Params>(params, { page }, populate);
    const records = plainToInstance(Tag, data.records);

    return { ...data, records };
  };

  fetchTag = async (id: string, populate?: boolean): Promise<Tag> => {
    const data = await this.fetchData<ITag>(id, populate);
    return plainToInstance(Tag, data);
  };

  fetchTagsMini = async <Params>(params: Params, page?: number): Promise<PagingType<Tag>> => {
    const data = await this.fetchPaginatedData<ITag, Params>(params, { page }, false);
    const records = plainToInstance(Tag, data.records);
    return { ...data, records };
  };
}

export default new TagsAPI();
