import { ReactNode } from 'react';

export interface ConditionalWrapperProps {
  condition: boolean;
  children: ReactNode;
  wrapper: (children: ReactNode) => ReactNode;
}

const ConditionalWrapper = ({ condition, children, wrapper }: ConditionalWrapperProps): JSX.Element =>
  (condition ? wrapper(children) : children) as JSX.Element;

export default ConditionalWrapper;
