import React, { useRef } from 'react';

import { ArrowNarrowUpIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { twMerge } from 'tailwind-merge';

import { ColumnType, CommonRowProps, DataWithId } from '@app/types';
import { getNextSortType } from '@utils/common';
import { SortTypes } from '@utils/options';

import Section from '@components/UI/Section/Section';
import CopiableTable from '@components/utils/CopiableTable/CopiableTable';

interface TableProps<Entity, CustomRowProps> {
  title?: string;
  entities: Entity[];
  columns: ColumnType[];
  RowComponent: React.FC<CustomRowProps & CommonRowProps<Entity>>;
  rowProps?: CustomRowProps;
  asSection?: boolean;
  droppableId?: string;
  className?: string;
  headClassName?: string;
  isUpdating?: boolean;
  simpleStyle?: boolean;
  onSort?: (sortProperty: string, sortType: SortTypes | null) => void;
  emptyStateComponent?: JSX.Element;
  copiable?: boolean;
}

const Table = <Entity extends DataWithId, CustomRowProps>(props: TableProps<Entity, CustomRowProps>): JSX.Element => {
  const {
    title,
    entities,
    columns,
    RowComponent,
    asSection,
    droppableId,
    className,
    headClassName,
    emptyStateComponent,
    onSort,
    isUpdating = false,
    rowProps = {},
    simpleStyle = false,
    copiable = false,
  } = props;

  const ref = useRef<HTMLTableElement>(null);

  const renderDraggableTableBody = () => (
    <Droppable droppableId={droppableId!}>
      {(providedTbody) => (
        <tbody ref={providedTbody.innerRef} className="bg-white" {...providedTbody.droppableProps}>
          {!entities.length && (
            <tr className="p-4">
              {emptyStateComponent ? <td colSpan={columns.length}>{emptyStateComponent}</td> : <td>No items found</td>}
            </tr>
          )}
          {entities.map((entity, idx) => (
            <Draggable
              key={`${droppableId}-${entity.id!}-${idx}`}
              draggableId={`${droppableId}-${entity.id!}-${idx}`}
              index={idx}
            >
              {(provided, snapshot) => (
                <tr
                  ref={provided.innerRef}
                  className={!simpleStyle ? cn('tr tr-hover', { 'bg-gray-100': snapshot.isDragging }) : ''}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                >
                  <RowComponent
                    key={`${droppableId}-${entity.id!}-${idx}`}
                    data={entity}
                    idx={idx}
                    isDragging={snapshot.isDragging}
                    {...(rowProps as CustomRowProps)}
                  />
                </tr>
              )}
            </Draggable>
          ))}
          {providedTbody.placeholder}
        </tbody>
      )}
    </Droppable>
  );

  const renderColumns = () =>
    columns.map((col, idx) => {
      if (col.label) {
        return (
          <th
            key={idx}
            className={twMerge(
              cn('whitespace-nowrap px-3 py-4 text-left text-sm font-semibold capitalize tracking-wider', {
                'cursor-pointer': col.sortProperty && !!entities.length,
              })
            )}
            scope="col"
            onClick={() =>
              !!entities.length &&
              onSort &&
              col.sortProperty &&
              onSort(col.sortProperty, getNextSortType(col.sortType || null))
            }
          >
            {col.label}
            {!!entities.length && col.sortProperty && (
              <ArrowNarrowUpIcon
                className={cn(
                  'ml-1 inline-block h-4 -translate-y-0.5 scale-100',
                  col.sortType ? 'text-accent' : 'opacity-30',
                  { 'rotate-180': col.sortType === SortTypes.DESC }
                )}
              />
            )}
          </th>
        );
      }

      return <th key={idx} className="relative px-3 py-3" scope="col" />;
    });

  const renderTableBody = () => {
    if (droppableId) {
      return renderDraggableTableBody();
    }

    return (
      <tbody className="bg-white">
        {entities.map((entity, idx) => (
          <RowComponent
            key={entity.id || idx}
            data={entity}
            idx={idx}
            isOdd={(idx + 1) % 2 !== 0}
            {...(rowProps as CustomRowProps)}
          />
        ))}
        {!entities.length && !droppableId && (
          <tr>
            {emptyStateComponent ? <td colSpan={columns.length}>{emptyStateComponent}</td> : <td>No items found</td>}
          </tr>
        )}
      </tbody>
    );
  };

  const table = (
    <>
      {title && <h3 className="mt-1 mb-3">{title}</h3>}
      {copiable && <CopiableTable tableRef={ref} />}
      <table
        ref={ref}
        className={cn(
          'w-full overflow-x-auto sm:rounded-lg',
          { 'pointer-events-none opacity-50': isUpdating },
          className
        )}
      >
        <thead>
          <tr className={twMerge('border-b-2 border-b-gray-200', headClassName)}>{renderColumns()}</tr>
        </thead>
        {renderTableBody()}
      </table>
    </>
  );

  if (asSection) {
    return <Section className="p-4">{table}</Section>;
  }

  return table;
};

export default Table;
