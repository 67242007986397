import { Transform, Type } from 'class-transformer';
import produce from 'immer';
import { cloneDeep, pick, sortBy, sumBy } from 'lodash';

import { Countries, DEFAULT_LOCALE, EMPTY_IMG, Statuses, emptyLocaleData } from '@app/constants';
import { Availability, GenericModelFormData, Id, Identifier, ImageData, LocaleData, Modify } from '@app/types';
import { ArtistFieldData, RoleArtist, roleArtistToFormData } from '@models/Artist';
import { DeliveryChannel } from '@models/DeliveryChannel';
import { GenreFieldData, OrderedGenre, orderedGenreToFormData } from '@models/Genre';
import { Track } from '@models/Track';
import { Video } from '@models/Video';
import {
  getIdentifierValue,
  getLocaleName,
  getPrimaryCountryData,
  getPrimaryGenre,
  getPrimaryImageFromCountry,
  getPrimaryImageUrl,
  getSharedStatusColor,
  normalizeAvailabilities,
  sortCountriesByWw,
} from '@utils/common';
import { Identifiers } from '@utils/options';

export class AlbumCountry implements IAlbumCountry {
  country = Countries.WW;
  explicit = false;
  imprints: string[] = [];
  releaseDate: Date | null = null;
  images: ImageData[] = [];
  originalReleaseDate?: Date | null;

  @Type(() => OrderedGenre)
  genres: OrderedGenre[] = [];
}

export class Album implements IAlbum {
  id!: Id;
  status!: string;
  createdBy!: string;
  updatedBy!: string;
  type = '';
  names: LocaleData[] = [{ ...emptyLocaleData, locale: DEFAULT_LOCALE }];
  deliveryChannel: DeliveryChannel | null = null;
  groups: string[] = [];
  identifiers: Identifier[] = [];
  ref?: string;
  traceId?: string;
  overrideStartDate?: Date | null;
  isVideo?: boolean;
  isAtmos?: boolean;

  @Type(() => AlbumCountry)
  @Transform(({ value }: { value: AlbumCountry[] }) => sortCountriesByWw(value))
  countryData: AlbumCountry[] = [new AlbumCountry()];

  @Type(() => Date)
  createdOn!: Date;

  @Type(() => Date)
  updatedOn!: Date;

  @Type(() => Video)
  videos: Video[] = [];

  @Type(() => Track)
  tracks: Track[] = [];

  @Type(() => RoleArtist)
  contributors: RoleArtist[] = [];

  @Transform(({ value }: { value: Availability[] }) => normalizeAvailabilities(value))
  availability: Availability[] = [];

  get displayName(): string {
    return getLocaleName(this.names);
  }

  get primaryCountryData(): AlbumCountry {
    return getPrimaryCountryData(this.countryData);
  }

  get primaryGenre(): OrderedGenre | null {
    return getPrimaryGenre(this.primaryCountryData.genres);
  }

  get primaryImage(): ImageData | null {
    return getPrimaryImageFromCountry(this.primaryCountryData);
  }

  get primaryImageUrl(): string {
    return getPrimaryImageUrl(this.primaryImage);
  }

  get duration(): number {
    const videosDuration = sumBy(this.videos, 'videoFile.duration');
    const tracksDuration = sumBy(this.tracks, 'audioFiles[0].duration');
    return videosDuration + tracksDuration;
  }

  get externalLink(): string {
    if (this.isVideo) return `https://play.napster.com/video/${this.videos[0].id}`;
    return `https://web.napster.com/album/${this.ref}`;
  }

  static getImageUrl(album: Album, idx: number): string {
    return album.primaryCountryData.images[idx]?.imageUrl || EMPTY_IMG;
  }

  getIdentifierValue(type: Identifiers): string {
    return getIdentifierValue(type, this.identifiers);
  }

  getStatusColor(): { name: string; color: string } {
    return getSharedStatusColor(this.status as Statuses);
  }
}

export interface IAlbum {
  id: string;
  status: string;
  type: string;
  countryData: AlbumCountry[];
  deliveryChannel: DeliveryChannel | null;
  names: LocaleData[];
  identifiers: Identifier[];
  contributors: RoleArtist[];
  availability: Availability[];
  tracks: Track[];
  videos: Video[];
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
  isVideo?: boolean;
  traceId?: string;
  groups?: string[];
  overrideStartDate?: Date | null;
  ref?: string;
}

export interface IAlbumCountry {
  country: Countries;
  releaseDate: Date | null;
  explicit: boolean;
  imprints: string[];
  genres: OrderedGenre[];
  images: ImageData[];
  originalReleaseDate?: Date | null;
}

export type AlbumEditCountry = Modify<
  AlbumCountry,
  {
    imprints: string[];
    genres: {
      id: Id;
      order: number;
    }[];
  }
>;

export type AlbumEditData = Omit<
  Modify<
    AlbumFormData,
    {
      countryData: AlbumEditCountry[];
      deliveryChannel: string | null;
      contributors: {
        id: Id;
        roles: string[];
      }[];
      tracks?: {
        id: Id;
        overrideStartDate?: Date | null;
      }[];
      videos?: {
        id: Id;
        overrideStartDate?: Date | null;
      }[];
      overrideStartDate?: Date | null;
    }
  >,
  'updateTrackContributors'
>;

export interface AlbumCountryFormData {
  country: Countries;
  releaseDate: Date | null;
  imprints: { value: string }[];
  images: ImageData[];
  genres: GenreFieldData[];
  originalReleaseDate?: Date | null;
  explicit: boolean;
}

export interface AlbumFormData {
  type: string;
  overrideStartDate: Date | null;
  names: LocaleData[];
  identifiers: Identifier[];
  contributors: ArtistFieldData[];
  deliveryChannel: GenericModelFormData | null;
  countryData: AlbumCountryFormData[];
  videos: GenericModelFormData[];
  tracks: GenericModelFormData[];
  updateTrackContributors?: boolean;
}

export interface AlbumTakedownResponse {
  albums: string[];
  videos: string[];
  tracks: string[];
}

export interface AlbumMediaFilesResponse {
  images: string[];
  audios: string[];
  videos: string[];
}

export const getFormData = (albumData: Album): AlbumFormData => {
  const formFields = pick(
    albumData,
    'type',
    'overrideStartDate',
    'names',
    'identifiers',
    'contributors',
    'deliveryChannel',
    'countryData',
    'videos',
    'tracks'
  );

  const formData: unknown = produce<typeof formFields, AlbumFormData>(formFields, (draft) => {
    draft.deliveryChannel = albumData.deliveryChannel && {
      id: albumData.deliveryChannel.id,
      displayName: albumData.deliveryChannel.name,
    };
    draft.videos = albumData.videos.map((video) => ({ id: video.id, displayName: video.displayName }));
    draft.tracks = albumData.tracks.map((track) => ({ id: track.id, displayName: track.displayName }));
    draft.contributors = albumData.contributors.map(roleArtistToFormData);
    draft.countryData = albumData.countryData.map((country) => ({
      ...country,
      genres: sortBy(country.genres.map(orderedGenreToFormData), 'order'),
      imprints: country.imprints.map((imprint) => ({ value: imprint })),
    }));
  });

  return formData as AlbumFormData;
};

export const formatAlbumFormDataForApi = (formData: AlbumFormData, useDeepUpdate?: boolean): AlbumEditData => {
  const countryData = cloneDeep(formData.countryData);
  delete formData.updateTrackContributors;
  const formattedData: unknown = produce<AlbumFormData, AlbumEditData>(formData, (draft) => {
    draft.contributors = formData.contributors.map(({ id, roles, displayArtist }) => ({
      id,
      roles,
      displayArtist,
    }));
    draft.deliveryChannel = formData.deliveryChannel ? formData.deliveryChannel.id : null;
    countryData.forEach((cd, idx) => {
      draft.countryData[idx].imprints = cd.imprints.map((imprint) => imprint.value);
      draft.countryData[idx].genres = countryData[0].genres.map(({ id, order }) => ({ id, order }));
      draft.countryData[idx].originalReleaseDate = countryData[0].originalReleaseDate;
      draft.countryData[idx].images = countryData[0].images;
    });

    if (useDeepUpdate && formData.tracks.length) {
      draft.tracks = formData.tracks.map((track) => ({
        id: track.id,
        overrideStartDate: formData.overrideStartDate,
      }));
    } else {
      delete draft.tracks;
    }

    if (useDeepUpdate && formData.videos.length) {
      draft.videos = formData.videos.map((video) => ({
        id: video.id,
        overrideStartDate: formData.overrideStartDate,
      }));
    } else {
      delete draft.videos;
    }

    delete draft.overrideStartDate;
  });

  return formattedData as AlbumEditData;
};

export const albumCountryFormData: AlbumCountryFormData = {
  country: '' as Countries,
  releaseDate: null,
  originalReleaseDate: null,
  explicit: false,
  imprints: [],
  genres: [],
  images: [],
};
