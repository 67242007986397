import { SyntheticEvent } from 'react';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useMediaStream } from '@hooks/useMediaStream';
import { getMediaUrl } from '@utils/files';

import Player from '@components/UI/Player/Player';
import Section from '@components/UI/Section/Section';
import Spinner from '@components/UI/Spinner/Spinner';
import SvgIcon from '@components/UI/SvgIcon/SvgIcon';
import Tag from '@components/UI/Tag/Tag';
import ConditionalWrapper from '@components/utils/ConditionalWrapper/ConditionalWrapper';

export interface VideoPreviewProps {
  mediaId: string;
  mediaName: string;
  artistName?: string;
  isExplicit?: boolean;
  containerClassName?: string;
  playerClassname?: string;
  autoplay?: boolean;
  asSection?: boolean;
}

const VideoPreview = ({
  mediaId,
  mediaName,
  artistName,
  isExplicit = false,
  containerClassName = '',
  autoplay = false,
  playerClassname = '',
  asSection = true,
}: VideoPreviewProps): JSX.Element => {
  const navigate = useNavigate();

  const {
    mediaStreamData,
    isMediaStreamLoading: isLoading,
    isMediaStreamError: isWithError,
  } = useMediaStream({ id: mediaId, type: 'video' }, { refetchOnWindowFocus: false, retryDelay: 500 });

  const handleContainerClick = (ev: SyntheticEvent) => {
    ev.stopPropagation();
  };

  return (
    <div className={cn('cursor-default rounded bg-white shadow', containerClassName)} onClick={handleContainerClick}>
      <div className={cn({ 'p-6': !asSection })}>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col pb-4">
              <span
                className="flex cursor-pointer flex-row items-center gap-2 font-semibold"
                onClick={() => navigate(`/videos/${mediaId}`)}
              >
                {mediaName}
                {isExplicit && <Tag bgClass="bg-black mb-1 text-xs">Explicit</Tag>}
              </span>
              {artistName && (
                <span className="flex flex-row items-center gap-1 text-sm">
                  <SvgIcon color="rgb(59 130 246)" name="video" /> by {artistName}
                </span>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col items-center justify-center">
            {isLoading && !isWithError && <Spinner size={10} />}
            {isWithError && <FontAwesomeIcon icon={faWarning} size="2x" />}
            <ConditionalWrapper condition={asSection} wrapper={(children) => <Section>{children}</Section>}>
              <Player
                withProgressBar
                autoplay={autoplay}
                containerClassName={playerClassname}
                url={mediaStreamData ? getMediaUrl(mediaStreamData) : ''}
              />
            </ConditionalWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPreview;
