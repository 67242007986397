import { noop } from 'lodash';

import Button from '@components/UI/buttons/Button/Button';

export interface PlayerControlsProps {
  onPlay?: () => void;
  onPause?: () => void;
  onRelativeSeek?: (jump: number) => void;
  onScreenCapture?: () => void;
}

const PlayerControls = ({
  onPlay = noop,
  onPause = noop,
  onRelativeSeek = noop,
  onScreenCapture = noop,
}: PlayerControlsProps) => {
  return (
    <div className="mt-5 flex gap-2">
      <Button className="flex-1" onClick={onPlay}>
        Play
      </Button>
      <Button className="flex-1" onClick={onPause}>
        Pause
      </Button>
      <Button className="flex-1" onClick={() => onRelativeSeek(-10)}>
        -10
      </Button>
      <Button className="flex-1" onClick={() => onRelativeSeek(10)}>
        +10
      </Button>
      <Button className="flex-1" onClick={onScreenCapture}>
        Capture
      </Button>
    </div>
  );
};

export default PlayerControls;
