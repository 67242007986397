import produce, { immerable } from 'immer';

import { ENTERCOM_NAME, NAPSTER_PARTNER_NAME } from '@app/constants';
import { IGenericMiniResponse, Modify, OptionType } from '@app/types';
import { Role } from '@models/Role';
import { Tag } from '@models/Tag';
import { getLocaleName } from '@utils/common';
import { passwordPlaceholder } from '@utils/user';

export enum UserStatuses {
  STAGED = 'STAGED',
  PROVISIONED = 'PROVISIONED',
  ACTIVE = 'ACTIVE',
  RECOVERY = 'RECOVERY',
  LOCKED_OUT = 'LOCKED_OUT',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  SUSPENDED = 'SUSPENDED',
  DEPROVISIONED = 'DEPROVISIONED',
}

export class User implements IUser {
  id!: string;
  email = '';
  firstName = '';
  lastName = '';
  username = '';
  nickname? = '';
  roleId = '';
  roles: Role[] = [];
  status = UserStatuses.ACTIVE;
  picture? = '';
  password?: string;
  partners?: IGenericMiniResponse[];
  tags?: Tag[];

  get fullName(): string {
    if (this.firstName) return `${this.firstName} ${this.lastName}`;
    return this.nickname || 'Empty name';
  }

  get isNapsterPartner(): boolean {
    return !!this.partners?.some(({ name }) => name === NAPSTER_PARTNER_NAME);
  }

  get isEntercomPartner(): boolean {
    return !!this.partners?.some(({ name }) => name === ENTERCOM_NAME);
  }

  get isAdmin(): boolean {
    return !!this.roles.some(({ name }) => name === 'admin');
  }

  get tagsOptions(): OptionType[] {
    return this.tags?.map(({ primaryName, id }) => ({ label: primaryName, value: id })) || [];
  }

  [immerable] = true;
}

export interface IUser {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  username: string;
  nickname?: string;
  roleId: string;
  roles: Role[];
  status?: UserStatuses;
  picture?: string;
  password?: string;
  partners?: IGenericMiniResponse[];
  tags?: Tag[];
}
export type UserApiData = Modify<User, { tags: string[] }>;

export type UserEditData = Modify<
  User,
  {
    partners?: OptionType[];
    tags?: OptionType[];
    authType: string;
  }
>;

export interface UserUpdatePasswordData {
  password: string;
  oldPassword: string;
}

export const getFormData = (user: User, isEditMode: boolean): UserEditData => {
  const formattedData: unknown = produce<User, UserEditData>(user, (draft) => {
    draft.password = isEditMode ? passwordPlaceholder : '';
    draft.partners = user.partners?.map(({ id, name }) => ({ label: name, value: id }));
    draft.tags = user.tags?.map(({ id, names }) => ({ label: getLocaleName(names), value: id }));
  });
  return formattedData as UserEditData;
};
export const formatForApi = (user: UserEditData): UserApiData => {
  const formattedData: unknown = produce<UserEditData, UserApiData>(user, (draft) => {
    draft.partners = user.partners?.map(({ label, value }) => ({ name: label, id: value }));
    draft.tags = user.tags?.map(({ value }) => value) || [];
    Reflect.deleteProperty(draft, 'authType');
  });

  return formattedData as UserApiData;
};
