import { useCallback, useEffect, useState } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useLocation, useNavigate } from 'react-router-dom';

import config from '@app/auth/authConfig';
import { AuthProvider } from '@contexts/AuthContext';
import { CountryProvider } from '@contexts/CountryContext';
import { LocaleProvider } from '@contexts/LocaleContext';

import AppRoutes from './AppRoutes';
import Footer from './components/UI/Footer/Footer';
import Header from './components/UI/Header/Header';
import MainNav from './components/UI/MainNav/MainNav';

const oktaAuth = new OktaAuth(config.oidc);

const App = (): JSX.Element => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    },
    [navigate]
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Auth0Provider
        useRefreshTokens
        authorizationParams={{
          redirect_uri: `${process.env.REACT_APP_CMS_TOOLS_URL}/playlist-editor`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        }}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
        domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      >
        <AuthProvider>
          <LocaleProvider>
            <CountryProvider>
              <div className="flex min-h-screen flex-col">
                <Header
                  isHamburgerOpen={isHamburgerOpen}
                  setIsHamburgerOpen={() => setIsHamburgerOpen(!isHamburgerOpen)}
                />
                <div className="flex flex-1 bg-white">
                  <MainNav isHamburgerOpen={isHamburgerOpen} setIsHamburgerOpen={setIsHamburgerOpen} />
                  <div className="flex max-w-full flex-1 flex-col lg:max-w-[calc(100%-5rem)]">
                    <main className="flex-1 px-8 pt-7 pb-20">
                      <AppRoutes />
                    </main>
                    <Footer />
                  </div>
                </div>
              </div>
            </CountryProvider>
          </LocaleProvider>
        </AuthProvider>
      </Auth0Provider>
    </Security>
  );
};

export default App;
