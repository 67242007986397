import { Outlet } from 'react-router-dom';

import { useAuth } from '@contexts/AuthContext';

import PageLoader from '@components/UI/PageLoader/PageLoader';

export const RequiredAuth = (): JSX.Element => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return <PageLoader />;

  return <Outlet />;
};
