import { DCTypesEnum } from '@app/constants';
import { Id, OptionType } from '@app/types';
import { AccountingPartner } from '@models/AccountingPartner';
import { Partner } from '@models/Partner';

export class DeliveryChannel implements IDeliveryChannel {
  relatedDeliveryChannels?: RelatedDeliveryChannels[] | undefined;
  deliveryType?: DCTypesEnum | undefined;
  ref?: string | undefined;
  id!: Id;
  name = '';
  accountingPartner?: AccountingPartner = undefined;
  partners: IDeliveryChannelPartners[] = [];
  enabled = false;
  enabledForIngestion = false;
  deliveryLocation = '';
  directoryStructureFlag = '';
  completeFile = '';
  deliveryConfirmationEmail = '';

  get displayName() {
    return this.name;
  }
}

export interface IDeliveryChannel {
  id: Id;
  name: string;
  deliveryLocation: string;
  directoryStructureFlag: string;
  completeFile: string;
  enabled: boolean;
  deliveryConfirmationEmail: string;
  enabledForIngestion?: boolean;
  relatedDeliveryChannels?: RelatedDeliveryChannels[];
  partners: IDeliveryChannelPartners[];
  accountingPartner?: AccountingPartner;
  deliveryType?: DCTypesEnum;
  ref?: string;
}

export interface IDeliveryChannelPartners {
  id: string;
  excludedCountries: string[];
  value: Partner;
}

interface RelatedDeliveryChannels {
  id: string;
  type: string;
  value?: Partner;
}

export interface DeliveryChannelCount {
  pending: number;
  published: number;
  unpublished: number;
  total: number;
  name: string;
}

export interface DeliveryChannelsSearchParams {
  name: string;
}

export const getDeliveryChannelsSearchParams = (search: string): DeliveryChannelsSearchParams => ({
  name: search,
});

export const getDeliveryChannelsOptions = (data: { records: DeliveryChannel[] }): OptionType[] => {
  return data.records.map((deliveryChannel: DeliveryChannel) => ({
    value: deliveryChannel.id,
    label: deliveryChannel.displayName || deliveryChannel.name,
  }));
};
