import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const requiredString = (message = 'Field is required'): yup.StringSchema => yup.string().required(message);

export const requiredNumber = (message = 'Field is required'): yup.StringSchema =>
  yup.string().matches(/^\d*$/, 'Field must be a number').required(message);

export const requiredOneField: yup.TestFunction = <T>(values: T, context: yup.TestContext) => {
  if (Object.values(values).some(Boolean)) {
    return true;
  }

  return context.createError({ message: 'Field is required' });
};

export const searchFormResolver = yupResolver(yup.object().test(requiredOneField));
