import { AnyAbility, defineAbility } from '@casl/ability';

export enum Abilities {
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum Entities {
  All = 'all',
  Artist = 'Artist',
  Video = 'Video',
  Genre = 'Genre',
  User = 'User',
  Album = 'Album',
  Track = 'Track',
  ArtistRole = 'ArtistRole',
  ProgrammedList = 'ProgrammedList',
  Ingestion = 'Ingestion',
  Dashboard = 'Dashboard',
  Playlist = 'Playlist',
  BlogPost = 'BlogPost',
  FeaturedTag = 'FeaturedTag',
  DeliveryChannel = 'DeliveryChannel',
  Settings = 'Settings',
  PasswordManager = 'PasswordManager',
}

export const READ_ABILITY = 'read';
export const CREATE_ABILITY = 'create';
export const UPDATE_ABILITY = 'update';
export const DELETE_ABILITY = 'delete';

export const ALL_ENTITIES = 'all';
export const ARTIST = 'Artist';
export const VIDEO = 'Video';
export const GENRE = 'Genre';
export const USER = 'User';
export const ALBUM = 'Album';
export const TRACK = 'Track';
export const ARTIST_ROLE = 'ArtistRole';
export const PROGRAMMED_LIST = 'ProgrammedList';
export const INGESTION = 'Ingestion';
export const DASHBOARD = 'Dashboard';
export const PLAYLIST = 'Playlist';
export const BLOGPOST = 'BlogPost';
export const FEATURED_TAG = 'FeaturedTag';

export interface UserRole {
  name: 'manager' | 'editor' | 'admin' | 'viewer' | 'PlaylistMaker';
  id: string;
}

export const adminAbility = defineAbility<AnyAbility>((can) => {
  can(Abilities.Read, Entities.All);
  can(Abilities.Create, Entities.All);
  can(Abilities.Update, Entities.All);
  can(Abilities.Delete, Entities.All);
});

export const managerAbility = defineAbility<AnyAbility>((can) => {
  can(Abilities.Read, Entities.Artist);
  can(Abilities.Create, Entities.Artist);
  can(Abilities.Update, Entities.Artist);
  can(Abilities.Delete, Entities.Artist);

  can(Abilities.Read, Entities.Video);
  can(Abilities.Create, Entities.Video);
  can(Abilities.Update, Entities.Video);
  can(Abilities.Delete, Entities.Video);

  can(Abilities.Read, Entities.Genre);
  can(Abilities.Create, Entities.Genre);
  can(Abilities.Update, Entities.Genre);
  can(Abilities.Delete, Entities.Genre);

  can(Abilities.Read, Entities.Album);
  can(Abilities.Create, Entities.Album);
  can(Abilities.Update, Entities.Album);
  can(Abilities.Delete, Entities.Album);

  can(Abilities.Read, Entities.Track);
  can(Abilities.Create, Entities.Track);
  can(Abilities.Update, Entities.Track);
  can(Abilities.Delete, Entities.Track);

  can(Abilities.Read, Entities.ArtistRole);
  can(Abilities.Create, Entities.ArtistRole);
  can(Abilities.Update, Entities.ArtistRole);
  can(Abilities.Delete, Entities.ArtistRole);

  can(Abilities.Read, Entities.ProgrammedList);
  can(Abilities.Create, Entities.ProgrammedList);
  can(Abilities.Update, Entities.ProgrammedList);
  can(Abilities.Delete, Entities.ProgrammedList);

  can(Abilities.Read, Entities.Ingestion);
  can(Abilities.Create, Entities.Ingestion);
  can(Abilities.Update, Entities.Ingestion);
  can(Abilities.Delete, Entities.Ingestion);

  can(Abilities.Read, Entities.Dashboard);
  can(Abilities.Create, Entities.Dashboard);
  can(Abilities.Update, Entities.Dashboard);
  can(Abilities.Delete, Entities.Dashboard);

  can(Abilities.Read, Entities.Playlist);
  can(Abilities.Create, Entities.Playlist);
  can(Abilities.Update, Entities.Playlist);
  can(Abilities.Delete, Entities.Playlist);

  can(Abilities.Read, Entities.BlogPost);
  can(Abilities.Create, Entities.BlogPost);
  can(Abilities.Update, Entities.BlogPost);
  can(Abilities.Delete, Entities.BlogPost);

  can(Abilities.Read, Entities.FeaturedTag);
  can(Abilities.Create, Entities.FeaturedTag);
  can(Abilities.Update, Entities.FeaturedTag);
  can(Abilities.Delete, Entities.FeaturedTag);

  can(Abilities.Read, Entities.Settings);

  can(Abilities.Read, Entities.PasswordManager);
  can(Abilities.Create, Entities.PasswordManager);
  can(Abilities.Update, Entities.PasswordManager);
  can(Abilities.Delete, Entities.PasswordManager);
});

export const editorAbility = defineAbility<AnyAbility>((can) => {
  can(Abilities.Read, Entities.Artist);
  can(Abilities.Create, Entities.Artist);
  can(Abilities.Update, Entities.Artist);
  can(Abilities.Delete, Entities.Artist);

  can(Abilities.Read, Entities.Video);
  can(Abilities.Create, Entities.Video);
  can(Abilities.Update, Entities.Video);
  can(Abilities.Delete, Entities.Video);

  can(Abilities.Read, Entities.Genre);
  can(Abilities.Create, Entities.Genre);
  can(Abilities.Update, Entities.Genre);
  can(Abilities.Delete, Entities.Genre);

  can(Abilities.Read, Entities.Album);
  can(Abilities.Create, Entities.Album);
  can(Abilities.Update, Entities.Album);
  can(Abilities.Delete, Entities.Album);

  can(Abilities.Read, Entities.Track);
  can(Abilities.Create, Entities.Track);
  can(Abilities.Update, Entities.Track);
  can(Abilities.Delete, Entities.Track);

  can(Abilities.Read, Entities.ArtistRole);
  can(Abilities.Create, Entities.ArtistRole);
  can(Abilities.Update, Entities.ArtistRole);
  can(Abilities.Delete, Entities.ArtistRole);

  can(Abilities.Read, Entities.ProgrammedList);
  can(Abilities.Create, Entities.ProgrammedList);
  can(Abilities.Update, Entities.ProgrammedList);
  can(Abilities.Delete, Entities.ProgrammedList);

  can(Abilities.Read, Entities.Playlist);
  can(Abilities.Create, Entities.Playlist);
  can(Abilities.Update, Entities.Playlist);
  can(Abilities.Delete, Entities.Playlist);

  can(Abilities.Read, Entities.BlogPost);
  can(Abilities.Create, Entities.BlogPost);
  can(Abilities.Update, Entities.BlogPost);
  can(Abilities.Delete, Entities.BlogPost);

  can(Abilities.Read, Entities.FeaturedTag);
  can(Abilities.Create, Entities.FeaturedTag);
  can(Abilities.Update, Entities.FeaturedTag);
  can(Abilities.Delete, Entities.FeaturedTag);

  can(Abilities.Read, Entities.Settings);

  can(Abilities.Read, Entities.PasswordManager);
  can(Abilities.Create, Entities.PasswordManager);
  can(Abilities.Update, Entities.PasswordManager);
  can(Abilities.Delete, Entities.PasswordManager);
});

export const viewerAbility = defineAbility<AnyAbility>((can) => {
  can(Abilities.Read, Entities.Artist);
  can(Abilities.Read, Entities.Video);
  can(Abilities.Read, Entities.Genre);
  can(Abilities.Read, Entities.Album);
  can(Abilities.Read, Entities.Track);
  can(Abilities.Read, Entities.ArtistRole);
  can(Abilities.Read, Entities.ProgrammedList);
  can(Abilities.Read, Entities.Playlist);
  can(Abilities.Read, Entities.BlogPost);
  can(Abilities.Read, Entities.FeaturedTag);
  can(Abilities.Read, Entities.Settings);
  can(Abilities.Read, Entities.PasswordManager);
});

export const playlistMakerAbility = defineAbility<AnyAbility>((can) => {
  can(Abilities.Read, Entities.Playlist);
  can(Abilities.Create, Entities.Playlist);
  can(Abilities.Update, Entities.Playlist);
  can(Abilities.Delete, Entities.Playlist);

  can(Abilities.Read, Entities.Settings);
  can(Abilities.Read, Entities.PasswordManager);
});

export const roles = {
  manager: managerAbility,
  editor: editorAbility,
  viewer: viewerAbility,
  admin: adminAbility,
  PlaylistMaker: playlistMakerAbility,
};

export const getAbility = (userRoles: UserRole[]): AnyAbility => roles[userRoles[0].name];
