import { ReactNode } from 'react';

export interface EmptyDroppableAreaProps {
  children?: ReactNode;
}

const EmptyDroppableArea = ({ children }: EmptyDroppableAreaProps) => {
  return <div className="flex self-stretch rounded border border-dashed border-gray-300 bg-white">{children}</div>;
};

export default EmptyDroppableArea;
