import React from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import SvgIcon from '@components/UI/SvgIcon/SvgIcon';

export interface BreadcrumbLink {
  label: string;
  path?: string;
}

export interface BreadcrumbProps {
  links: BreadcrumbLink[];
}

export const BREADCRUMB_ARIA_LABEL = 'Breadcrumb';
export const BREADCRUMB_ARIA_CURRENT = 'page';

export const breadcrumbNonActiveLinkCn = 'font-semibold text-2xl text-gray-400';
export const breadcrumbActiveLinkCn = cn(breadcrumbNonActiveLinkCn, 'font-bold text-gray-800');
const breadcrumbSeparatorCn = 'fill-gray-400 h-5 mx-2';

const Breadcrumb = ({ links }: BreadcrumbProps): JSX.Element => {
  if (links.length === 0) {
    return <div />;
  }

  return (
    <nav aria-label={BREADCRUMB_ARIA_LABEL} className="flex items-center">
      {links.map((link, index) => {
        const isActiveLink = index === links.length - 1;
        const linkCn = cn({ [breadcrumbActiveLinkCn]: isActiveLink, [breadcrumbNonActiveLinkCn]: !isActiveLink });
        const ariaCurrent = isActiveLink ? BREADCRUMB_ARIA_CURRENT : undefined;

        return (
          <React.Fragment key={index}>
            <Link aria-current={ariaCurrent} className={linkCn} to={link.path || '#'}>
              {link.label}
            </Link>
            {index !== links.length - 1 && (
              <SvgIcon className={breadcrumbSeparatorCn} color="#9ca3af" name="chev-right" size={1.25} />
            )}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
