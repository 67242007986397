import React, { Dispatch, SetStateAction, createContext, useCallback, useMemo, useState } from 'react';

import { Transition } from '@headlessui/react';
import noop from 'lodash/noop';

import ToastComponent from '@components/UI/Toast/Toast';

type ToastStatus = 'success' | 'error';

interface Toast {
  message: string;
  status: ToastStatus;
  errors?: string[];
}

export interface ToastContextData {
  toast: Toast | null;
  setToast: Dispatch<SetStateAction<Toast | null>>;
  closeToast: () => void;
}

interface ToastProviderProps {
  children: React.ReactNode;
}

const ToastContext = createContext<ToastContextData>({
  toast: null,
  setToast: noop,
  closeToast: noop,
});

export const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
  const [toast, setToast] = useState<Toast | null>(null);

  const closeToast = useCallback(() => {
    setToast(null);
  }, []);

  const contextValue = useMemo(() => ({ toast, setToast, closeToast }), [toast, closeToast]);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Transition
        className="fixed right-0 top-4 z-20"
        enter="transition ease-in duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={!!toast}
      >
        {toast && (
          <ToastComponent
            autoDeleteTime={toast.status === 'error' ? 60000 : 3000}
            errors={toast.errors}
            message={toast.message}
            status={toast.status}
            onClose={closeToast}
          />
        )}
      </Transition>
    </ToastContext.Provider>
  );
};

export default ToastContext;
