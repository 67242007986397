const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENTID || '';
const ISSUER = process.env.ISSUER || `https://${process.env.REACT_APP_OKTA_URL_BASE}/oauth2/default`;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    services: {
      autoRenew: true,
    },
  },
};
