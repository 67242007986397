import { MediaStreamData } from '@models/File';

export const readFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(String(reader.result));
    };

    reader.onerror = reject;
  });
};

export const readImageFile = async (file: File): Promise<{ src: string; width: number; height: number }> => {
  const image = new Image();
  const imageSrc = await readFile(file);

  image.src = imageSrc;

  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve({
        src: imageSrc,
        width: image.width,
        height: image.height,
      });
    };

    image.onerror = reject;
  });
};

/**
 * `getFileExtension` returns the extension of a data url, which represents the file's data as a base64 encoded string.
 * @param fileSource data url, follows the pattern `data:[<mediatype>][;base64],<data>`
 */
export const getFileExtension = (fileSource: string): string => {
  const fileType = /[^.]*$/;
  if (fileSource.includes('https://')) return `image/${fileSource.match(fileType)?.[0]}`;
  return fileSource.split(';')[0].slice(5);
};

export const getMediaUrl = (mediaStreamData: MediaStreamData[]): string => {
  return mediaStreamData[0]?.url || '';
};
