import React, { AriaRole } from 'react';

import cn from 'classnames';

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  bgClass: string;
  labelClass?: string;
  role?: AriaRole;
  ariaLabel?: string;
}

const Tag = ({
  children,
  bgClass,
  labelClass = 'text-white',
  role = 'status',
  ariaLabel = String(children),
}: TagProps): JSX.Element => {
  return (
    <div
      aria-label={ariaLabel}
      className={cn('w-fit rounded px-2 py-1 text-center text-sm font-semibold capitalize', bgClass, labelClass)}
      role={role}
    >
      {children}
    </div>
  );
};

export default Tag;
