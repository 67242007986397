import { useEffect, useMemo } from 'react';

import omit from 'lodash/omit';
import { FetchNextPageOptions, FetchPreviousPageOptions, InfiniteData, useQueryClient } from 'react-query';
import { useUpdateEffect } from 'usehooks-ts';

import { NO_CONNECTION_ERROR, QueryKeys } from '@app/constants';
import { AnyObject, PageParams, PagingType, SortParams } from '@app/types';
import { usePaginatedData } from '@hooks/usePaginatedData';
import { useUnmountEffect } from '@hooks/utility/useUnmountEffect';
import { filterNotFoundBulkSearchValues, getValuesFromBulkSearchInput } from '@utils/bulkSearch';
import { getRecordsFromPaging } from '@utils/pagination';

import { BulkInputProps } from '@components/UI/BulkInput/BulkInput';

export interface BulkSearchFormValues {
  bulkSearchValue?: string;
  bulkSearchType?: string;
}

export type BulkSearchValuesValidation = Pick<BulkInputProps, 'invalidValues' | 'validValues'>;

export enum SearchTypes {
  Name = 'name',
  Id = 'id',
  Ref = 'ref',
  UPC = 'upc',
}

export const searchTypeOptions = [
  { value: SearchTypes.Name, label: 'Name' },
  { value: SearchTypes.Id, label: 'ID' },
  { value: SearchTypes.Ref, label: 'Ref' },
  { value: SearchTypes.UPC, label: 'UPC' },
];

export type SearchDataType<Model> = {
  data: Model[] | null;
  paginableData: InfiniteData<PagingType<Model>> | null;
  error: Error | null;
  fetchData: () => void;
  loadMore: () => void;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  fetchPreviousPage: (options?: FetchPreviousPageOptions) => void;
  invalidateData: () => void;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isFetchingNextPage: boolean;
  hasMore: boolean;
  bulkSearchValuesValidation: BulkSearchValuesValidation;
};

const useSearchData = <
  Model extends { id?: string },
  FormValues extends AnyObject & BulkSearchFormValues & PageParams = AnyObject,
  Params extends AnyObject = AnyObject
>(
  queryData: {
    queryKey: string;
    params?: Params;
    populate?: boolean | null;
    prefetch?: boolean;
    itemsPerPage?: number;
    fetchAllSubsequentPages?: boolean;
    shouldInvalidateData?: boolean | null;
    sortParams?: SortParams;
  },
  fetchPaginatedData: (params: FormValues & Params) => Promise<PagingType<Model>>,
  formValues: FormValues,
  options = {},
  normalizeParams?: (values: FormValues) => FormValues,
  isPartner?: boolean
): SearchDataType<Model> => {
  const queryClient = useQueryClient();
  const formattedParams = normalizeParams?.(formValues) || formValues;
  queryData.fetchAllSubsequentPages ||= !!formattedParams.bulkSearchValue?.length;

  let params = omit(
    { ...formattedParams, ...queryData.params },
    'searchValue',
    'searchType',
    'bulkSearchValue',
    'bulkSearchType'
  ) as FormValues & Params;
  if (queryData.queryKey === QueryKeys.Tracks && isPartner) {
    params = {
      ...params,
      isAvailableOnly: true,
    };
  }

  const {
    paginatedData: data,
    fetchPaginatedData: fetchData,
    remove: invalidateData,
    loadMore,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError: isDataError,
    error: dataError,
  } = usePaginatedData<Model, FormValues & Params>(queryData, fetchPaginatedData, params, options);

  const isError = isDataError || !navigator.onLine;
  const error = dataError || (!navigator.onLine ? NO_CONNECTION_ERROR : null);
  const records = useMemo(() => getRecordsFromPaging(data), [data]);

  useUpdateEffect(() => {
    if (queryData.params) {
      fetchData();
    }
  }, [queryData.params]);

  useUpdateEffect(() => {
    if (queryData.shouldInvalidateData === undefined || !!queryData.shouldInvalidateData) invalidateData();
  }, [formValues]);

  const bulkSearchValuesValidation = useMemo((): BulkSearchValuesValidation => {
    if (!formValues?.bulkSearchValue || !formValues?.bulkSearchType || !records)
      return { validValues: undefined, invalidValues: undefined };

    const values = getValuesFromBulkSearchInput(formValues.bulkSearchValue);

    const invalidValues = filterNotFoundBulkSearchValues(
      formValues.bulkSearchValue,
      formValues.bulkSearchType,
      records
    );

    const validValues = values.filter((value) => !invalidValues.includes(value));

    return { validValues, invalidValues };
  }, [records, formValues?.bulkSearchValue, formValues?.bulkSearchType]);

  useUnmountEffect(() => {
    invalidateData();
  });

  useEffect(() => {
    if (queryData.prefetch) {
      data?.pages[0].records.slice(0, 5).forEach((record) => {
        if (record.id) {
          queryClient.setQueryData([queryData.queryKey.slice(0, -1), record.id], () => record);
        }
      });
    }
  }, [data, queryClient, queryData.queryKey, queryData.prefetch]);

  return {
    data: records,
    paginableData: data || null,
    error,
    fetchData,
    loadMore,
    fetchNextPage,
    fetchPreviousPage,
    invalidateData,
    isLoading,
    isFetching,
    isError,
    hasMore: !!hasNextPage,
    isFetchingNextPage,
    bulkSearchValuesValidation,
  };
};

export default useSearchData;
