import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import 'reflect-metadata';

import { AbilityProvider } from '@contexts/AbilityContext';
import { ToastProvider } from '@contexts/ToastContext';

import App from './App';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <AbilityProvider>
            <App />
          </AbilityProvider>
        </ToastProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
