import { useAuth } from '@contexts/AuthContext';

import DropdownMenu from '../DropdownMenu/DropdownMenu';
import SvgIcon from '../SvgIcon/SvgIcon';

interface UserAvatarProps {
  name: string;
}

const UserAvatar = ({ name }: UserAvatarProps): JSX.Element => {
  const { logout } = useAuth();

  const profileOptions = [
    {
      label: 'Sign Out',
      value: 'sign-out',
      onClick: () => logout(),
    },
  ];

  return (
    <DropdownMenu options={profileOptions}>
      <div className="flex items-center text-white opacity-60 hover:opacity-100">
        <span className="ml-3 hidden text-base sm:flex">{name}</span>
        <div className="ml-3 rounded-full border border-white p-1.5">
          <SvgIcon name="profile" size={1.1} />
        </div>
      </div>
    </DropdownMenu>
  );
};

export default UserAvatar;
