import { Fragment, memo, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { OptionType } from '@app/types';

import Spinner from '@components/UI/Spinner/Spinner';

import SvgIcon from '../SvgIcon/SvgIcon';
import Button from '../buttons/Button/Button';

export const Select = <TOptionType extends OptionType = OptionType>({
  children,
  className,
  value,
  disabled,
  onChange,
}: {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  value: TOptionType | null;
  onChange: (option: TOptionType) => void;
}): JSX.Element => {
  return (
    <Listbox as="div" className={cn(className)} disabled={disabled} value={value} onChange={onChange}>
      {children}
    </Listbox>
  );
};

Select.Button = ({
  children,
  hasValue,
  className,
  labelClassName,
  disabled,
  isLoading,
}: {
  children: React.ReactNode;
  hasValue: boolean;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Listbox.Button
      className={twMerge(
        cn(
          'block h-11 w-full rounded bg-gray-100 p-2 text-left text-base text-gray-600 focus-within:text-gray-600 focus:outline-none focus:ring-0',
          { 'bg-gray-100': disabled },
          className
        )
      )}
    >
      {isLoading ? (
        <Spinner size={5} />
      ) : (
        <span className={cn('mr-2 block truncate', hasValue ? 'text-black-base' : 'text-gray-500', labelClassName)}>
          {children}
        </span>
      )}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex h-full items-center pr-2">
        <SvgIcon color="#4B5563" name="chev-down" size={1} title="" />
      </div>
    </Listbox.Button>
  );
};

Select.Options = ({ children, open }: { children: React.ReactNode; open: boolean }) => {
  return (
    <Transition
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={open}
    >
      <Listbox.Options
        static
        className="absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white text-sm text-black-base shadow-lg focus:outline-none"
      >
        {children}
      </Listbox.Options>
    </Transition>
  );
};

Select.Option = <TOptionType extends OptionType = OptionType>({
  option,
  className,
  labelClassName,
  isActive,
  isSelected,
  onMouseEnter,
}: {
  option: TOptionType;
  className?: string;
  labelClassName?: string;
  isActive?: boolean;
  isSelected?: boolean;
  onMouseEnter?: () => void;
}) => {
  const getIsSelected = (selected: boolean) => isSelected ?? selected;
  const getIsActive = (active: boolean) => isActive ?? active;

  return (
    <Listbox.Option
      key={option.value}
      className={({ active }) =>
        cn(
          'relative mt-0.5 cursor-pointer select-none bg-gray-100 py-3 pl-3 pr-9',
          { 'font-semibold': getIsActive(active) },
          className
        )
      }
      value={option}
      onMouseEnter={onMouseEnter}
    >
      {({ selected }) => (
        <span className={cn('block', { 'font-semibold text-accent': getIsSelected(selected) }, labelClassName)}>
          {option.label}
        </span>
      )}
    </Listbox.Option>
  );
};

export interface SelectComponentProps<TOptionType> {
  value: TOptionType | null;
  options: TOptionType[];
  onChange: (option: TOptionType) => void;
  label?: string;
  className?: string;
  buttonContainerClassName?: string;
  buttonClassName?: string;
  buttonLabelClassName?: string;
  labelClassName?: string;
  optionClassName?: string;
  optionLabelClassName?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  reset?: () => void;
  onAddNewOption?: (opt: TOptionType) => void;
}

const SelectComponent = <TOptionType extends OptionType = OptionType>({
  value,
  options,
  onChange,
  className,
  buttonContainerClassName,
  buttonClassName,
  buttonLabelClassName,
  labelClassName,
  optionClassName,
  optionLabelClassName,
  label,
  id,
  required,
  disabled,
  isLoading,
  reset,
  onAddNewOption,
}: SelectComponentProps<TOptionType>): JSX.Element => {
  const [customOptionValue, setCustomOptionValue] = useState<string>('');

  const addCustomOption = () => {
    if (onAddNewOption && customOptionValue) {
      const newOption = { label: customOptionValue, value: customOptionValue } as TOptionType;
      onAddNewOption(newOption);
      setCustomOptionValue('');
    }
  };

  return (
    <Select className={twMerge('w-full', className)} disabled={disabled} value={value} onChange={onChange}>
      {(state: { open: boolean }) => (
        <>
          {label && (
            <Listbox.Label className={cn('field-label', labelClassName)}>
              <span>
                {label} {required && '*'}
              </span>
              {value && !required && reset && (
                <button aria-label="reset" onClick={reset}>
                  <XIcon className="h-4 w-4" />
                </button>
              )}
            </Listbox.Label>
          )}
          <div className={cn('relative', buttonContainerClassName)} id={id}>
            <Select.Button
              className={buttonClassName}
              disabled={disabled}
              hasValue={!!value}
              isLoading={isLoading}
              labelClassName={buttonLabelClassName}
            >
              {value?.label || 'Select'}
            </Select.Button>
            <Select.Options open={state.open}>
              {options.map((option) => (
                <Select.Option
                  key={option.value}
                  className={optionClassName}
                  labelClassName={optionLabelClassName}
                  option={option}
                />
              ))}
              {onAddNewOption && (
                <div className="flex">
                  <input
                    className="w-full border-b-transparent border-l-transparent border-r-transparent px-3 py-3 text-sm focus:border-b-transparent focus:border-l-transparent focus:border-t-accent focus:outline-none focus:ring-0"
                    placeholder="Option"
                    type="text"
                    value={customOptionValue}
                    onChange={(ev) => setCustomOptionValue(ev.target.value)}
                    onKeyDown={(ev) => ev.code === 'Space' && ev.stopPropagation()}
                  />
                  <Button className="rounded-none" onClick={addCustomOption}>
                    Add
                  </Button>
                </div>
              )}
            </Select.Options>
          </div>
        </>
      )}
    </Select>
  );
};

export default memo(SelectComponent) as typeof SelectComponent;
