import { replace, startCase, toLower } from 'lodash';

import {
  ALL_COUNTRIES_VALUE,
  COUNTRIES,
  FAILED_TASK_STATUSES,
  IngestionTaskStatuses,
  MISSING_MEDIA_STATUSES,
  Statuses,
  VIDEO_TYPES,
} from '@app/constants';
import { OptionType } from '@app/types';

export const PUBLISHED_VALUE = 'published';
export const UNPUBLISHED_VALUE = 'unpublished';

export const ARTIST_ROLE_MAIN_ARTIST = 'MainArtist';
export const ARTIST_ROLE_FEATURED_ARTIST = 'FeaturedArtist';
export const ARTIST_ROLE_FILM_DIRECTOR = 'FilmDirector';
export const ARTIST_ROLE_FILM_PRODUCER = 'FilmProducer';
export const ARTIST_ROLE_COMPOSER = 'Composer';
export const ARTIST_ROLE_LYRICIST = 'Lyricist';
export const ARTIST_ROLE_COMPOSER_LYRICIST = 'ComposerLyricist';
export const ARTIST_ROLE_CONDUCTOR = 'Conductor (Classical music)';
export const ARTIST_ROLE_RE_MIXER = 'Re-mixer (dance)';
export const ARTIST_ROLE_DJ = 'DJ (dance)';
export const ARTIST_ROLE_PERFORMER = 'Performer';

export const CONTEMPORARIES = 'contemporaries';
export const FOLLOWERS = 'followers';
export const INFLUENCES = 'influences';
export const RELATED_PROJECTS = 'related projects';

export enum Identifiers {
  UPC = 'UPC',
  ISRC = 'ISRC',
  GRID = 'GRID',
  CATALOG = 'CATALOG',
}

export enum SortTypes {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING',
}

export const removeExcludedOptions = (options: OptionType[], excludedValues: string[]): OptionType[] => {
  return options.filter((opt) => !excludedValues.includes(opt.value));
};

export const convertToOptions = (items: string[] = [], lowerCase = false): OptionType[] =>
  items.map((item) => ({ value: lowerCase ? item.toLowerCase() : item, label: item }));

export const constantsToOptions = (items: string[]): OptionType[] => {
  return items.map((item) => {
    return {
      value: item,
      label: startCase(toLower(replace(item, /_/g, ' '))),
    };
  });
};

export const videoTypeOptions = convertToOptions(VIDEO_TYPES, false);

export const countryOptions = convertToOptions(COUNTRIES);
export const platformCountryOptions = convertToOptions(COUNTRIES);

export const sortTypeOptions = [
  { label: 'Ascending', value: 'ASCENDING' },
  { label: 'Descending', value: 'DESCENDING' },
];

export const albumTypeOptions = [
  { label: 'Main Release', value: 'Main Release' },
  { label: 'Single & EP', value: 'Single & EP' },
];

export const countryAllOptions = [{ value: ALL_COUNTRIES_VALUE, label: 'WW' }, ...countryOptions];

export const statusOptions = [
  { value: Statuses.Published, label: 'Published' },
  { value: Statuses.Unpublished, label: 'Unpublished' },
  { value: Statuses.Takedown, label: 'Takedown' },
];

export const ratingTypeOptions = [
  { value: 'MPAA', label: 'MPAA' },
  { value: 'US TV', label: 'US TV' },
  { value: 'MPA - Canada', label: 'MPA - Canada' },
  { value: 'BBFC', label: 'BBFC' },
];

export const ratingValueOptions = {
  MPAA: [
    { value: 'G', label: 'G' },
    { value: 'PG', label: 'PG' },
    { value: 'PG-13', label: 'PG-13' },
    { value: 'R', label: 'R' },
  ],
  'US TV': [
    { value: 'TV-Y', label: 'TV-Y' },
    { value: 'TV-Y7', label: 'TV-Y7' },
    { value: 'TV-Y7 FV', label: 'PTV-Y7 FV' },
    { value: 'TV-G', label: 'TV-G' },
    { value: 'TV-PG', label: 'TV-PG' },
    { value: 'TV-14', label: 'TV-14' },
    { value: 'TV-MA', label: 'TV-MA' },
  ],
  'MPA - Canada': [
    { value: 'U', label: 'U' },
    { value: 'PG', label: 'PG' },
    { value: '14A', label: '14A' },
    { value: '18', label: '18' },
    { value: 'R', label: 'R' },
  ],
  BBFC: [
    { value: 'U', label: 'U' },
    { value: 'PG', label: 'PG' },
    { value: '12', label: '12' },
    { value: '12A', label: '12A' },
    { value: '15', label: '15' },
    { value: '18', label: '18' },
  ],
};

export const ingestionTaskStatusOptions = constantsToOptions(Object.values(IngestionTaskStatuses));
export const ingestionFailedTaskStatusOptions = constantsToOptions(FAILED_TASK_STATUSES);
export const missingMediaStatusOptions = constantsToOptions(MISSING_MEDIA_STATUSES);

export const artistRoles: { [role: string]: string } = {
  [ARTIST_ROLE_MAIN_ARTIST]: 'Main Artist',
  [ARTIST_ROLE_FEATURED_ARTIST]: 'Featured Artist',
  [ARTIST_ROLE_FILM_DIRECTOR]: 'Film Director',
  [ARTIST_ROLE_FILM_PRODUCER]: 'Film Producer',
  [ARTIST_ROLE_COMPOSER]: 'Composer',
  [ARTIST_ROLE_LYRICIST]: 'Lyricist',
  [ARTIST_ROLE_COMPOSER_LYRICIST]: 'Composer Lyricist',
  [ARTIST_ROLE_CONDUCTOR]: 'Conductor (Classical music)',
  [ARTIST_ROLE_RE_MIXER]: 'Re-mixer (dance)',
  [ARTIST_ROLE_DJ]: 'DJ (dance)',
  [ARTIST_ROLE_PERFORMER]: 'Performer',
};

export const artistRoleOptions = [
  { value: ARTIST_ROLE_MAIN_ARTIST, label: 'Main Artist' },
  { value: ARTIST_ROLE_FEATURED_ARTIST, label: 'Featured Artist' },
  { value: ARTIST_ROLE_FILM_DIRECTOR, label: 'Film Director' },
  { value: ARTIST_ROLE_FILM_PRODUCER, label: 'Film Producer' },
  { value: ARTIST_ROLE_COMPOSER, label: 'Composer' },
  { value: ARTIST_ROLE_LYRICIST, label: 'Lyricist' },
  { value: ARTIST_ROLE_COMPOSER_LYRICIST, label: 'Composer Lyricist' },
  { value: ARTIST_ROLE_CONDUCTOR, label: 'Conductor (Classical music)' },
  { value: ARTIST_ROLE_RE_MIXER, label: 'Re-mixer (dance)' },
  { value: ARTIST_ROLE_DJ, label: 'DJ (dance)' },
  { value: ARTIST_ROLE_PERFORMER, label: 'Performer' },
];

export const similarityTypeOptions = [
  { value: CONTEMPORARIES, label: 'Contemporaries' },
  { value: FOLLOWERS, label: 'Followers' },
  { value: INFLUENCES, label: 'Influences' },
  { value: RELATED_PROJECTS, label: 'Related Projects' },
];

export const socialNetworkOptions = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'youtube', label: 'Youtube' },
  { value: 'officialSite', label: 'Official Site' },
];

export const programmedListOptions = [
  { value: 'Hero', label: 'Hero' },
  { value: 'NewVideo', label: 'Featured' },
  { value: 'NapsterExclusive', label: 'Exclusives' },
  { value: 'Documentary', label: 'Documentaries' },
  { value: 'LivePerformance', label: 'Live Performances' },
];

export const identifierOptions = [
  { value: Identifiers.UPC, label: 'UPC' },
  { value: Identifiers.GRID, label: 'GRID' },
  { value: Identifiers.ISRC, label: 'ISRC' },
  { value: Identifiers.CATALOG, label: 'Napster Catalog' },
];

export const blogPostTypeOptions = [
  { value: 'playlist', label: 'Playlist' },
  { value: 'album', label: 'Album' },
  { value: 'video', label: 'Video' },
  { value: 'radio', label: 'Radio' },
  { value: 'objects', label: 'Objects' },
];
