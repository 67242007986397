import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import _ from 'lodash';

import { AnyObject, PageParams, PagingType } from '@app/types';
import { Country } from '@models/Country';
import { Track } from '@models/Track';
import { ITrackGroupWithTracks, TrackGroup, TrackGroupWithTracks } from '@models/TrackGroup';
import API from '@services/api';
import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE } from '@services/constants';
import CountriesApi from '@services/countries-api';
import tracksApi from '@services/tracks-api';

export interface FetchTrackGroupsWithTracksParams {
  id?: string;
  trackId?: string;
  trackName?: string;
  region?: string;
}

export const TRACK_GROUPS_URL = `${API_BASE_URL}/v1/track-groups`;

class TrackGroupsAPI extends API {
  constructor() {
    super(TRACK_GROUPS_URL);
  }

  createTrackGroupWithTacks = async (tracks: Track[]) => {
    const response = await axios.post<TrackGroup>(`${TRACK_GROUPS_URL}`);
    const tracksWithNewGroup: Partial<Track>[] = tracks.map(
      (track: Track): Partial<Track> => ({
        id: track.id,
        groups: [...track.groups, response.data],
      })
    );
    await tracksApi.updateManyTracks(tracksWithNewGroup);
    return response;
  };

  updateTrackGroup = async (id: string, newTracks: Track[]) => {
    const oldTracks = await tracksApi.fetchRecords<Track>({ group: id });
    const updatedTracks: Partial<Track>[] = [...oldTracks, ...newTracks].map(
      (track: Track): Partial<Track> => ({
        id: track.id,
        groups: track.groups.filter(
          (group) => newTracks.find((newTrack) => newTrack.id === track.id) || group.id !== id
        ),
      })
    );
    return tracksApi.updateManyTracks(updatedTracks);
  };

  deleteTrackGroup = async (id: string) => axios.delete(`${TRACK_GROUPS_URL}/${id}`);

  fetchTrackGroupsWithTracks = async (
    params: FetchTrackGroupsWithTracksParams,
    pageParams = { page: 1, itemsPerPage: DEFAULT_ITEMS_PER_PAGE } as PageParams
  ): Promise<PagingType<TrackGroupWithTracks>> => {
    const fetchByGroups = async (
      groups: TrackGroupWithTracks[],
      countries: Country[]
    ): Promise<ITrackGroupWithTracks[]> =>
      Promise.all(
        groups.map(async (group) => ({
          ...group,
          tracks: [
            ...(group.tracks || []),
            ...(
              await tracksApi.fetchRecords<Track>({
                group: group.id,
                country: countries.map(({ name }) => name).join(', '),
              })
            ).filter((track) => track.id !== group.tracks?.[0].id),
          ],
        }))
      );

    let groupsToFetch: TrackGroupWithTracks[] = [];
    if (params.id !== '') {
      try {
        groupsToFetch = [(await this.fetchData<TrackGroup>(params.id!)) as TrackGroupWithTracks];
      } catch (e) {
        throw new Error();
      }
    } else if (params.trackId !== '') {
      try {
        const track = await tracksApi.fetchTrack(params.trackId!);
        groupsToFetch = track.groups.map((group) => ({ ...group, tracks: [track] }));
      } catch (e) {
        throw new Error();
      }
    } else if (params.trackName !== '') {
      const fetchedTracks = await tracksApi.fetchRecords<Track, AnyObject>({ name: params.trackName });
      groupsToFetch = _.uniq(
        _.flatten(fetchedTracks.map((track) => track.groups.map((group) => ({ ...group, tracks: [track] }))))
      );
    }

    const countries: Country[] = params?.region
      ? await CountriesApi.fetchRecords<Country>({ region: params.region }, null)
      : [];
    const groupsWithTracks = await fetchByGroups(groupsToFetch, countries);

    const currentPage = pageParams.page || 1;
    const count = groupsWithTracks.length;
    const itemsPerPage = pageParams.itemsPerPage || DEFAULT_ITEMS_PER_PAGE;

    return {
      records: plainToInstance(
        TrackGroupWithTracks,
        groupsWithTracks.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage)
      ),
      count,
      currentPage,
      totalPages: count / itemsPerPage,
      itemsPerPage,
    };
  };
}

export default new TrackGroupsAPI();
