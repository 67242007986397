import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { QueryKeys } from '@app/constants';
import { Track } from '@models/Track';
import TracksApi from '@services/tracks-api';

import { BreadcrumbLink } from '@components/UI/Breadcrumb/Breadcrumb';
import PageLoader from '@components/UI/PageLoader/PageLoader';

import TrackGroupDetailContainer from '@pages/track-group/TrackGroupDetailPage/TrackGroupDetailContainer/TrackGroupDetailContainer';

export const breadcrumbLinks: BreadcrumbLink[] = [
  {
    label: 'Tracks',
    path: '/track-editor',
  },
  {
    label: 'View track group',
  },
];

const TrackGroupDetailPage = (): JSX.Element => {
  const { trackGroupId } = useParams<{ trackGroupId: string }>();

  const filters = { group: trackGroupId };

  const { data, isLoading } = useQuery<Track[]>(
    [QueryKeys.TrackGroup, { group: trackGroupId }],
    () => TracksApi.fetchRecords<Track>(filters),
    {}
  );

  if (isLoading) return <PageLoader />;

  return <TrackGroupDetailContainer isViewOnly breadcrumbLinks={breadcrumbLinks} tracks={data} />;
};

export default TrackGroupDetailPage;
