import axios, { AxiosResponse } from 'axios';
import { omitBy } from 'lodash';

import { IngestionLocaleMap } from '@models/IngestionLocaleMap';
import API from '@services/api';
import { API_BASE_URL } from '@services/constants';

export const INGESTION_LOCALE_MAP_URL = `${API_BASE_URL}/v1/ingestion-locale-map`;

class IngestionLocaleMapAPI extends API {
  constructor() {
    super(INGESTION_LOCALE_MAP_URL);
  }

  create = async (data: IngestionLocaleMap): Promise<AxiosResponse> =>
    axios.post(
      INGESTION_LOCALE_MAP_URL,
      omitBy(data, (value) => (Array.isArray(value) && !value.length) || !value)
    );

  update = async (data: IngestionLocaleMap): Promise<AxiosResponse> =>
    axios.patch(`${INGESTION_LOCALE_MAP_URL}/${data.id}`, data);

  delete = async (id: string) => axios.delete(`${INGESTION_LOCALE_MAP_URL}/${id}`);
}

export default new IngestionLocaleMapAPI();
