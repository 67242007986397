import { useAuth } from '@contexts/AuthContext';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

interface PrivateRouteProps {
  path: string;
  render: () => JSX.Element;
}

const PrivateRoute = ({ path, render }: PrivateRouteProps): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const Element = render;

  return <ErrorBoundary path={path}>{isAuthenticated ? <Element /> : null}</ErrorBoundary>;
};

export default PrivateRoute;
