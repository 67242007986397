import cn from 'classnames';

import SvgIcon from '@components/UI/SvgIcon/SvgIcon';
import Tooltip from '@components/UI/Tooltip/Tooltip';
import ConditionalWrapper from '@components/utils/ConditionalWrapper/ConditionalWrapper';

export enum AvailabilityEnum {
  TOTALLY_AVAILABLE,
  PARTIAL_AVAILABLE,
  NOT_AVAILABLE,
}

interface AvailabilityWidgetFieldProps {
  name: string;
  available: AvailabilityEnum;
  tooltip?: string;
}

const renderAvailabilityIcon = (available: AvailabilityEnum): JSX.Element => {
  if (available === AvailabilityEnum.PARTIAL_AVAILABLE)
    return (
      <Tooltip content="Partial available">
        <SvgIcon color="blue" name="minus-circle" />
      </Tooltip>
    );

  if (available === AvailabilityEnum.TOTALLY_AVAILABLE)
    return (
      <Tooltip content="Available">
        <SvgIcon color="green" name="tick-circle" />
      </Tooltip>
    );

  return (
    <Tooltip content="Not available">
      <SvgIcon color="red" name="cross-circle" />
    </Tooltip>
  );
};

export const AvailabilityWidgetField = ({ name, available, tooltip }: AvailabilityWidgetFieldProps): JSX.Element => {
  return (
    <div className="flex flex-row">
      <span className="mr-2">{renderAvailabilityIcon(available)}</span>
      <ConditionalWrapper condition={!!tooltip} wrapper={(children) => <Tooltip content={tooltip}>{children}</Tooltip>}>
        <p className={cn('text-gray-400', { '!font-semibold !text-gray-800': available })}>{name}</p>
      </ConditionalWrapper>
    </div>
  );
};
