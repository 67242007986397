import { Transform, Type } from 'class-transformer';
import produce, { immerable } from 'immer';
import { cloneDeep, pick, startCase } from 'lodash';

import { Countries, Statuses } from '@app/constants';
import {
  Availability,
  EmptyNumber,
  FileData,
  GenericModelFormData,
  Id,
  Identifier,
  ImageData,
  LocaleData,
  LocaleName,
  Modify,
  RelatedRelease,
} from '@app/types';
import { Album } from '@models/Album';
import { ArtistFieldData, RoleArtist, roleArtistToFormData } from '@models/Artist';
import { DeliveryChannel } from '@models/DeliveryChannel';
import { GenreFieldData, OrderedGenre, orderedGenreToFormData } from '@models/Genre';
import {
  formatAvailabilities,
  getIdentifierValue,
  getLocaleName,
  getPrimaryAvailability,
  getPrimaryCountryData,
  getPrimaryGenre,
  getPrimaryImageFromCountry,
  getPrimaryImageUrl,
  getSharedStatusColor,
  isExplicit,
  normalizeAvailabilities,
  sortCountriesByWw,
} from '@utils/common';
import { Identifiers } from '@utils/options';
import { emptyVideoName } from '@utils/videos';

export class VideoCountry implements IVideoCountry {
  [immerable] = true;
  country = Countries.WW;
  explicit = false;
  releaseDate = null;
  imprints: string[] = [];
  relatedReleases: RelatedRelease[] = [];
  images: ImageData[] = [];

  @Type(() => Date)
  originalReleaseDate?: Date | null = null;

  @Type(() => OrderedGenre)
  genres: OrderedGenre[] = [];
}

export class Video implements IVideo {
  id!: Id;
  status!: Statuses;
  names: LocaleName[] = [emptyVideoName];
  descriptions: LocaleName[] = [];
  identifiers: Identifier[] = [];
  subtitles: LocaleName[] = [];
  ratings: VideoRating[] = [];
  overrideStartDate: Date | null = null;
  traceId?: string;
  venue = '';
  episodeNo = '';
  seriesNo = '';
  lang = '';
  pseTag = false;
  videoFile: VideoFile = {
    duration: '',
    fileData: {
      name: '',
      path: '',
      hashSum: '',
    },
  };

  @Type(() => VideoCountry)
  @Transform(({ value }: { value: VideoCountry[] }) => sortCountriesByWw(value))
  countryData: VideoCountry[] = [new VideoCountry()];

  @Transform(({ value }: { value: string }) => startCase(value))
  videoType = '';

  @Transform(({ value }: { value: Availability[] }) => normalizeAvailabilities(value))
  availability: Availability[] = [];

  @Type(() => DeliveryChannel)
  deliveryChannel: DeliveryChannel | null = null;

  @Type(() => Album)
  album: Album | null = null;

  @Type(() => RoleArtist)
  contributors: RoleArtist[] = [];

  get formattedDuration(): string {
    return this.videoFile.duration ? new Date(Number(this.videoFile.duration)).toISOString().substr(11, 8) : '';
  }

  get displayName() {
    return getLocaleName(this.names);
  }

  get featuredArtistsName(): string {
    return RoleArtist.getFeaturedArtistsName(this.contributors);
  }

  get mainArtistsName(): string {
    return RoleArtist.getMainArtistsName(this.contributors);
  }

  get primaryCountryData(): VideoCountry {
    return getPrimaryCountryData(this.countryData);
  }

  get primaryImage(): ImageData | null {
    return getPrimaryImageFromCountry(this.primaryCountryData);
  }

  get primaryImageUrl(): string {
    return getPrimaryImageUrl(this.primaryImage);
  }

  get primaryGenre(): OrderedGenre | null {
    return getPrimaryGenre(this.primaryCountryData.genres);
  }

  get primaryAvailability(): Availability | null {
    return getPrimaryAvailability(this.availability);
  }
  get duration(): number {
    if (typeof this.videoFile.duration === 'string') {
      const duration = parseInt(this.videoFile.duration, 10);
      return Number.isNaN(duration) ? 0 : duration;
    }

    return this.videoFile.duration;
  }

  get externalLink(): string {
    return `https://web.napster.com/video/${this.id}`;
  }

  getIdentifierValue(type: Identifiers): string {
    return getIdentifierValue(type, this.identifiers);
  }

  getStatusColor(): { name: string; color: string } {
    return getSharedStatusColor(this.status);
  }

  [immerable] = true;
}

export interface IVideo {
  id: Id;
  videoFile: VideoFile;
  names: LocaleName[];
  descriptions: LocaleName[];
  subtitles: LocaleName[];
  ratings: VideoRating[];
  countryData: VideoCountry[];
  identifiers: Identifier[];
  availability: Availability[];
  deliveryChannel: DeliveryChannel | null;
  album: Album | null;
  contributors: RoleArtist[];
  pseTag: boolean;
  overrideStartDate: Date | null;
  seriesNo: string;
  episodeNo: string;
  lang: string;
  venue: string;
  videoType?: string;
  status?: Statuses;
  traceId?: string;
}

export interface IVideoCountry {
  country: Countries;
  explicit: boolean;
  imprints: string[];
  genres: OrderedGenre[];
  releaseDate: Date | null;
  originalReleaseDate?: null | Date;
  relatedReleases: RelatedRelease[] | null;
  images: ImageData[];
}

export interface VideoRating {
  type: string;
  value: string;
}

export interface VideoFile {
  fileData: FileData;
  duration: string | number;
  audioBitsPerSample?: EmptyNumber;
  audioSamplingRate?: EmptyNumber;
  audioNumberOfChannels?: EmptyNumber;
  audioCodecType?: string;
  audioFileType?: string;
  imageHeight?: EmptyNumber;
  imageWidth?: EmptyNumber;
  colorDepth?: EmptyNumber;
  containerFormat?: string;
  definitionType?: string;
  videoBitDepth?: EmptyNumber;
  videoCodecType?: string;
  videoFrameRate?: EmptyNumber;
  videoBitRate?: EmptyNumber;
  aspectRatio?: string;
  audioBitRate?: number;
}

type EditCountry = Modify<
  VideoCountry,
  {
    genres: {
      id: Id;
      order: number;
    }[];
  }
>;

export type VideoEditData = Modify<
  Video,
  {
    countryData: EditCountry[];
    deliveryChannel: Id | null;
    album: Id | null;
    contributors: {
      id: Id;
      roles: string[];
    }[];
    episodeNo: string | null;
    seriesNo: string | null;
    lang: string | null;
  }
>;

export interface VideoCountryFormData {
  country: Countries;
  releaseDate: Date | null;
  imprints: { value: string }[];
  images: ImageData[];
  genres: GenreFieldData[];
  originalReleaseDate?: Date | null;
  explicit: boolean;
  relatedReleases: RelatedRelease[] | null;
}

export interface VideoFormData {
  names: LocaleData[];
  status: Statuses;
  videoType: string;
  venue: string;
  descriptions: LocaleData[];
  identifiers: Identifier[];
  deliveryChannel: GenericModelFormData | null;
  album: GenericModelFormData | null;
  contributors: ArtistFieldData[];
  availability: Availability[];
  countryData: VideoCountryFormData[];
  videoFile: VideoFile;
  ratings: VideoRating[];
  subtitles: LocaleData[];
  overrideStartDate: Date | null;
  seriesNo: string;
  episodeNo: string;
  lang: string;
  pseTag: boolean;
}

export const getFormData = (videoData: Video): VideoFormData => {
  const formFields = pick(
    videoData,
    'status',
    'videoType',
    'names',
    'descriptions',
    'identifiers',
    'venue',
    'deliveryChannel',
    'album',
    'contributors',
    'availability',
    'countryData',
    'videoFile',
    'ratings',
    'subtitles',
    'overrideStartDate',
    'seriesNo',
    'episodeNo',
    'lang',
    'pseTag'
  );

  const formData: unknown = produce<typeof formFields, VideoFormData>(formFields, (draft) => {
    draft.deliveryChannel = videoData.deliveryChannel && {
      id: videoData.deliveryChannel.id,
      displayName: videoData.deliveryChannel.name,
    };
    draft.album = videoData.album && {
      id: videoData.album.id,
      displayName: videoData.album.displayName,
    };
    draft.contributors = videoData.contributors.map(roleArtistToFormData);
    draft.countryData = videoData.countryData.map((country) => ({
      ...country,
      genres: country.genres.map(orderedGenreToFormData),
      imprints: country.imprints.map((imprint) => ({ value: imprint })),
    }));

    if (videoData.videoFile) {
      draft.videoFile.duration = videoData.formattedDuration;
    }
  });

  return formData as VideoFormData;
};

export const formatForApi = (formData: VideoFormData): VideoEditData => {
  const formattedData: unknown = produce<VideoFormData, VideoEditData>(cloneDeep(formData), (draft) => {
    draft.deliveryChannel = formData.deliveryChannel?.id || null;
    draft.album = formData.album?.id || null;
    draft.contributors = formData.contributors.map(({ id, roles, displayArtist }) => ({
      id,
      roles,
      displayArtist,
    }));
    const hasWWCountry = formData.countryData.find((value) => value.country === Countries.WW);
    const isVideoExplicit = isExplicit(draft.countryData);
    formData.countryData.forEach((country, idx) => {
      draft.countryData[idx].genres = country.genres.map(({ id, order }) => ({ id, order }));
      // in case there's no WW, we check if some country has explicit true, if so we set true in all elements.
      draft.countryData[idx].explicit = hasWWCountry ? !!draft.countryData[idx].explicit : isVideoExplicit;
      draft.countryData[idx].originalReleaseDate = formData.countryData[0].originalReleaseDate;
      draft.countryData[idx].imprints = country.imprints.map((imprint) => imprint.value);
    });

    draft.videoFile.duration =
      String(formData.videoFile.duration)
        .split(':')
        .reduce((acc, time) => 60 * acc + Number(time), 0) * 1000;
    draft.availability = formatAvailabilities(formData.availability);

    draft.episodeNo = draft.episodeNo || null;
    draft.seriesNo = draft.episodeNo || null;
    draft.lang = draft.lang || null;

    if (draft.videoFile.fileData && draft.videoFile.fileData.hashSum != null)
      draft.videoFile.fileData.hashSum =
        draft.videoFile.fileData.hashSum.length > 0 ? draft.videoFile.fileData.hashSum : null;
  });

  return formattedData as VideoEditData;
};
