import React from 'react';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import Button from '@components/UI/buttons/Button/Button';

interface DeleteButtonProps {
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
  updated?: boolean;
  iconClassName?: string;
}

const DeleteButton = ({
  onClick,
  children,
  className,
  iconClassName,
  updated = false,
}: DeleteButtonProps): JSX.Element => {
  if (updated) {
    return (
      <Button
        className={cn(
          'rounded-full border border-gray-300 p-1 text-center text-gray-500 transition-all hover:border-gray-400 hover:text-gray-700',
          className
        )}
        variant="onlyText"
        onClick={onClick}
      >
        <svg
          className={twMerge('h-6 w-6', iconClassName)}
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        {children}
      </Button>
    );
  }

  return (
    <Button className={cn('text-red-700 hover:text-red-900', className)} variant="onlyText" onClick={onClick}>
      <FontAwesomeIcon className="mr-2 text-2xl" icon={faTimesCircle} />
      {children}
    </Button>
  );
};

export default DeleteButton;
