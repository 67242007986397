import { Countries } from '@app/constants';
import { Album } from '@models/Album';
import { Country } from '@models/Country';
import { Track } from '@models/Track';
import { Video } from '@models/Video';
import { normalizeArray } from '@utils/common';

import { AvailabilityEnum } from './AvailabilityWidgetField/AvailabilityWidgetField';

export type RegionsWithCountriesAvailabilityType = Record<
  string,
  { availableCountries: Country[]; unavailableCountries: Country[] }
>;

export const resolveAvailableCountries = (contents: (Track | Album | Video)[], countries: Country[]): Country[] => {
  let availableCountriesName: string[] = contents
    .map(({ availability }) => normalizeArray(availability).map(({ country }) => country))
    .flat();
  const excludedCountriesCountriesName: string[] = contents
    .map(({ availability: availabilities }) =>
      normalizeArray(availabilities).map((availability) => normalizeArray(availability.excludedCountries))
    )
    .filter((excludedCountries) => excludedCountries.length > 0)
    .flat()
    .flat();
  if (availableCountriesName.some((countryName) => countryName === Countries.WW)) {
    availableCountriesName = normalizeArray(countries).map(({ name }) => name);
  }

  availableCountriesName = availableCountriesName.filter(
    (countryName) => !excludedCountriesCountriesName.includes(countryName)
  );

  return countries.filter(({ name }) => availableCountriesName.includes(name));
};

export const resolveRegionsWithCountriesAvailability = (
  availableCountries: Country[],
  countries: Country[]
): RegionsWithCountriesAvailabilityType => {
  let regions: string[] = countries.map(({ region }) => region);
  regions = regions.filter((region, idx) => regions.indexOf(region) === idx);

  const regionsWithCountries: RegionsWithCountriesAvailabilityType = {};

  regions.forEach((regionName) => {
    regionsWithCountries[regionName] = { availableCountries: [], unavailableCountries: [] };
  });

  countries.forEach((country) => {
    if (availableCountries.some(({ name }) => name === country.name)) {
      regionsWithCountries[country.region].availableCountries.push(country);
    } else {
      regionsWithCountries[country.region].unavailableCountries.push(country);
    }
  });

  return regionsWithCountries;
};

export const resolveRegionAvailability = (
  availableCountries: Country[],
  unavailableCountries: Country[]
): AvailabilityEnum => {
  if (availableCountries.length === 0) {
    return AvailabilityEnum.NOT_AVAILABLE;
  }

  if (unavailableCountries.length === 0) {
    return AvailabilityEnum.TOTALLY_AVAILABLE;
  }

  return AvailabilityEnum.PARTIAL_AVAILABLE;
};

export const resolveRegionTooltip = (
  region: string,
  availableCountries: Country[],
  unavailableCountries: Country[]
): string => {
  const availableCountriesNames = availableCountries.map(({ name }) => name).join(', ');
  const unavailableCountriesNames = unavailableCountries.map(({ name }) => name).join(', ');

  if (availableCountries.length === 0) {
    return `Not available in ${region}'s countries: ${unavailableCountriesNames}`;
  }

  if (unavailableCountries.length === 0) {
    return `Totally available in ${region}`;
  }

  return `Available in ${availableCountriesNames} but has ${unavailableCountries.length} exception${
    unavailableCountries.length > 1 ? 's' : ''
  }`;
};
