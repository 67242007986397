import { useEffect, useMemo } from 'react';

import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { OptionType } from '@app/types';
import { removeExcludedOptions } from '@utils/options';

import Select from '@components/UI/Select/Select';

export interface SingleSelectFieldProps {
  options: OptionType[];
  label?: string;
  defaultValue?: string;
  excludedValues?: string[];
  className?: string;
  buttonContainerClassName?: string;
  buttonClassName?: string;
  buttonLabelClassName?: string;
  labelClassName?: string;
  optionClassName?: string;
  optionLabelClassName?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  addOption?: (option: OptionType) => void;
}

const SingleSelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  options,
  className,
  buttonContainerClassName,
  buttonClassName,
  buttonLabelClassName,
  labelClassName,
  optionClassName,
  optionLabelClassName,
  defaultValue,
  label,
  addOption,
  excludedValues = [],
  required = false,
  disabled = false,
  isLoading = false,
}: SingleSelectFieldProps & UseControllerProps<TFieldValues, TName>): JSX.Element => {
  const {
    field: { value, onChange: setValue },
  } = useController({ name, control });
  const availableOptions = useMemo(() => removeExcludedOptions(options, excludedValues), [excludedValues, options]);
  const selectedOption = useMemo(() => options.find((opt) => opt.value === value) || null, [options, value]);

  useEffect(() => {
    const setInitialValue = () => {
      if (defaultValue && !selectedOption) {
        return setValue(defaultValue);
      }

      if (required && availableOptions.length && !selectedOption) {
        return setValue(availableOptions[0].value);
      }
    };

    setInitialValue();
  }, [availableOptions, defaultValue, required, selectedOption, setValue]);

  const handleChange = (option: OptionType) => {
    setValue(option.value);
  };

  const reset = () => {
    setValue(null);
  };

  return (
    <Select
      buttonClassName={buttonClassName}
      buttonContainerClassName={buttonContainerClassName}
      buttonLabelClassName={buttonLabelClassName}
      className={className}
      disabled={disabled}
      isLoading={isLoading}
      label={label}
      labelClassName={labelClassName}
      optionClassName={optionClassName}
      optionLabelClassName={optionLabelClassName}
      options={availableOptions}
      required={required}
      reset={reset}
      value={selectedOption}
      onAddNewOption={addOption}
      onChange={handleChange}
    />
  );
};

export default SingleSelectField;
