import SvgIcon, { SvgIconNameType } from '@components/UI/SvgIcon/SvgIcon';

interface MediaTypeIconProps {
  iconType: 'atmos' | 'track' | 'video' | 'album';
  className?: string;
}
const iconName = {
  atmos: 'dolby',
  track: 'track',
  video: 'video',
  album: 'album',
};

const MediaTypeIcon = ({ iconType, className = 'mr-2 mt-2' }: MediaTypeIconProps): JSX.Element => {
  return <SvgIcon className={className} color="blue" name={iconName[iconType] as SvgIconNameType} />;
};

export default MediaTypeIcon;
