import { SyntheticEvent } from 'react';

import { noop } from 'lodash';

import Spinner from '@components/UI/Spinner/Spinner';
import SvgIcon from '@components/UI/SvgIcon/SvgIcon';

const SPINNER_SIZE_OFFSET = 4;
const ICON_DROP_SHADOW_CLASSNAMES = 'drop-shadow-[0_0px_3px_rgba(0,0,0,0.75)]';
export interface MediaPreviewThumbnailProps {
  thumbnailSrc: string;
  isPreviewable?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  isPlaying?: boolean;
  isWithError?: boolean;
  size?: string;
  iconSize?: number;
}

const MediaPreviewThumbnail = ({
  thumbnailSrc,
  isPreviewable = true,
  onClick = noop,
  isLoading = false,
  isPlaying = false,
  isWithError = false,
  size = '80px',
  iconSize = 2,
}: MediaPreviewThumbnailProps): JSX.Element => {
  const handleClick = (ev: SyntheticEvent) => {
    ev.stopPropagation();
    if (isPreviewable) onClick();
  };

  const renderStatusIcon = () => {
    if (isWithError)
      return (
        <SvgIcon className={ICON_DROP_SHADOW_CLASSNAMES} color="white" name="warning" size={iconSize} title="warning" />
      );
    if (isLoading)
      return <Spinner className={ICON_DROP_SHADOW_CLASSNAMES} color="white" size={SPINNER_SIZE_OFFSET + iconSize} />;
    if (isPlaying)
      return (
        <SvgIcon
          className={ICON_DROP_SHADOW_CLASSNAMES}
          color="white"
          name="pause-circle"
          size={iconSize}
          title="pause-circle"
        />
      );

    return (
      <SvgIcon
        className={ICON_DROP_SHADOW_CLASSNAMES}
        color="white"
        name="play-circle"
        size={iconSize}
        title="play-circle"
      />
    );
  };

  return (
    <div className="relative" style={{ height: size, width: size }} onClick={handleClick}>
      {isPreviewable && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <div>{renderStatusIcon()}</div>
        </div>
      )}
      <img alt="Media thumbnail" src={thumbnailSrc} />
    </div>
  );
};

export default MediaPreviewThumbnail;
