import { ReactNode, SyntheticEvent } from 'react';

import { Link } from 'react-router-dom';

import SvgIcon from '@components/UI/SvgIcon/SvgIcon';

export interface LinkWithIconProps {
  link: string;
  children?: ReactNode;
  openInNewTab?: boolean;
}

const LinkWithIcon = ({ link, children, openInNewTab }: LinkWithIconProps): JSX.Element => {
  const handleClick = (ev: SyntheticEvent) => {
    ev.stopPropagation();
  };

  return (
    <Link
      className="flex flex-row py-2 hover:text-gray-900 hover:underline"
      target={openInNewTab ? '_blank' : '_self'}
      to={link}
      onClick={handleClick}
    >
      {children}
      <SvgIcon color="#2259FF" name="link" style={{ marginLeft: '0.5em' }} />
    </Link>
  );
};

export default LinkWithIcon;
