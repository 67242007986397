import {
  Availability,
  Copyright,
  CountriesPerRegionsType,
  CountryReleaseData,
  CountryWithCodeType,
  ImageData,
  LocaleData,
  LocaleName,
  LocaleValue,
} from '@app/types';
import { TrackCountryFormData } from '@models/Track';

export enum Statuses {
  Published = 'published',
  Unpublished = 'unpublished',
  Pending = 'pending',
  Takedown = 'takendown',
}

export enum Locales {
  Default = 'en-US',
}

export enum Countries {
  WW = 'WW',
  US = 'US',
  AT = 'AT',
  AR = 'AR',
  BR = 'BR',
  CA = 'CA',
  TA = 'TA',
}

export enum UsageTypes {
  Stream = 'Stream',
  OnDemandStream = 'OnDemandStream',
  AsPerContract = 'AsPerContract',
  ConditionalDownload = 'ConditionalDownload',
}

export enum CommercialModelTypes {
  SubscriptionModel = 'SubscriptionModel',
  AdvertisementSupportedModel = 'AdvertisementSupportedModel',
}

export const EMPTY_IMG = 'https://www.freeiconspng.com/uploads/no-image-icon-6.png';
export const COUNTRIES = [
  'WW',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'RE',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'RO',
  'BA',
  'BB',
  'RS',
  'BD',
  'BE',
  'BF',
  'RW',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'SA',
  'SB',
  'BQ',
  'SC',
  'BR',
  'SD',
  'BS',
  'SE',
  'BT',
  'SG',
  'BV',
  'SH',
  'BW',
  'SI',
  'SJ',
  'SK',
  'BZ',
  'SL',
  'SM',
  'SN',
  'SO',
  'CA',
  'SR',
  'SS',
  'CC',
  'ST',
  'CD',
  'SV',
  'CF',
  'CG',
  'SX',
  'CH',
  'SY',
  'CI',
  'SZ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'TC',
  'CR',
  'TD',
  'TF',
  'CU',
  'TG',
  'CV',
  'TH',
  'CW',
  'CX',
  'TJ',
  'CY',
  'TK',
  'CZ',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'DE',
  'TV',
  'TW',
  'TZ',
  'DJ',
  'DK',
  'DM',
  'DO',
  'UA',
  'UG',
  'DZ',
  'UM',
  'US',
  'EC',
  'EE',
  'EG',
  'EH',
  'UY',
  'UZ',
  'VA',
  'VC',
  'ER',
  'ES',
  'VE',
  'ET',
  'VG',
  'VI',
  'VN',
  'VU',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'WF',
  'GA',
  'GB',
  'WS',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'YE',
  'HT',
  'HU',
  'YT',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'ZA',
  'IQ',
  'IR',
  'IS',
  'IT',
  'ZM',
  'JE',
  'ZW',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
];

export const SUPPORT_COUNTRIES_FOR_NAPSTER = [
  'US',
  'CA',
  'AR',
  'BR',
  'CL',
  'CO',
  'CR',
  'EC',
  'SV',
  'GT',
  'MX',
  'NI',
  'PA',
  'PE',
  'UY ',
  'PT',
  'ES',
  'GB',
  'CH',
  'SE',
  'NO',
  'NL',
  'MC',
  'IT',
  'IE',
  'GR',
  'DE',
  'FR',
  'FI',
  'DK',
  'AT',
  'AD',
  'LU',
  'VE',
  'BO',
  'AU',
  'PY',
  'BZ',
  'HN',
  'BE',
  'TR',
  'UY',
  'JP',
  'NZ',
];

export const ALL_COUNTRIES_VALUE = 'WW';
export const DEFAULT_LOCALE = 'en-US';
export const ALLOWED_VIDEO_EXTENSIONS = ['video/mp4', 'video/mpeg', 'video/avc'];
export const ALLOWED_IMAGE_EXTENSIONS = ['image/jpg', 'image/png', 'image/jpeg'];
// (C3CI-1604) Video Type list updated according to https://napster.atlassian.net/wiki/spaces/CMS/pages/1816166599/Video+Type
export const VIDEO_TYPES = [
  'Lyric Video',
  'Enhanced Pseudo Video',
  'Pseudo Video',
  'Slideshow',
  'Content Provider Originals',
  'Behind the Scenes',
  'Webisode',
  'Making Of',
  'Trailer',
  'Interview',
  'Music Video',
  'VR',
  '360',
  'Acoustic',
  'TV Show',
  'Special Event',
  'Concert',
  'Documentary',
  'Feature',
  'EPK',
  'Mini Film',
  'Official Performance',
  'Karaoke',
  'Preview',
  'Live',
  'Live Stream',
  'Marketing',
  'Raw Footage',
  'Napster Exclusive',
];
export const MIN_WIDTH_VIDEO_IMAGE_UPLOAD = 640;
export const MIN_HEIGHT_VIDEO_IMAGE_UPLOAD = 360;

export const DEFAULT_IMAGE_EXTENSION = 'image/jpeg';

export const SAMPLE_TRANSPARENT_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==';

export const NO_CONNECTION_ERROR = Error('No internet connection. Try again later');

export const EMPTY_QUERY_KEY = '';

export enum Errors {
  BadRequest = 400,
  NotFound = 404,
  ConnectionAborted = 'ECONNABORTED',
}

export enum MediaTrackStatus {
  ACTIVE = 'ACTIVE',
  CREATING = 'CREATING',
  DELETING = 'DELETING',
  WAIT_SRC_EXPIRE = 'WAIT_SRC_EXPIRE',
}

export const emptyLocaleData: LocaleData = {
  value: '',
  locale: '',
  source: 'napster',
};

export const emptyLocaleField: LocaleName = {
  value: '',
  locale: DEFAULT_LOCALE,
  source: 'napster',
  isPrimary: false,
};

export const emptyLocaleValueField: LocaleValue = {
  value: '',
  locale: DEFAULT_LOCALE,
};

export const emptyCopyright: Copyright = {
  company: '',
  year: 0,
  text: '',
};

export const emptyCountry: CountryReleaseData = {
  country: '',
  explicit: false,
  releaseDate: '',
  originalReleaseDate: '',
  excludedCountries: [],
  imprints: [],
  images: [],
  pline: emptyCopyright,
  cline: emptyCopyright,
  genres: [],
};

export const emptyImage: ImageData = {
  fileData: {
    name: '',
    path: '',
    hashSum: '',
  },
  imageUrl: '',
  isPrimary: false,
  availableOn: null,
};

export const emptyAvailability: Availability = {
  country: Countries.WW,
  availabilityDates: [
    {
      availableOn: null,
      expiresOn: null,
      isRegional: false,
      commercialModelType: 'SubscriptionModel',
      usageType: UsageTypes.Stream,
    },
  ],
  excludedCountries: [],
};

export const trackEmptyCountry: TrackCountryFormData = {
  country: '' as Countries,
  explicit: false,
  imprints: [],
  genres: [],
  relatedReleases: [],
};

export enum QueryKeys {
  AccountingPartner = 'accountingPartner',
  AccountingPartners = 'accountingPartners',
  Artists = 'artists',
  Artist = 'artist',
  ArtistRole = 'artistRole',
  ArtistRoles = 'artistRoles',
  Albums = 'albums',
  Album = 'album',
  AlbumReviews = 'albumReviews',
  AlbumReview = 'albumReview',
  Collection = 'collection',
  Collections = 'collections',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Countries = 'countries',
  DeliveryChannel = 'deliveryChannel',
  DeliveryChannels = 'deliveryChannels',
  FeaturedTag = 'featuredTag',
  FeaturedTags = 'featuredTags',
  Genres = 'genres',
  Genre = 'genre',
  IngestionTasks = 'ingestionTasks',
  IngestionTask = 'ingestionTask',
  IngestionGenreMaps = 'ingestionGenreMaps',
  IngestionGenreMap = 'ingestionGenreMap',
  IngestionLocaleMaps = 'ingestionLocaleMaps',
  IngestionLocaleMap = 'ingestionLocaleMap',
  IngestionReleaseTypeMaps = 'ingestionReleaseTypeMaps',
  IngestionReleaseTypeMap = 'ingestionReleaseTypeMap',
  IngestionVideoTypeMaps = 'ingestionVideoTypeMaps',
  IngestionVideoTypeMap = 'ingestionVideoTypeMap',
  Medias = 'medias',
  Media = 'media',
  ProgrammedList = 'programmedList',
  ProgrammedLists = 'programmedLists',
  Tracks = 'tracks',
  Track = 'track',
  TrackGroups = 'trackGroups',
  TrackGroup = 'trackGroup',
  Videos = 'videos',
  Video = 'video',
  Users = 'users',
  BlogPost = 'blogPost',
  BlogPosts = 'blogPosts',
}

export enum IngestionTaskStatuses {
  ALBUM_MATCHED = 'ALBUM_MATCHED',
  ALBUM_MATCHING = 'ALBUM_MATCHING',
  ARTIST_MATCHED = 'ARTIST_MATCHED',
  ARTIST_MATCHING = 'ARTIST_MATCHING',
  CANCELED = 'CANCELED',
  CONVERTED = 'CONVERTED',
  CONVERTING = 'CONVERTING',
  DONE = 'DONE',
  FAILED_ALBUM_MATCHING = 'FAILED_ALBUM_MATCHING',
  FAILED_ARTIST_MATCHING = 'FAILED_ARTIST_MATCHING',
  FAILED_CONVERSION = 'FAILED_CONVERSION',
  FAILED_INGESTION = 'FAILED_INGESTION',
  FAILED_RECONCILIATION = 'FAILED_RECONCILIATION',
  FAILED_VALIDATION = 'FAILED_VALIDATION',
  INGESTING = 'INGESTING',
  INVALID = 'INVALID',
  LABEL_CONFLICT = 'LABEL_CONFLICT',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
  VALIDATED = 'VALIDATED',
  VALIDATING = 'VALIDATING',
  OUT_OF_ORDER = 'OUT_OF_ORDER',
}

export enum MissingMediaStatuses {
  MISSING_AUDIO = 'MISSING_AUDIO',
  MISSING_VIDEO = 'MISSING_VIDEO',
  MISSING_IMAGE = 'MISSING_IMAGE',
}

export enum AudioStreamProcessingStates {
  PUBLISHED = 'PUBLISHED',
  WAIT_ENCODE_START = 'WAIT_ENCODE_START',
}

export const MISSING_MEDIA_STATUSES = [
  MissingMediaStatuses.MISSING_AUDIO,
  MissingMediaStatuses.MISSING_VIDEO,
  MissingMediaStatuses.MISSING_IMAGE,
];

export const FAILED_TASK_STATUSES = [
  IngestionTaskStatuses.CANCELED,
  IngestionTaskStatuses.INVALID,
  IngestionTaskStatuses.LABEL_CONFLICT,
  IngestionTaskStatuses.UNKNOWN,
  IngestionTaskStatuses.FAILED_ALBUM_MATCHING,
  IngestionTaskStatuses.FAILED_ARTIST_MATCHING,
  IngestionTaskStatuses.FAILED_CONVERSION,
  IngestionTaskStatuses.FAILED_INGESTION,
  IngestionTaskStatuses.FAILED_RECONCILIATION,
  IngestionTaskStatuses.FAILED_VALIDATION,
];

export enum IngestionMapTypes {
  GenreMap = 'GenreMap',
  VideoMap = 'VideoMap',
  ReleaseMap = 'ReleaseMap',
  LocaleMap = 'LocaleMap',
}

export const defaultSortProperties = {
  createdOn: {
    value: 'createdOn',
    label: 'Created on',
  },
  updatedOn: {
    value: 'updatedOn',
    label: 'Updated on',
  },
};

export const accountingPartnerSortProperties = {
  ...defaultSortProperties,
  name: {
    value: 'name',
    label: 'Name',
  },
};

export const albumXmlHistorySortProperties = {
  publishedDate: {
    value: 'publishedDate',
    label: 'Publish date',
  },
};

export const artistSortProperties = {
  ...defaultSortProperties,
};

export const albumSortProperties = {
  ...defaultSortProperties,
};

export const blogPostSortProperties = {
  ...defaultSortProperties,
  publishedDate: {
    value: 'publishedDate',
    label: 'Publish date',
  },
};

export const deliveryChannelSortProperties = {
  ...defaultSortProperties,
  name: {
    value: 'name',
    label: 'Name',
  },
};

export const genreSortProperties = {
  ...defaultSortProperties,
};

export const ingestionGenreMapSortProperties = {
  ...defaultSortProperties,
};

export const ingestionLocaleMapSortProperties = {
  ...defaultSortProperties,
  locale: {
    value: 'locale',
    label: 'Locale',
  },
};

export const ingestionReleaseTypeMapSortProperties = {
  ...defaultSortProperties,
};

export const ingestionStatusSortProperties = {
  ...defaultSortProperties,
};

export const ingestionVideoMapStortProperties = {
  ...defaultSortProperties,
};

export const programmedListSortProperties = {
  published: {
    value: 'published',
    label: 'Published',
  },
  scheduled: {
    value: 'scheduled',
    label: 'Scheduled',
  },
  draft: {
    value: 'draft',
    label: 'Draft',
  },
};

export const trackSortProperties = {
  ...defaultSortProperties,
  sequenceNumber: {
    value: 'sequenceNumber',
    label: 'Sequence number',
  },
};

export const videoSortProperties = {
  ...defaultSortProperties,
};

export const countries: { [key: string]: CountryWithCodeType } = {
  UnitedStates: { name: 'United States', code: 'US' },
  Canada: { name: 'Canada', code: 'CA' },
  Mexico: { name: 'Mexico', code: 'MX' },
  Colombia: { name: 'Colombia', code: 'CO' },
  Chile: { name: 'Chile', code: 'CL' },
  Argentina: { name: 'Argentina', code: 'AR' },
  Peru: { name: 'Peru', code: 'PE' },
  CostaRica: { name: 'Costa Rica', code: 'CR' },
  Ecuador: { name: 'Ecuador', code: 'EC' },
  ElSalvador: { name: 'El Salvador', code: 'SV' },
  Guatemala: { name: 'Guatemala', code: 'GT' },
  Nicaragua: { name: 'Nicaragua', code: 'NI' },
  Panama: { name: 'Panama', code: 'PA' },
  Uruguay: { name: 'Uruguay', code: 'UY' },
  Paraguay: { name: 'Paraguay', code: 'PY' },
  Bolivia: { name: 'Bolivia', code: 'BO' },
  Belize: { name: 'Belize', code: 'BZ' },
  Honduras: { name: 'Honduras', code: 'HN' },
  Brazil: { name: 'Brazil', code: 'BR' },
  France: { name: 'France', code: 'FR' },
  Germany: { name: 'Germany', code: 'DE' },
  Switzerland: { name: 'Switzerland', code: 'CH' },
  Austria: { name: 'Austria', code: 'AT' },
  Netherlands: { name: 'Netherlands', code: 'NL' },
  Spain: { name: 'Spain', code: 'ES' },
  Portugal: { name: 'Portugal', code: 'PT' },
  Italy: { name: 'Italy', code: 'IT' },
  Greece: { name: 'Greece', code: 'GR' },
  Turkey: { name: 'Turkey', code: 'TR' },
  Sweden: { name: 'Sweden', code: 'SE' },
  Finland: { name: 'Finland', code: 'FI' },
  Denmark: { name: 'Denmark', code: 'DK' },
  Norway: { name: 'Norway', code: 'NO' },
  Luxembourg: { name: 'Luxembourg', code: 'LU' },
  Australia: { name: 'Australia', code: 'AU' },
  NewZealand: { name: 'New Zealand', code: 'NZ' },
  Japan: { name: 'Japan', code: 'JP' },
};

export const regions: {
  [key: string]: CountriesPerRegionsType;
} = {
  NorthAmerica: {
    name: 'North America',
    countries: [countries.UnitedStates, countries.Canada],
  },
  SouthAmerica: {
    name: 'South America',
    countries: [
      countries.Mexico,
      countries.Colombia,
      countries.Chile,
      countries.Argentina,
      countries.Peru,
      countries.CostaRica,
      countries.Ecuador,
      countries.ElSalvador,
      countries.Guatemala,
      countries.Nicaragua,
      countries.Panama,
      countries.Uruguay,
      countries.Paraguay,
      countries.Bolivia,
      countries.Belize,
      countries.Honduras,
      countries.Brazil,
    ],
  },
  Europe: {
    name: 'Europe',
    countries: [
      countries.France,
      countries.Germany,
      countries.Switzerland,
      countries.Austria,
      countries.Netherlands,
      countries.Spain,
      countries.Portugal,
      countries.Italy,
      countries.Greece,
      countries.Turkey,
      countries.Sweden,
      countries.Finland,
      countries.Denmark,
      countries.Norway,
      countries.Luxembourg,
    ],
  },
  AsiaPacific: {
    name: 'Asia Pacific',
    countries: [countries.Australia, countries.NewZealand, countries.Japan],
  },
};

export const colorRowContent = [
  'bg-pink-400',
  'bg-yellow-400',
  'bg-green-400',
  'bg-blue-300',
  'bg-purple-200',
  'bg-gray-200',
  'bg-green-100',
  'bg-red-300',
];

export const DatePickerFormats = {
  dateTime: 'dd MMM yy, h:mm aa',
  date: 'dd MMM yy',
  time: 'hh:mm aa',
  year: 'yyyy',
};

export enum UserRoles {
  PLAYLIST_MAKER = 'playlistmaker',
  ADMIN = 'admin',
}

export enum DCTypesEnum {
  GCS = 'gcs',
  NFS = 'nfs',
  Editorial = 'editorial',
}

export const AuthType = {
  OKTA: 'okta',
  AUTH0: 'auth0',
};

export const ENTERCOM_TAGS_IDS = [
  '645d85f3e229f2b5a9f4f225',
  '645d865be229f2b5a9f4f226',
  '645d86cae229f2b5a9f4f228',
  '645d855de229f2b5a9f4f222',
  '645d858ce229f2b5a9f4f223',
  '645d85bfe229f2b5a9f4f224',
  '645d84cbe229f2b5a9f4f221',
];

export const PARTNER_ID = '614884be5a375ab1e17e8e03';

export enum DMCACheckResponses {
  OVERALL_SAME_ARTIST_TRACKS = 'OVERALL_SAME_ARTIST_TRACKS',
}

export const NAPSTER_PARTNER_NAME = 'Napster';

export const ENTERCOM_ID = '614884be5a375ab1e17e8e12';
export const ENTERCOM_COUNTRY = 'US';
export const ENTERCOM_NAME = 'ENTERCOM';
