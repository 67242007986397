import React, { ReactNode, SyntheticEvent } from 'react';

import cn from 'classnames';
import noop from 'lodash/noop';
import { twMerge } from 'tailwind-merge';

import SvgIcon, { SvgIconNameType } from '@components/UI/SvgIcon/SvgIcon';
import Tooltip from '@components/UI/Tooltip/Tooltip';
import ConditionalWrapper from '@components/utils/ConditionalWrapper/ConditionalWrapper';

import Spinner from '../../Spinner/Spinner';

const variants = {
  primary: 'border-transparent px-8 py-2 text-white bg-accent hover:bg-accent-dark focus:bg-accent-dark',
  secondary: 'border-gray-300 px-8 py-2 text-black bg-white hover:bg-gray-200',
  danger: 'border-red-900 px-10 py-2 text-white bg-red-800 hover:bg-red-900',
  onlyText: 'border-transparent bg-transparent shadow-none',
};

const sizes = {
  tiny: 'w-auto h-7 text-sm px-3 gap-1',
  small: 'w-auto h-9 text-sm px-5 gap-1',
  medium: 'w-auto h-11 text-base px-8 gap-1',
  large: 'w-auto h-16 text-xl px-8 gap-1',
};

export type ButtonVariants = 'primary' | 'secondary' | 'danger' | 'onlyText';

export interface ButtonProps {
  children: React.ReactNode;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariants;
  disabled?: boolean;
  disableTooltip?: ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  className?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  iconName?: SvgIconNameType;
  rounded?: boolean;
}

const roundedCn = 'aspect-square h-auto p-4 rounded-full';

const Button = ({
  children,
  className,
  disabled = false,
  disableTooltip,
  isLoading = false,
  type = 'button',
  variant = 'primary',
  onClick = noop,
  size = 'medium',
  iconName,
  rounded = false,
}: ButtonProps): JSX.Element => {
  const iconColor = variant === 'secondary' || variant === 'onlyText' ? '#000000' : '#FFFFFF';
  return (
    <ConditionalWrapper
      condition={disabled && !!disableTooltip}
      wrapper={(btn) => <Tooltip content={disableTooltip}>{btn}</Tooltip>}
    >
      <button
        className={twMerge(
          cn(
            'flex items-center justify-center rounded-full border text-sm font-semibold shadow-sm outline-none duration-200',
            { 'pointer-events-none opacity-80': disabled },
            [variants[variant]],
            [sizes[size]],
            { [roundedCn]: rounded }
          ),
          className
        )}
        disabled={disabled || isLoading}
        type={type}
        onClick={onClick}
      >
        {iconName && <SvgIcon color={iconColor} name={iconName} />}
        {children}
        {isLoading && (
          <span className="ml-2 -mr-7">
            <Spinner color="#ddd" size={5} />
          </span>
        )}
      </button>
    </ConditionalWrapper>
  );
};

export default Button;
