import { ReactNode, useState } from 'react';

import { Track } from '@models/Track';
import { getIconType, isExplicit } from '@utils/common';

import AvailabilityWidget from '@components/UI/AvailabilityWidget/AvailabilityWidget';
import ExplicitTag from '@components/UI/ExplicitTag/ExplicitTag';
import MediaTypeIcon from '@components/UI/MediaTypeIcon/MediaTypeIcon';
import SvgIcon from '@components/UI/SvgIcon/SvgIcon';
import TrackPreviewThumbnail from '@components/UI/TrackPreviewThumbnail/TrackPreviewThumbnail';
import Modal from '@components/UI/modals/Modal/Modal';
import ArtistNameLabel from '@components/form-fields/ArtistNameLabel/ArtistNameLabel';

export interface TrackGroupContentRowProps {
  data: Track;
  idx: number;
  isEditMode?: boolean;
  isDraggable?: boolean;
  isDragging?: boolean;
  onRemove?: (idx: number) => void;
}

const TrackGroupContentRow = ({
  data,
  idx,
  isEditMode,
  isDraggable,
  isDragging,
  onRemove,
}: TrackGroupContentRowProps): JSX.Element => {
  const [showAvailabilityModal, setShowAvailabilityModal] = useState<boolean>(false);

  const mainArtist = data.contributors.find((artist) => artist.isMainArtist || artist.isFeaturedArtist)?.value;

  const onShowAvailabilityClick = () => {
    setShowAvailabilityModal(true);
  };

  const Wrapper = ({ children }: { children: ReactNode }): JSX.Element => {
    if (!isDraggable) return <tr className="tr">{children}</tr>;
    if (isDragging) return <div className="tr flex w-full flex-row items-center">{children}</div>;

    return children as JSX.Element;
  };

  return (
    <Wrapper>
      <td className="py-2 pl-6">
        <MediaTypeIcon iconType={getIconType({ isAtmos: !!data.isAtmos, isTrack: !data.isAtmos })} />
      </td>
      {!isDragging && (
        <td className="py-2">
          <TrackPreviewThumbnail isAtmos={data.isAtmos} mediaId={data.id} thumbnailSrc={data.primaryImageUrl} />
        </td>
      )}
      <td className="flex flex-col py-2 pl-6 text-sm">
        <div className="flex flex-row gap-3 font-semibold text-black">
          <span>{data.displayName}</span>
          <ExplicitTag isExplicit={isExplicit(data.countryData)} />
        </div>
        <div className="flex whitespace-nowrap text-gray-500">
          <div className="flex flex-col whitespace-nowrap">
            {mainArtist && <ArtistNameLabel displayName={mainArtist.displayName} id={mainArtist.id} />}
          </div>
        </div>
      </td>
      {!isDragging && (
        <td className="px-6 py-2 text-sm font-medium text-blue-600">
          <span className="cursor-pointer" onClick={onShowAvailabilityClick}>
            Show availability
          </span>
          <Modal
            open={showAvailabilityModal}
            setOpen={setShowAvailabilityModal}
            onClose={() => setShowAvailabilityModal(false)}
          >
            <AvailabilityWidget
              showFirstContentMeta
              contents={[data]}
              title={(<p className="mb-4 text-xl font-bold">Track availability</p>) as JSX.Element}
            />
          </Modal>
        </td>
      )}
      {onRemove && !isDragging && !isEditMode && (
        <td className="py-2 pl-6">
          <span
            className="box-content block h-3 w-3 cursor-pointer rounded-full border-2 p-2"
            onClick={() => onRemove(idx)}
          >
            <SvgIcon color="#131313" name="cross" size={0.75} />
          </span>
        </td>
      )}
    </Wrapper>
  );
};

export default TrackGroupContentRow;
