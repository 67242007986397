import { CSSProperties } from 'react';

import svgs from '@components/UI/SvgIcon/svgs';
import Tooltip from '@components/UI/Tooltip/Tooltip';
import ConditionalWrapper from '@components/utils/ConditionalWrapper/ConditionalWrapper';

export type SvgIconNameType = keyof typeof svgs;

interface SvgIconProps {
  name: SvgIconNameType;
  size?: number;
  color?: string;
  title?: string;
  tooltip?: string | React.ReactNode;
  viewBox?: string;
  style?: CSSProperties;
  className?: string;
}

const SvgIcon = ({
  name,
  size = 1.5,
  color = '#FCFCFC',
  title = name,
  viewBox = '0 0 24 24',
  tooltip,
  style,
  className,
}: SvgIconProps): JSX.Element => {
  const IconComponent = svgs[name as keyof typeof svgs] || svgs._missing;
  return (
    <ConditionalWrapper condition={!!tooltip} wrapper={(children) => <Tooltip content={tooltip}>{children}</Tooltip>}>
      <svg
        aria-hidden={!title}
        aria-label={title}
        className={className}
        role="img"
        style={{
          width: `${size}rem`,
          height: `${size}rem`,
          fill: `${color}`,
          ...style,
        }}
        viewBox={viewBox}
      >
        <IconComponent />
      </svg>
    </ConditionalWrapper>
  );
};

export default SvgIcon;
