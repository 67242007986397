import React, { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';

interface DropdownMenuProps {
  options: { label: string; value: string; onClick: (value: string) => void; className?: string }[];
  children: React.ReactNode;
  disabled?: boolean;
  itemClassName?: string;
  itemHoverClassName?: string;
}

const DropdownMenu = ({
  options,
  children,
  disabled = false,
  itemClassName = 'right-0 w-48',
  itemHoverClassName = 'text-gray-700',
}: DropdownMenuProps): JSX.Element => {
  return (
    <Menu as="div" className="relative ml-3" role="menu">
      {({ open }) => (
        <>
          <Menu.Button
            className="flex max-w-xs items-center rounded-full text-sm focus:outline-none"
            disabled={disabled}
            role="button"
          >
            {children}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items
              static
              className={cn(
                'absolute z-50 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                itemClassName
              )}
            >
              {options.map((item) => (
                <Menu.Item key={item.value}>
                  {({ active }) => (
                    <span
                      className={cn(
                        'block cursor-pointer px-4 py-2 text-sm',
                        { [itemHoverClassName]: active },
                        { 'bg-gray-100': active },
                        item.className
                      )}
                      onClick={() => item.onClick(item.value)}
                    >
                      {item.label}
                    </span>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropdownMenu;
