import { useCallback, useState } from 'react';

import cn from 'classnames';
import { FieldPath, FieldValues, PathValue, UnpackNestedValue, UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useUpdateEffect } from 'usehooks-ts';

import { OptionType } from '@app/types';
import { usePreviousValue } from '@hooks/utility/usePreviousValue';

import ActionSelect from '@components/UI/ActionSelect/ActionSelect';
import Input, { InputComponentProps } from '@components/UI/Input/Input';

interface DynamicInputFieldProps<TFieldValues extends FieldValues, TName>
  extends Pick<InputComponentProps, 'onKeyDown'> {
  values: FieldValues;
  setValue: UseFormSetValue<TFieldValues>;
  options: OptionType<TName>[];
  label: string;
  placeholder?: string;
  selectLabel?: string;
  selectClassName?: string;
  className?: string;
  merged?: boolean;
  required?: boolean;
  type?: 'text' | 'search';
  isActionAsPlaceholder?: boolean;
  setActiveOption?: (value: TName) => void;
}

const DynamicInputField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TReturnValue extends UnpackNestedValue<PathValue<TFieldValues, TName>> = UnpackNestedValue<
    PathValue<TFieldValues, TName>
  >
>({
  setValue,
  values,
  options,
  label,
  selectLabel,
  selectClassName,
  className,
  placeholder,
  setActiveOption,
  onKeyDown,
  type = 'text',
  required = false,
  merged = false,
  isActionAsPlaceholder = false,
}: DynamicInputFieldProps<TFieldValues, TName>): JSX.Element => {
  const [name, setName] = useState<TName>(options[0].value);
  const prevName = usePreviousValue<TName>(name);

  useUpdateEffect(() => {
    const resetPrevValue = () => {
      setValue(prevName, '' as TReturnValue);
    };

    resetPrevValue();
  }, [name]);

  const handleSelectChange = useCallback(
    (fieldName: TName) => {
      setValue(fieldName, values[name]);
      setName(fieldName);
      setActiveOption?.(fieldName);
    },
    [name, setValue, values]
  );

  const handleInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, ev.target.value as TReturnValue, { shouldValidate: true });
  };

  return (
    <div className={cn('relative flex w-full items-end', className)}>
      <Input
        className="w-2/3"
        fieldClassName="!rounded-r-none"
        label={label}
        name={name}
        placeholder={isActionAsPlaceholder ? options.find((option) => option.value === name)?.label : placeholder}
        required={required}
        type={type}
        value={values[name]}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
      />
      <ActionSelect<TName>
        buttonClassName={cn({
          'bg-gray-300 rounded-tl-none rounded-bl-none border-l border-y-0 border-r-0': merged,
        })}
        className={twMerge(cn('w-1/3', selectClassName))}
        label={selectLabel}
        options={options}
        required={required}
        onSelect={handleSelectChange}
      />
    </div>
  );
};

export default DynamicInputField;
