import { InfiniteData } from 'react-query';

import { PageParams, PageType, PagingType } from '@app/types';

export const emptyPaginationData = { records: [], currentPage: 1, totalPages: 0 };

export const getRecordsFromPaging = <Model>(pagingData?: InfiniteData<PagingType<Model>>): Model[] | null =>
  pagingData ? pagingData.pages.map((page: PageType<Model>) => page.records).flat() : null;

export const getNextPageParam = <Model>(pageData: PagingType<Model>): PageParams | undefined =>
  pageData.currentPage >= pageData.totalPages
    ? undefined
    : {
        page: pageData.currentPage + 1,
        itemsPerPage: pageData.itemsPerPage,
      };

export const getPreviousPageParam = <Model>(pageData: PagingType<Model>): PageParams | undefined =>
  pageData.currentPage <= 1
    ? undefined
    : {
        page: pageData.currentPage - 1,
        itemsPerPage: pageData.itemsPerPage,
      };
