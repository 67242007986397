import { isString } from 'lodash';

import { Identifier } from '@app/types';

interface SupportedIdentifiersType {
  id?: string;
  ref?: string;
  identifiers?: Identifier[];
}

export const getValuesFromBulkSearchInput = (rawValue: string): string[] => {
  if (rawValue.length === 0) return [];
  return rawValue.split(',').map((value) => value.trim());
};

export const getBulkSearchInputFromValues = (values: string[]): string =>
  values.filter((value) => isString(value) && (value as string).length > 0).join(', ');

export const getIdentifierByType = (identifiers: Identifier[], type: string): Identifier | undefined => {
  return identifiers.find((identifier) => identifier.type.toLowerCase() === type.toLowerCase());
};

export const matchesRecordToIdentifier = (value: string, type: string, record: SupportedIdentifiersType): boolean => {
  const loweredType = type.toLowerCase();
  const loweredValue = value.toLowerCase();

  if (loweredType === 'id' || loweredType === 'ids') {
    return record?.id?.toLowerCase() === loweredValue;
  }
  if (loweredType === 'ref') {
    return record?.ref?.toLowerCase() === loweredValue;
  }
  if (loweredType === 'upc' || loweredType === 'isrc' || loweredType === 'grid') {
    const identifier = getIdentifierByType(record?.identifiers || [], type);
    return identifier?.value?.toLowerCase() === loweredValue;
  }

  return false;
};

export const filterNotFoundBulkSearchValues = <TModel>(
  rawValue: string,
  type: string,
  records: (TModel & SupportedIdentifiersType)[] | undefined
): string[] => {
  const values = getValuesFromBulkSearchInput(rawValue);

  const foundValues = values.filter((value) => {
    return records?.some((record) => matchesRecordToIdentifier(value, type, record));
  });

  return values.filter((value) => !foundValues.includes(value));
};
