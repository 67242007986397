import axios from 'axios';
import _ from 'lodash';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import useToast from '@hooks/useToast';
import UsersAPI from '@services/users-api';

import UserResetPasswordForm from '@pages/user/UserResetPasswordForm/UserResetPasswordForm';

export interface UserResetPasswordFormData {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

const UserResetPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToast();
  const { mutateAsync: updateAsync, isLoading } = useMutation(UsersAPI.updatePassword);

  const updatePassword = async (data: UserResetPasswordFormData) => {
    try {
      await updateAsync(_.omit(data, 'passwordConfirm'));
      showSuccessToast('Password successfully updated');
      navigate('/settings/users/reset-password');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        showErrorToast(err?.response?.data?.message);
      }
    }
  };

  return <UserResetPasswordForm isLoading={isLoading} onSubmit={updatePassword} />;
};

export default UserResetPasswordContainer;
