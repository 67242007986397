import { omitBy } from 'lodash';

import { EMPTY_IMG } from '@app/constants';
import { ImageData } from '@app/types';
import { Artist, ArtistEditData, IRoleArtist } from '@models/Artist';
import { OrderedGenre } from '@models/Genre';
import { getLocaleName } from '@utils/common';

export const getPrimaryArtist = (artists: IRoleArtist[]): Artist | null => {
  if (!artists.length) return null;
  return artists.find((artist) => !!artist.displayArtist)?.value || artists[0].value;
};

export const getArtistName = (artist: Artist): string => {
  return getLocaleName(artist.names);
};

export const getArtistPrimaryImageData = (images?: ImageData[]): ImageData | undefined => {
  return images?.find((img) => img.isPrimary);
};

export const getPrimaryArtistImageUrl = (artistData: Artist): string => {
  const primaryImage = artistData.images.find((img) => img.isPrimary);
  return primaryImage?.imageUrl || EMPTY_IMG;
};

export const getArtistPrimaryGenre = (artistData: Artist): OrderedGenre | undefined => {
  return artistData.genres.find((genre) => genre.order === 1) || artistData.genres[0];
};

export const getArtistGenreName = (genre?: OrderedGenre): string => {
  if (!genre) return '-';
  return getLocaleName(genre.value.names);
};

export const omitInvalidValuesFromArtistPayload = (data: ArtistEditData) => {
  return omitBy(data, (val, key) => (key === 'ref' && val === '') || key === 'albums');
};
