import { immerable } from 'immer';

export class Country implements ICountry {
  id!: string;
  name = '';
  defaultLocale = '';
  region = '';
  ref?: string;

  [immerable] = true;
}

export interface ICountry {
  id: string;
  name: string;
  defaultLocale: string;
  region: string;
  ref?: string;
}
