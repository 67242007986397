import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useUnmountEffect = (callback: EffectCallback, deps: DependencyList = []) => {
  const isUnmounting = useRef(false);

  useEffect(
    () => () => {
      isUnmounting.current = true;
    },
    []
  );

  useEffect(
    () => () => {
      if (isUnmounting.current) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
};
