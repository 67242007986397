import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import DeleteButton from '@components/UI/buttons/DeleteButton/DeleteButton';

interface FieldRowLayoutProps {
  children: React.ReactNode;
  onRemove?: () => void;
  className?: string;
  removeHidden?: boolean;
}

const FieldRowLayout = ({ children, className, onRemove, removeHidden }: FieldRowLayoutProps): JSX.Element => {
  return (
    <div className={twMerge('my-7 flex items-end gap-x-6', className)}>
      {children}
      {onRemove && <DeleteButton updated className={cn('mb-1.5', { invisible: removeHidden })} onClick={onRemove} />}
    </div>
  );
};

export default FieldRowLayout;
