import { Type } from 'class-transformer';
import produce, { immerable } from 'immer';
import { cloneDeep, pick } from 'lodash';

import { Statuses, emptyLocaleField } from '@app/constants';
import { Id, ImageData, LocaleName, Modify, SocialNetworkType } from '@app/types';
import { AccountingPartner } from '@models/AccountingPartner';
import { Album } from '@models/Album';
import { GenreFieldData, OrderedGenre, orderedGenreToFormData } from '@models/Genre';
import { getArtistPrimaryImageData } from '@utils/artists';
import { getLocaleName, getPrimaryImageUrl } from '@utils/common';
import { ARTIST_ROLE_FEATURED_ARTIST, ARTIST_ROLE_MAIN_ARTIST } from '@utils/options';

export class Artist implements IArtist {
  id!: Id;
  names: LocaleName[] = [{ ...emptyLocaleField, isPrimary: true }];
  status: Statuses = Statuses.Unpublished;
  realName: LocaleName[] = [];
  images: ImageData[] = [];
  socialLinks: ArtistSocialLink[] = [];
  bio: ArtistBio[] = [];
  accountingPartners: AccountingPartner[] = [];
  gender = '';
  deathPlace = '';
  birthPlace = '';
  countryOfOrigin = '';
  stateOfOrigin = '';
  cityOfOrigin = '';
  ref = '';
  isni = '';
  hideCompilations = false;
  createdBy?: string;
  updatedBy?: string;

  @Type(() => Date)
  updatedOn?: Date | undefined;

  @Type(() => Date)
  createdOn?: Date | undefined;

  @Type(() => Date)
  dateOfBirth: Date | null = null;

  @Type(() => OrderedGenre)
  genres: OrderedGenre[] = [];

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  @Type(() => SimilarArtist)
  similarArtists: SimilarArtist[] = [];

  @Type(() => Album)
  albums: Album[] = [];

  get displayName() {
    return getLocaleName(this.names);
  }

  get externalLink(): string {
    return `https://web.napster.com/artist/${this.ref}`;
  }

  get primaryImage(): ImageData | null {
    return getArtistPrimaryImageData(this.images) || null;
  }

  get primaryImageUrl(): string {
    return getPrimaryImageUrl(this.primaryImage);
  }

  get statusColor(): { name: string; color: string } {
    if (this.status === Statuses.Published) return { name: 'Published', color: 'bg-green-700 text-white' };
    if (this.status === Statuses.Takedown) return { name: 'Takendown', color: 'bg-red-700 text-white' };
    if (this.status === Statuses.Unpublished)
      return { name: 'Unpublished', color: 'bg-wite border border-gray-400 text-black' };
    return { name: 'unknown', color: 'bg-gray' };
  }

  [immerable] = true;
}

export class RoleArtist implements IRoleArtist {
  id!: Id;
  roles: string[] = [];
  displayArtist = false;

  @Type(() => Artist)
  value!: Artist;

  get isFeaturedArtist(): boolean {
    return this.roles.includes(ARTIST_ROLE_FEATURED_ARTIST);
  }

  get isMainArtist(): boolean {
    return this.roles.includes(ARTIST_ROLE_MAIN_ARTIST);
  }

  static getFeaturedArtistsName(artists?: RoleArtist[]): string {
    if (!artists?.length) return '';

    const featureArtists = artists.filter((artist) => artist.isFeaturedArtist);
    if (!featureArtists.length) return '';

    return featureArtists.map((artist) => artist.value.displayName).join(', ');
  }

  static getMainArtistsName(artists?: RoleArtist[]): string {
    if (!artists?.length) return '';

    const mainArtists = artists?.filter((artist) => artist?.isMainArtist);
    if (!mainArtists?.length) return '';

    return mainArtists?.map((artist) => artist?.value?.displayName).join(', ');
  }

  [immerable] = true;
}

export class SimilarArtist implements ISimilarArtist {
  id!: Id;
  order!: number;
  similarityType = '';

  @Type(() => Artist)
  value!: Artist;
}

export interface IArtist {
  id: Id;
  names: LocaleName[];
  status: Statuses;
  genres: OrderedGenre[];
  similarArtists: SimilarArtist[];
  images: ImageData[];
  albums?: Album[];
  realName?: LocaleName[];
  gender?: string;
  deathPlace?: string;
  birthPlace?: string;
  dateOfBirth?: Date | null;
  countryOfOrigin?: string;
  stateOfOrigin?: string;
  cityOfOrigin?: string;
  socialLinks?: ArtistSocialLink[];
  bio?: ArtistBio[];
  accountingPartners?: AccountingPartner[];
  hideCompilations?: boolean;
  ref?: string;
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export type IRoleArtist = {
  id: Id;
  value: Artist;
  roles: string[];
  displayArtist: boolean;
};

export interface ISimilarArtist {
  id: Id;
  similarityType: string;
  order: number;
  value?: Artist;
}

export interface ArtistImage {
  url: string;
  type: string;
  isPrimary?: boolean;
}

export interface ArtistSocialLink {
  url: string;
  type: SocialNetworkType;
}

export interface ArtistBio {
  value: string;
  locale: string;
  author: string;
}

export type ArtistEditData = Modify<
  Artist,
  {
    similarArtists: ISimilarArtist[];
    genres: Omit<OrderedGenre, 'value'>[];
  }
>;

export interface MergeArtistsFormData {
  merge: ArtistFieldData[];
}

export interface ArtistFieldData {
  id: Id;
  displayName: string;
  displayArtist: boolean;
  roles: string[];
}

export interface SimilarArtistFieldData {
  id: Id;
  displayName: string;
  order: number;
  similarityType: string;
}

export interface ArtistFormData {
  gender: string;
  deathPlace: string;
  birthPlace: string;
  countryOfOrigin: string;
  stateOfOrigin: string;
  cityOfOrigin: string;
  dateOfBirth: Date | null;
  names: LocaleName[];
  realName: LocaleName[];
  genres: GenreFieldData[];
  images: ImageData[];
  socialLinks: ArtistSocialLink[];
  bio: ArtistBio[];
  similarArtists: SimilarArtistFieldData[];
  filteredUnsupportedBios: ArtistBio[] | null;
  hideCompilations: boolean;
}

export const formatForApi = (formData: ArtistFormData): ArtistEditData => {
  const formattedData: unknown = produce<ArtistFormData, ArtistEditData>(cloneDeep(formData), (draft) => {
    draft.genres = formData.genres.map(({ id, order }) => ({ id, order }));
    draft.similarArtists = formData.similarArtists.map(({ id, similarityType }, index) => ({
      id,
      similarityType,
      order: index + 1,
    }));
  });

  return formattedData as ArtistEditData;
};

export const getFormData = (artistData: Artist): ArtistFormData => {
  const formFields = pick(
    artistData,
    'names',
    'realName',
    'bio',
    'socialLinks',
    'gender',
    'birthPlace',
    'deathPlace',
    'dateOfBirth',
    'cityOfOrigin',
    'countryOfOrigin',
    'similarArtists',
    'stateOfOrigin',
    'images',
    'genres',
    'hideCompilations'
  );

  const formData: unknown = produce<typeof formFields, ArtistFormData>(formFields, (draft) => {
    draft.genres = artistData.genres.map(orderedGenreToFormData);
    draft.similarArtists = artistData.similarArtists.map(({ id, similarityType, order, value }) => ({
      id,
      similarityType,
      order,
      displayName: value?.displayName || '',
    }));
  });

  return formData as ArtistFormData;
};

export const roleArtistToFormData = (artist: RoleArtist): ArtistFieldData => {
  return {
    id: artist.id,
    roles: artist.roles,
    displayArtist: artist.displayArtist,
    displayName: artist.value?.displayName || '',
  };
};
