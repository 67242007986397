import { truncate } from 'lodash';
import { Link } from 'react-router-dom';

import { useAbility } from '@contexts/AbilityContext';
import { ARTIST, Abilities } from '@utils/ability';

import LinkWithIcon from '@components/UI/LinkWithIcon/LinkWithIcon';

const ARTIST_NAME_LABEL_MAX_LENGTH = 30;

export interface ArtistNameLabelProps {
  id: string;
  displayName: string;
  disabled?: boolean;
  hideIcon?: boolean;
}

const ArtistNameLabel = ({ id, displayName, disabled, hideIcon }: ArtistNameLabelProps): JSX.Element => {
  const { withAbility } = useAbility();

  const name = truncate(displayName, { length: ARTIST_NAME_LABEL_MAX_LENGTH });
  const shouldDisplayLink = withAbility(Abilities.Read, ARTIST) && !disabled;

  const renderLink = () =>
    hideIcon ? (
      <Link className="hover:text-gray-900 hover:underline" target="_blank" to={`/artists/${id}`}>
        <span>{name}</span>
      </Link>
    ) : (
      <LinkWithIcon openInNewTab link={`/artists/${id}`}>
        {name}
      </LinkWithIcon>
    );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{shouldDisplayLink ? renderLink() : <span>{displayName}</span>}</>
  );
};

export default ArtistNameLabel;
