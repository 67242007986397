import React, { Fragment, memo } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import noop from 'lodash/noop';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  width?: 'xl' | '6xl' | '7xl';
  className?: string;
}

const Modal = ({ children, open, setOpen, onClose = noop, width = 'xl', className }: ModalProps): JSX.Element => {
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const widthClass = () => {
    return width === 'xl' ? 'sm:max-w-xl' : 'sm:max-w-7xl';
  };

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog static as="div" className="fixed inset-0 z-10 overflow-y-auto" open={open} onClose={handleClose}>
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:items-end sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span aria-hidden="true" className="hidden sm:inline-block sm:h-screen sm:align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={cn(
                className,
                'relative mb-16 inline-block transform rounded-sm bg-white p-8 text-left shadow-xl transition-all sm:mt-10 sm:w-full sm:align-top',
                widthClass()
              )}
            >
              <div className="absolute hidden pt-12 pr-20 sm:block" style={{ top: '-30px', right: '-55px' }}>
                <button
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                  type="button"
                  onClick={handleClose}
                >
                  <XIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default memo(Modal);
