import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { omitBy } from 'lodash';

import { AnyObject, Id, PageParams, PagingType } from '@app/types';
import { ITrack, Track, TrackEditData } from '@models/Track';
import API from '@services/api';

import { API_BASE_URL, DEFAULT_ITEMS_PER_PAGE } from './constants';

export const TRACKS_URL = `${API_BASE_URL}/v1/tracks`;
export const TRACK_STATUS_URL = `${API_BASE_URL}/v1/files/tracks`;

interface UpdateTrackArgs {
  id: Id;
  data: TrackEditData;
}

class TracksAPI extends API {
  url = TRACKS_URL;

  constructor() {
    super(TRACKS_URL);
  }

  fetchTracks = async <Params extends AnyObject>(
    params: Params,
    pageParams = { page: 1, itemsPerPage: DEFAULT_ITEMS_PER_PAGE } as PageParams,
    populate?: boolean | null
  ): Promise<PagingType<Track>> => {
    const data = await this.fetchPaginatedData<ITrack, Params>(params, pageParams, populate);
    const tracks = plainToInstance(Track, data.records);

    return {
      ...data,
      records: tracks,
    };
  };

  fetchTrack = async (id: string, populate?: boolean): Promise<Track> => {
    const data = await this.fetchData<ITrack>(id, populate);
    return plainToInstance(Track, data);
  };

  createTrack = async (data: TrackEditData) =>
    axios.post(
      TRACKS_URL,
      omitBy(data, (value) => (Array.isArray(value) && !value.length) || !value)
    );

  updateTrack = async ({ id, data }: UpdateTrackArgs) => {
    return axios.patch(`${TRACKS_URL}/${id}`, data);
  };

  updateManyTracks = async (newData: Partial<Track>[]) =>
    axios.patch(`${TRACKS_URL}/update/many`, {
      items: newData.map((track) => ({ id: track.id, groups: track.groups?.map((group) => group.id) })),
    });

  deleteTrack = async (id: string) => axios.delete(`${TRACKS_URL}/${id}`);

  fetchTrackStatus = async (id: string): Promise<string | null> => {
    try {
      const response = await axios.get(`${TRACK_STATUS_URL}/${id}/status`);
      return response.data.status;
    } catch {
      return null;
    }
  };
}

export default new TracksAPI();
