import { immerable } from 'immer';

import { IGenericMiniResponse, OptionType } from '@app/types';

export class Partner implements IPartner {
  id!: string;
  name = '';
  countryData = [];
  enabled = false;
  useNapsterForPopularity?: boolean;
  ref?: string;

  [immerable] = true;
}
export interface IPartner {
  id: string;
  name: string;
  countryData: string[];
  enabled: boolean;
  useNapsterForPopularity?: boolean;
  ref?: string;
}

export class PartnerMini implements IGenericMiniResponse {
  id!: string;
  name = '';
}

export interface PartnersSearchParams {
  name: string;
}

export const getPartnersSearchParams = (search: string): PartnersSearchParams => ({
  name: search,
});

export const getPartnersOptions = (data: { records: PartnerMini[] }): OptionType[] => {
  return data.records.map((partnerMini: PartnerMini) => ({
    value: partnerMini.id,
    label: partnerMini.name,
  }));
};
