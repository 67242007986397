import Spinner from '../Spinner/Spinner';

const LoadingScreen = (): JSX.Element => {
  return (
    <div className=" flex h-full w-full items-center justify-center">
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
