import { QueryObserverResult, useQuery } from 'react-query';

import { MediaStreamData, MediaStreamRequestData } from '@models/File';
import API from '@services/media-stream-api';

interface QueryType {
  mediaStreamData: MediaStreamData[] | undefined;
  fetchMediaStream: () => Promise<QueryObserverResult<MediaStreamData[]>>;
  isMediaStreamError: boolean;
  isMediaStreamLoading: boolean;
  mediaStreamError: Error | null;
}

export const useMediaStream = (requestData: MediaStreamRequestData, options = {}): QueryType => {
  const {
    data: mediaStreamData,
    refetch: fetchMediaStream,
    isError: isMediaStreamError,
    isLoading: isMediaStreamLoading,
    error: mediaStreamError,
  } = useQuery<MediaStreamData[], Error>(['media-stream', requestData], API.fetchMediaStream, options);

  const normalizedData = mediaStreamData
    ? mediaStreamData.map((media) => ({ ...media, id: media.media_id }))
    : mediaStreamData;

  return {
    mediaStreamData: normalizedData,
    fetchMediaStream,
    isMediaStreamError,
    isMediaStreamLoading,
    mediaStreamError,
  };
};
