import cn from 'classnames';
import { FieldError, RefCallBack } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import Spinner from '@components/UI/Spinner/Spinner';

import SvgIcon from '../SvgIcon/SvgIcon';

export const Input = ({ children, className }: React.HTMLProps<HTMLDivElement>): JSX.Element => {
  return <div className={cn(className)}>{children}</div>;
};

Input.Label = ({ children, htmlFor, required }: React.HTMLProps<HTMLLabelElement>): JSX.Element => {
  return (
    <label className="field-label" htmlFor={htmlFor}>
      {children} {required && '*'}
    </label>
  );
};

Input.Field = ({
  type,
  className,
  disabled,
  inputRef,
  invalid,
  ...inputProps
}: React.HTMLProps<HTMLInputElement> & { inputRef?: RefCallBack; invalid?: boolean }): JSX.Element => {
  return (
    <div className="relative w-full focus-within:outline-none">
      {(type === 'search' || type === undefined) && (
        <div className="pointer-events-none absolute inset-y-0 flex items-center px-2">
          <SvgIcon color="#4B5563" name="search-input" size={1} />
        </div>
      )}
      <input
        ref={inputRef}
        autoComplete="off"
        className={twMerge(
          cn(
            'block w-full bg-gray-100 p-2 text-left text-base text-black-base shadow-sm focus:outline-none focus:ring-transparent',
            { 'pl-9': type === 'search' || type === undefined },
            { 'border-red-300 text-red-900 focus:border-red-500': invalid },
            { 'bg-gray-100': disabled }
          ),
          className
        )}
        disabled={disabled}
        type={type}
        {...inputProps}
      />
    </div>
  );
};

Input.Meta = ({
  error,
  valueLength,
  maxLength,
  asStatic,
}: {
  error?: FieldError;
  valueLength?: number;
  maxLength?: number;
  asStatic?: boolean;
}): JSX.Element => {
  return (
    <div className={cn('flex justify-between text-right', { absolute: !asStatic })}>
      {error && <p className="mt-1 ml-1 mr-5 text-sm text-red-600">{error.message}</p>}
      {maxLength && (
        <p className="mt-1 flex-1 justify-self-end text-xs text-gray-500">{`${valueLength || 0}/${maxLength}`}</p>
      )}
    </div>
  );
};

export interface InputComponentProps extends React.HTMLProps<HTMLInputElement> {
  value: string;
  fieldClassName?: string;
  inputRef?: RefCallBack;
  isLoading?: boolean;
  error?: FieldError;
}

const InputComponent = ({
  className,
  fieldClassName,
  label,
  id,
  name,
  required,
  placeholder,
  value,
  inputRef,
  error,
  maxLength,
  isLoading,
  ...inputProps
}: InputComponentProps): JSX.Element => {
  return (
    <Input className={twMerge('relative w-full', className)}>
      {label && (
        <Input.Label htmlFor={id || name} required={required}>
          {label}
        </Input.Label>
      )}
      <Input.Field
        {...inputProps}
        aria-describedby={label}
        className={fieldClassName}
        id={id || name}
        inputRef={inputRef}
        invalid={!!error}
        name={name}
        placeholder={placeholder || label}
        value={value}
      />
      {isLoading && (
        <div className="absolute right-3 bottom-2.5">
          <Spinner size={6} />
        </div>
      )}
      <Input.Meta error={error} maxLength={maxLength} valueLength={value?.length} />
    </Input>
  );
};

export default InputComponent;
