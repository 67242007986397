import { useFormContext } from 'react-hook-form';

import FieldRowLayout from '@components/UI/layout/FieldRowLayout/FieldRowLayout';
import InputField from '@components/form-fields/InputField/InputField';

import { UserResetPasswordFormData } from '@pages/user/UserResetPasswordContainer/UserResetPasswordContainer';

const UserResetPasswordView = (): JSX.Element => {
  const { control } = useFormContext<UserResetPasswordFormData>();

  return (
    <div>
      <h4 className="pt-4">Reset password</h4>
      <FieldRowLayout>
        <InputField required control={control} label="Old password" name="oldPassword" type="password" />
      </FieldRowLayout>
      <FieldRowLayout>
        <InputField required control={control} label="New password" name="password" type="password" />
        <InputField
          required
          control={control}
          label="Confirm password"
          name="passwordConfirm"
          placeholder="Please confirm your new password"
          type="password"
        />
      </FieldRowLayout>
    </div>
  );
};

export default UserResetPasswordView;
