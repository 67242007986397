import { IGenericMiniResponse, OptionType } from '@app/types';
import { User } from '@models/User';
import UsersAPI from '@services/users-api';

export const passwordPlaceholder = '********';

export const isUserDeactivated = (userData: User): boolean => userData.status === 'DEPROVISIONED';

export const hasRole = (roleName: string, user: User): boolean =>
  !!user?.roles?.find(({ name }) => name?.toLowerCase() === roleName.toLowerCase());

export const getUserPartner = (partners: IGenericMiniResponse[]) =>
  partners?.find(({ name }) => name === 'Napster') || partners?.[0];

export const getUserPartnerOption = (user: User): OptionType => {
  const partner = getUserPartner(user.partners || []);
  return { label: partner.name, value: partner.id };
};

export const getUsersSearchParams = (search: string): { textSearch: string; sortProperty: string } => ({
  textSearch: search,
  sortProperty: 'name',
});

export const getUsersOptions = (data: { records: User[] }): OptionType[] => {
  return data.records.map((user: User) => ({
    value: JSON.stringify({ name: user.fullName, id: user.id }),
    label: user.fullName,
  }));
};

export const fetchUsersData = (params: { textSearch: string; sortProperty: string }) =>
  UsersAPI.fetchUsers(params, {}, null);
