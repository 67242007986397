import { ALL_COUNTRIES_VALUE, Countries, DEFAULT_LOCALE, EMPTY_IMG } from '@app/constants';
import { LocaleName } from '@app/types';
import { Video, VideoCountry } from '@models/Video';

export const getVideoCountry = (video: Video): VideoCountry => {
  return video.countryData.find((cd) => cd.country === ALL_COUNTRIES_VALUE) || video.countryData[0];
};

export const getVideoImage = (video: Video): string => {
  const country = getVideoCountry(video);
  return country?.images[0]?.imageUrl || EMPTY_IMG;
};

export const getVideoReleaseDate = (video: Video): string | null => {
  const { releaseDate } = getVideoCountry(video);

  if (!releaseDate) return null;

  return new Date(releaseDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

export const emptyVideoName: LocaleName = {
  value: '',
  locale: DEFAULT_LOCALE,
  source: 'napster',
};

export const emptyCountry = {
  country: '' as Countries,
  releaseDate: null,
  explicit: false,
  imprints: [],
  genres: [],
  images: [],
  relatedReleases: [],
};
