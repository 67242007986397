import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useFormValidationAlert } from '@hooks/useFormValidationAlert';
import { passwordPlaceholder } from '@utils/user';

import Section from '@components/UI/Section/Section';
import FormControlsLayout from '@components/UI/layout/FormControlsLayout/FormControlsLayout';

import { UserResetPasswordFormData } from '@pages/user/UserResetPasswordContainer/UserResetPasswordContainer';
import UserResetPasswordView from '@pages/user/UserResetPasswordView/UserResetPasswordView';

const defaultValues: UserResetPasswordFormData = {
  oldPassword: '',
  password: '',
  passwordConfirm: '',
};

const schema = Yup.object().shape(
  {
    oldPassword: Yup.string().required('Field is required'),
    password: Yup.string()
      .required('Field is required')
      .min(8, 'Password should have at least 8 characters')
      .when('password', {
        is: (value: string) => value !== passwordPlaceholder,
        then: Yup.string().matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
        ),
      }),
    passwordConfirm: Yup.string()
      .required('Field is required')
      .min(8, 'Password should have at least 8 characters')
      .when('password', {
        is: (password: string) => password && password.length > 0,
        then: Yup.string().oneOf([Yup.ref('password')], 'New passwords fields must match'),
      }),
  },
  [['password', 'password']]
);

export interface UserResetPasswordFormProps {
  onSubmit: (data: UserResetPasswordFormData) => Promise<void>;
  isLoading?: boolean;
}

const UserResetPasswordForm = ({ onSubmit, isLoading = false }: UserResetPasswordFormProps): JSX.Element => {
  const formMethods = useForm<UserResetPasswordFormData>({
    defaultValues,
    mode: 'all',
    delayError: 500,
    resolver: yupResolver(schema),
  });
  const { isValid, submitCount } = formMethods.formState;

  useFormValidationAlert(isValid, submitCount);

  return (
    <div>
      <h2 className="mb-7">Reset your account password</h2>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Section className="my-0 px-6 pt-2 pb-4">
            <UserResetPasswordView />
          </Section>
          <FormControlsLayout isSaving={!!isLoading} />
        </form>
      </FormProvider>
    </div>
  );
};

export default UserResetPasswordForm;
