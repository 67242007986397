import { Suspense, lazy } from 'react';

import { LoginCallback } from '@okta/okta-react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoadingScreen from '@components/UI/LoadingScreen/LoadingScreen';
import PageLoader from '@components/UI/PageLoader/PageLoader';
import PrivateRoute from '@components/utils/PrivateRoute/PrivateRoute';
import { RequiredAuth } from '@components/utils/RequireAuth/RequireAuth';

import TrackGroupDetailPage from '@pages/track-group/TrackGroupDetailPage/TrackGroupDetailPage/TrackGroupDetailPage';
import TrackGroupEditPage from '@pages/track-group/TrackGroupEditPage/TrackGroupEditPage';
import TrackGroupNewPage from '@pages/track-group/TrackGroupNewPage/TrackGroupNewPage';
import UserResetPasswordContainer from '@pages/user/UserResetPasswordContainer/UserResetPasswordContainer';

const NotFound = lazy(() => import('@components/UI/NotFound/NotFound'));

const ArtistMenuContainer = lazy(() => import('@pages/artist-editor/ArtistMenuContainer'));
const ArtistDetailContainer = lazy(
  () => import('@pages/artist/ArtistDetailPage/ArtistDetailContainer/ArtistDetailContainer')
);
const ArtistEditContainer = lazy(() => import('@pages/artist/ArtistEditPage/ArtistEditContainer/ArtistEditContainer'));
const ArtistCreateContainer = lazy(() => import('@pages/artist/ArtistCreateContainer/ArtistCreateContainer'));

const AlbumEditorContainer = lazy(() => import('@pages/album-editor/AlbumEditorContainer/AlbumEditorContainer'));
const AlbumDetailContainer = lazy(() => import('@pages/album/detail/AlbumDetailContainer/AlbumDetailContainer'));
const AlbumEditContainer = lazy(() => import('@pages/album/AlbumEditContainer/AlbumEditContainer'));
const AlbumCreateContainer = lazy(() => import('@pages/album/AlbumCreateContainer/AlbumCreateContainer'));
const AlbumListContainer = lazy(() => import('@pages/artist/ArtistDetailPage/AlbumListContainer/AlbumListContainer'));

const GenreEditorContainer = lazy(() => import('@pages/genre-editor/GenreEditorContainer/GenreEditorContainer'));
const GenreCreateContainer = lazy(() => import('@pages/genre/GenreCreateContainer/GenreCreateContainer'));
const GenreEditContainer = lazy(() => import('@pages/genre/GenreEditContainer/GenreEditContainer'));
const GenreDetailContainer = lazy(
  () => import('@pages/genre/GenreDetailPage/GenreDetailContainer/GenreDetailContainer')
);

const IngestionMenuContainer = lazy(() => import('@pages/ingestion/IngestionMenuContainer/IngestionMenuContainer'));
const IngestionMapsDetailContainer = lazy(
  () => import('@pages/ingestion/IngestionMaps/IngestionMapsDetailContainer/IngestionMapsDetailContainer')
);
const IngestionMapsEditContainer = lazy(
  () => import('@pages/ingestion/IngestionMaps/IngestionMapsEditContainer/IngestionMapsEditContainer')
);
const IngestionMapsCreateContainer = lazy(
  () => import('@pages/ingestion/IngestionMaps/IngestionMapsEditContainer/IngestionMapsCreateContainer')
);

const TrackEditorMenuContainer = lazy(
  () => import('@pages/track-editor/TrackEditorMenuContainer/TrackEditorMenuContainer')
);
const TrackDetailContainer = lazy(
  () => import('@pages/track/TrackDetailPage/TrackDetailContainer/TrackDetailContainer')
);
const TrackEditContainer = lazy(() => import('@pages/track/TrackEditPage/TrackEditContainer/TrackEditContainer'));

const ProgrammedListDetailContainer = lazy(
  () => import('@pages/programmed-list/video-atmos/ProgrammedListDetailContainer/ProgrammedListDetailContainer')
);

const ProgrammedListEditContainer = lazy(
  () =>
    import(
      '@pages/programmed-list/video-atmos/ProgrammedListEditPage/ProgrammedListEditContainer/ProgrammedListEditContainer'
    )
);
const ProgrammedListCreateContainer = lazy(
  () => import('@pages/programmed-list/video-atmos/ProgrammedListCreateContainer/ProgrammedListCreateContainer')
);

const ProgrammedListMenuContainer = lazy(() => import('@pages/programmed-list/ProgrammedListMenuContainer'));

const ProgrammedListCollectionDetailContainer = lazy(
  () =>
    import(
      '@pages/programmed-list/collections/CollectionDetailPage/CollectionDetailContainer/CollectionDetailContainer'
    )
);

const ProgrammedListCollectionCreateContainer = lazy(
  () => import('@pages/programmed-list/collections/CollectionCreateContainer/CollectionCreateContainer')
);
const ProgrammedListCollectionEditContainer = lazy(
  () => import('@pages/programmed-list/collections/CollectionEditContainer/CollectionEditContainer')
);

const PlaylistEditorContainer = lazy(
  () => import('@pages/playlist-editor/PlaylistEditorContainer/PlaylistEditorContainer')
);
const PlaylistDetailPage = lazy(() => import('@pages/playlist/PlaylistDetailPage/PlaylistDetailPage'));
const PlaylistCreateContainer = lazy(() => import('@pages/playlist/PlaylistCreateContainer/PlaylistCreateContainer'));
const PlaylistEditContainer = lazy(() => import('@pages/playlist/PlaylistEditContainer/PlaylistEditContainer'));

const VideoEditorContainer = lazy(() => import('@pages/video-editor/VideoEditorContainer/VideoEditorContainer'));
const VideoEditContainer = lazy(() => import('@pages/video/VideoEditContainer/VideoEditContainer'));
const VideoDetailContainer = lazy(() => import('@pages/video/detail/VideoDetailContainer/VideoDetailContainer'));
const VideoCreateContainer = lazy(() => import('@pages/video/VideoCreateContainer/VideoCreateContainer'));

const UserEditorContainer = lazy(() => import('@app/pages/user/UserEditorContainer/UserEditorContainer'));
const UserCreateContainer = lazy(() => import('@pages/user/UserCreateContainer/UserCreateContainer'));
const UserDetailContainer = lazy(() => import('@pages/user/UserDetailPage/UserDetailContainer/UserDetailContainer'));
const UserEditContainer = lazy(() => import('@pages/user/UserEditContainer/UserEditContainer'));

const FeaturedTagEditorContainer = lazy(
  () => import('@pages/featured-tag/FeaturedTagEditorContainer/FeaturedTagEditorContainer')
);
const FeaturedTagDetailContainer = lazy(
  () => import('@pages/featured-tag/FeaturedTagDetailContainer/FeaturedTagDetailContainer')
);

const BlogPostEditorContainer = lazy(
  () => import('@pages/blog-post-editor/BlogPostEditorContainer/BlogPostEditorContainer')
);
const BlogPostDetailContainer = lazy(
  () => import('@pages/blog-post/BlogPostDetailPage/BlogPostDetailContainer/BlogPostDetailContainer')
);

const LabelEditorContainer = lazy(() => import('@pages/label-editor/LabelEditorContainer/LabelEditorContainer'));

const routes = [
  { path: '/', render: () => <Navigate to="/artist-editor" />, exact: true },
  { path: '/partners', render: () => <LoadingScreen />, exact: true },
  { path: '/artist-editor', render: () => <ArtistMenuContainer />, exact: true },
  { path: '/album-editor', render: () => <AlbumEditorContainer />, exact: true },
  { path: '/track-editor', render: () => <TrackEditorMenuContainer />, exact: true },
  { path: '/video-editor', render: () => <VideoEditorContainer />, exact: false },
  { path: '/genre-editor', render: () => <GenreEditorContainer />, exact: true },
  { path: '/playlist-editor', render: () => <PlaylistEditorContainer />, exact: true },
  { path: '/programmed-list-menu', render: () => <ProgrammedListMenuContainer />, exact: true },
  { path: '/featured-tag-editor', render: () => <FeaturedTagEditorContainer />, exact: true },
  { path: '/label-editor', render: () => <LabelEditorContainer />, exact: true },
  { path: '/settings/users/new', render: () => <UserCreateContainer />, exact: true },
  { path: '/settings/users/:userId/edit', render: () => <UserEditContainer />, exact: true },
  { path: '/settings/users/:userId', render: () => <UserDetailContainer />, exact: true },
  { path: '/settings/users', render: () => <UserEditorContainer />, exact: true },
  { path: '/settings/user/reset-password', render: () => <UserResetPasswordContainer />, exact: true },
  { path: '/genres/:genreId/edit', render: () => <GenreEditContainer />, exact: true },
  { path: '/videos/:videoId/edit', render: () => <VideoEditContainer />, exact: true },
  { path: '/tracks/:trackId/edit', render: () => <TrackEditContainer />, exact: true },
  { path: '/videos/new', render: () => <VideoCreateContainer />, exact: true },
  { path: '/videos/:videoId', render: () => <VideoDetailContainer />, exact: true },
  { path: '/artists/new', render: () => <ArtistCreateContainer />, exact: true },
  { path: '/genres/new', render: () => <GenreCreateContainer />, exact: true },
  { path: '/programmed-lists/new', render: () => <ProgrammedListCreateContainer />, exact: true },
  { path: '/programmed-lists/new/:programmedListId', render: () => <ProgrammedListCreateContainer />, exact: true },
  { path: '/programmed-lists/:programmedListId', render: () => <ProgrammedListDetailContainer />, exact: true },
  { path: '/programmed-lists/:programmedListId/edit', render: () => <ProgrammedListEditContainer />, exact: true },
  { path: '/playlists/new', render: () => <PlaylistCreateContainer />, exact: true },
  { path: '/playlists/:playlistId', render: () => <PlaylistDetailPage />, exact: true },
  { path: '/playlists/:playlistId/edit', render: () => <PlaylistEditContainer />, exact: true },
  { path: '/artists/:artistId', render: () => <ArtistDetailContainer />, exact: true },
  { path: '/artists/:artistId/albums', render: () => <AlbumListContainer />, exact: true },
  { path: '/tracks/:trackId', render: () => <TrackDetailContainer />, exact: true },
  { path: '/artists/:artistId/edit', render: () => <ArtistEditContainer />, exact: true },
  { path: '/genres/:genreId', render: () => <GenreDetailContainer />, exact: true },
  { path: '/albums/:albumId/edit', render: () => <AlbumEditContainer />, exact: true },
  { path: '/albums/new', render: () => <AlbumCreateContainer />, exact: true },
  { path: '/albums/:albumId', render: () => <AlbumDetailContainer />, exact: true },
  { path: '/featured-tags/:featuredTagId', render: () => <FeaturedTagDetailContainer />, exact: true },
  { path: '/ingestion/:mapType/new', render: () => <IngestionMapsCreateContainer />, exact: true },
  { path: '/ingestion/:mapType/:ingestionMapId/edit', render: () => <IngestionMapsEditContainer />, exact: true },
  { path: '/ingestion/:mapType/:ingestionMapId', render: () => <IngestionMapsDetailContainer />, exact: true },
  { path: '/ingestion', render: () => <IngestionMenuContainer />, exact: true },
  { path: '/blog-post-editor', render: () => <BlogPostEditorContainer />, exact: true },
  { path: '/blog-post/:blogPostId', render: () => <BlogPostDetailContainer />, exact: true },
  { path: '/track-groups/new', render: () => <TrackGroupNewPage /> },
  { path: '/track-groups/:trackGroupId', render: () => <TrackGroupDetailPage /> },
  { path: '/track-groups/:trackGroupId/edit', render: () => <TrackGroupEditPage /> },
  { path: '/collections/:collectionId', render: () => <ProgrammedListCollectionDetailContainer />, exact: true },
  { path: '/collections/new', render: () => <ProgrammedListCollectionCreateContainer />, exact: true },
  { path: '/collections/:collectionId/edit', render: () => <ProgrammedListCollectionEditContainer />, exact: true },
];

const AppRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route element={<LoginCallback />} path="/login/callback" />
        {routes.map((route) => (
          <Route key={route.path} element={<RequiredAuth />} path={`${route.path}${route.exact ? '' : '/*'}`}>
            <Route
              element={<PrivateRoute path={route.path} render={route.render} />}
              path={`${route.exact ? '' : '*'}`}
            />
          </Route>
        ))}
        <Route element={<PrivateRoute path="*" render={() => <NotFound />} />} path="*" />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
