import axios from 'axios';
import { plainToInstance } from 'class-transformer';
import { pick } from 'lodash';
import { QueryFunctionContext, QueryKey } from 'react-query';

import { Errors } from '@app/constants';
import { AnyObject, PageParams, PagingType } from '@app/types';
import { Tag } from '@models/Tag';
import { IUser, User, UserApiData, UserUpdatePasswordData } from '@models/User';
import API from '@services/api';
import { API_AUTH_URL, DEFAULT_ITEMS_PER_PAGE } from '@services/constants';
import PartnersAPI from '@services/partners-api';
import TagsAPI from '@services/tags-api';

export const AUTH_URL = `${API_AUTH_URL}/v1/auth`;
export const USER_SERVICE_URL = `${API_AUTH_URL}/v1/users`;

class UsersAPI extends API {
  constructor() {
    super(USER_SERVICE_URL);
  }
  fetchUserTags = async (tags: string) => {
    const { records } = await TagsAPI.fetchTags({ ids: tags }, undefined, true);
    return records;
  };
  fetchProfile = async () => {
    const response = await axios.get(`${AUTH_URL}/profile`);
    if ('partner' in response.data) {
      const partnerData = await PartnersAPI.fetchPartner(response.data.partner);
      response.data.partner = partnerData;
    }

    if (response.data.id.includes('auth0') && !!response.data.tags?.length) {
      response.data.tags = await this.fetchUserTags(response.data.tags.join());
    }
    return response.data;
  };

  fetchUsers = async <Params extends AnyObject>(
    params: Params,
    pageParams = { page: 1, itemsPerPage: DEFAULT_ITEMS_PER_PAGE } as PageParams,
    populate?: boolean | null
  ): Promise<PagingType<User>> => {
    const queryParams = {
      ...params,
      name: null,
      email: null,
    };
    const data = await this.fetchPaginatedData<IUser, Params>(queryParams, pageParams, populate);
    const records = plainToInstance(User, data.records);

    return { ...data, records };
  };

  fetchUser = async ({ queryKey }: QueryFunctionContext<QueryKey, User>): Promise<User> => {
    const [, { id }] = queryKey as ['user', { id: string }];

    try {
      const response = await axios.get(`${USER_SERVICE_URL}/${id}`);

      if (id.includes('auth0')) {
        let tags: Tag[] = [];

        if (response.data.tags?.length) tags = await this.fetchUserTags(response.data.tags.join());

        return plainToInstance(User, { ...response.data, tags });
      }

      return plainToInstance(User, response.data);
    } catch (error) {
      if (!axios.isAxiosError(error)) throw Error('Unexpected error');

      const { statusCode } = error.response!.data;

      if (statusCode === Errors.NotFound || statusCode === Errors.BadRequest) {
        throw new Error(`Error ${statusCode}`);
      }

      throw new Error('Unexpected error');
    }
  };

  createUser = async (data: UserApiData) => {
    const pickedFields = ['email', 'firstName', 'lastName', 'roleId', 'username', 'partners', 'password'];
    return axios.post(USER_SERVICE_URL, pick(data, pickedFields));
  };

  createAuth0User = async (data: UserApiData) => {
    const pickedFields = ['email', 'firstName', 'lastName', 'roleId', 'username', 'partners', 'password', 'tags'];
    return axios.post(`${USER_SERVICE_URL}/auth0`, pick(data, pickedFields));
  };

  updatePassword = async (data: UserUpdatePasswordData) => {
    return axios.patch(`${AUTH_URL}/update-password`, data);
  };

  updateUser = async (data: UserApiData) => {
    const pickedFields = ['email', 'firstName', 'lastName', 'roleId', 'username', 'partners', 'tags'];
    return axios.patch(`${USER_SERVICE_URL}/${data.id}`, pick(data, pickedFields));
  };

  deleteUser = async (id: string) => axios.delete(`${USER_SERVICE_URL}/${id}`);

  reactivateUser = async (id: string) => axios.post(`${USER_SERVICE_URL}/${id}/reactivate`);
}

export default new UsersAPI();
