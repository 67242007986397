import axios, { AxiosError } from 'axios';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import uniq from 'lodash/uniq';

import { ALL_COUNTRIES_VALUE, Countries, DEFAULT_LOCALE, EMPTY_IMG, Statuses, UsageTypes } from '@app/constants';
import {
  Availability,
  AvailabilityDate,
  ErrorResponseData,
  Identifier,
  ImageData,
  LocaleName,
  OptionType,
} from '@app/types';
import { OrderedGenre } from '@models/Genre';
import { Identifiers, SortTypes } from '@utils/options';

import { IngestionTakedownFormValues } from '@pages/ingestion/IngestionTakedowns/IngestionTakedowns';

export type GenericCountryData = { country: string; images?: ImageData[]; explicit?: boolean };

export const isExplicit = <T extends GenericCountryData>(countryData?: T[]): boolean => {
  if (!countryData) return false;
  const wwCountry = countryData.find((data: GenericCountryData) => data.country === Countries.WW);
  if (wwCountry) return !!wwCountry.explicit;
  return countryData.some((data) => !!data.explicit);
};

const upcPattern = /^[0-9]{12}$/;
const gridPattern = /[a-zA-Z0-9]{18}/;

export const getDefaultLocaleName = (names: LocaleName[]): LocaleName | null => {
  const defaultLocaleNames = names.filter((name) => name?.locale === DEFAULT_LOCALE);
  if (!defaultLocaleNames.length) return null;

  return defaultLocaleNames.find((name) => name?.isPrimary) || defaultLocaleNames[0];
};

export const getLocaleName = (names: LocaleName[]): string => {
  if (!names?.length) return '';

  const defaultLocaleName = getDefaultLocaleName(names);

  if (defaultLocaleName) {
    return defaultLocaleName.value;
  }

  const primaryName = names.find((item) => item?.isPrimary);

  return primaryName ? primaryName?.value : names?.[0]?.value;
};

export const getPrimaryCountryData = <T extends GenericCountryData>(countryData: T[]): T => {
  return countryData.find((data) => data.country === Countries.WW) || countryData[0];
};

export const getPrimaryImageFromCountry = <T extends GenericCountryData>(countryData: T): ImageData | null => {
  const { images } = countryData;

  if (!images?.length) return null;

  return images.find((img) => img.isPrimary) || images[0];
};

export const getPrimaryImageUrl = (image: ImageData | null) => {
  return image?.imageUrl || EMPTY_IMG;
};

export const getSharedStatusColor = (status: Statuses): { name: string; color: string } => {
  if (status === Statuses.Published) return { name: 'Published', color: 'bg-green-700 text-white' };
  if (status === Statuses.Unpublished) return { name: 'Unpublished', color: 'bg-red-700 text-white' };
  if (status === Statuses.Takedown) return { name: 'Takendown', color: 'bg-red-700 text-white' };
  if (status === Statuses.Pending) return { name: 'Pending', color: 'bg-wite border border-gray-400 text-black' };

  return { name: 'unknown', color: 'bg-gray' };
};

export const getIdentifierValue = (type: Identifiers, identifiers: Identifier[]): string => {
  return identifiers.find((id) => id.type === type)?.value || '';
};

export const getPrimaryAvailability = (availabilities: Availability[]): Availability | null => {
  return availabilities.find((av) => av.country === Countries.WW) || availabilities[0] || null;
};

export const getPrimaryGenre = (genres: OrderedGenre[]): OrderedGenre | null => {
  return genres.find((genre) => genre.order === 1) || genres[0] || null;
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const updateOrderField = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  if (list.length === 0) return list;

  const reorderedList = reorder(list, startIndex, endIndex);

  return reorderedList.map((item, idx) => ({
    ...item,
    order: idx + 1,
  }));
};

export const getErrorResponseData = (err: AxiosError): ErrorResponseData => {
  return err.response?.data;
};

export const getSaveErrorMessages = (err: AxiosError): string[] => {
  const errorResponseData = getErrorResponseData(err);
  return errorResponseData.details?.map((item) => item.message) || [errorResponseData.message];
};

export const handleRequestError = (error: unknown): string => {
  if (!axios.isAxiosError(error)) throw Error('Unexpected error');

  if (error?.response?.data.statusCode !== 404) {
    throw new Error('Unexpected error');
  }

  throw new Error('Item not found');
};

export const getExcludedCountries = (availabilities: Availability[]): string[] => {
  return availabilities.reduce((acc: string[], curr: Availability) => {
    return uniq(acc.concat(curr.country === ALL_COUNTRIES_VALUE ? curr.excludedCountries! : curr.country));
  }, []);
};

export const formatAvailabilities = (availabilities: Availability[]): Availability[] => {
  return availabilities.map((item) => {
    return produce(item, (draft) => {
      if (draft.country === ALL_COUNTRIES_VALUE) {
        draft.excludedCountries = getExcludedCountries(availabilities);
      } else {
        delete draft.excludedCountries;
      }

      draft.availabilityDates.forEach((date, idx) => {
        draft.availabilityDates[idx].expiresOn = item.availabilityDates[0].expiresOn;
        draft.availabilityDates[idx].availableOn = item.availabilityDates[0].availableOn;
        draft.availabilityDates[idx].isRegional = item.availabilityDates[0].isRegional;
      });
    });
  });
};

export const getOptionsFromValue = (options: OptionType[], value: string | string[] | null): OptionType[] => {
  if (!value) return [];

  const valueArr = Array.isArray(value) ? value : value.split(',').map((val) => val.trim());
  return options.filter((opt) => valueArr.includes(opt.value));
};

export const sortCountriesByWw = <CountryWithCodeType extends GenericCountryData>(
  countries: CountryWithCodeType[]
): CountryWithCodeType[] => {
  const sortedCountries = [...countries];
  sortedCountries.sort((a) => (a.country === Countries.WW ? -1 : 0));

  return sortedCountries;
};

export const sortAvailabilityDates = (availabilityDates: AvailabilityDate[]): AvailabilityDate[] => {
  return availabilityDates.sort((a) => (a.usageType === UsageTypes.Stream ? -1 : 0));
};

export const normalizeAvailabilities = (availabilities: Availability[]): Availability[] => {
  return availabilities.map((availability: Availability) => ({
    ...availability,
    availabilityDates: sortAvailabilityDates(availability.availabilityDates),
  }));
};

export const getNextSortType = (sortType: SortTypes | null): SortTypes | null => {
  let nextSortType: SortTypes | null = null;

  if (!sortType) {
    nextSortType = SortTypes.ASC;
  }

  if (sortType === SortTypes.ASC) {
    nextSortType = SortTypes.DESC;
  }

  if (sortType === SortTypes.DESC) {
    nextSortType = null;
  }

  return nextSortType;
};

export const isUpc = (upc: string): boolean => {
  return upcPattern.test(upc);
};

export const isGrid = (grid: string): boolean => {
  return gridPattern.test(grid);
};

export const getCroppedId = (id: string) => `${id.substring(0, 3)}...${id.substring(id.length - 3, id.length)}`;

export const formatIdentifiers = (formData: IngestionTakedownFormValues): IngestionTakedownFormValues => {
  const formattedData: unknown = produce<IngestionTakedownFormValues, IngestionTakedownFormValues>(
    cloneDeep(formData),
    (draft) => {
      if (draft.grid) {
        draft.idType = Identifiers.GRID;
        draft.idValue = draft.grid;
      } else if (draft.upc) {
        draft.idType = Identifiers.UPC;
        draft.idValue = draft.upc;
      }
      delete draft.grid;
      delete draft.upc;
    }
  );

  return formattedData as IngestionTakedownFormValues;
};

export const getIconType = ({
  isAtmos = false,
  isVideo = false,
  isTrack = false,
}: {
  isAtmos?: boolean;
  isTrack?: boolean;
  isVideo?: boolean;
}) => {
  if (isAtmos) return 'atmos';
  if (isTrack) return 'track';
  if (isVideo) return 'video';
  return 'album';
};

export const normalizeArray = <T>(array: Array<T> | undefined): Array<T> => array || [];

export const normalizeBoolean = (value: boolean): boolean => {
  if (!value) return false;
  if (typeof value === 'boolean') return value;
  const v = String(value).toLowerCase();
  return v === 'true';
};
