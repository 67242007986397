import { SyntheticEvent, createRef } from 'react';

import cn from 'classnames';
import { noop } from 'lodash';

import { convertSecondsToTimestamp } from '@utils/dates';

export interface PlayerProgressBarProps {
  timestamp: number;
  total: number;
  onAbsoluteSeek?: (timestamp: number) => void;
}

const PlayerProgressBar = ({ timestamp, total, onAbsoluteSeek = noop }: PlayerProgressBarProps): JSX.Element => {
  const progressBarRef = createRef<HTMLDivElement>();

  const barCn = 'rounded-sm h-1';
  const timestampCn = 'font-light text-xs';

  const timestampString = convertSecondsToTimestamp(timestamp, 'mm:ss');
  const totalString = convertSecondsToTimestamp(total, 'mm:ss');

  const handleClick = (ev: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    if (total <= 0) return;

    const rect = progressBarRef.current?.getBoundingClientRect();
    if (!rect) return;
    const startXPosition = rect.x;
    const endXPosition = startXPosition + rect.width;
    const clickedXPosition = ev.nativeEvent.clientX;

    let position = (clickedXPosition - startXPosition) / (endXPosition - startXPosition);
    position = position < 0 ? 0 : position;
    position = position > 1 ? 1 : position;

    onAbsoluteSeek(Math.round(total * position));
  };

  return (
    <div className="mt-5 flex w-full flex-row items-center">
      <span className={cn(timestampCn)}>{timestampString}</span>
      <div
        ref={progressBarRef}
        aria-label="Progress bar container"
        className={cn(barCn, 'mx-3 w-full cursor-pointer bg-gray-300')}
        onClickCapture={handleClick}
      >
        <div
          aria-label="Progress bar"
          className={cn(barCn, 'bg-gray-600')}
          style={{ width: `${(timestamp / total) * 100}%` }}
          onClickCapture={handleClick}
        />
      </div>
      <span className={cn(timestampCn)}>{totalString}</span>
    </div>
  );
};

export default PlayerProgressBar;
