import { useState } from 'react';

import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import { Track } from '@models/Track';

import Breadcrumb, { BreadcrumbLink } from '@components/UI/Breadcrumb/Breadcrumb';
import Section from '@components/UI/Section/Section';
import Button from '@components/UI/buttons/Button/Button';

import TrackGroupContentContainer from '@pages/track-group/TrackGroupDetailPage/TrackGroupContentContainer/TrackGroupContentContainer';

export interface TrackGroupDetailContainerProps {
  tracks?: Track[];
  isViewOnly?: boolean;
  breadcrumbLinks: BreadcrumbLink[];
  isSaving?: boolean;
  onSave?: (tracks: Track[]) => void;
}

const TrackGroupDetailContainer = ({
  tracks: originalTracks = [],
  breadcrumbLinks,
  isViewOnly = false,
  isSaving = false,
  onSave = noop,
}: TrackGroupDetailContainerProps): JSX.Element => {
  const [tracks, setTracks] = useState<Track[]>(originalTracks);

  const handleSave = () => {
    onSave(tracks);
  };

  return (
    <div>
      <div className="mb-10 flex items-center justify-between">
        <Breadcrumb links={breadcrumbLinks} />
        <Link to="/track-editor">
          <Button variant="secondary">Find another track group</Button>
        </Link>
      </div>
      <TrackGroupContentContainer isViewOnly={isViewOnly} tracks={tracks} onTracksChange={setTracks} />
      {!isViewOnly && (
        <div>
          <Section className="py-8 px-6">
            <div className="flex flex-row justify-between text-sm">
              <div className="flex flex-col justify-center">
                <p className="text-xl font-semibold">Save your track group</p>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col">
                  <Button variant="secondary" onClick={noop}>
                    Cancel
                  </Button>
                </div>
                <div className="flex flex-col">
                  <Button isLoading={isSaving} onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Section>
        </div>
      )}
    </div>
  );
};

export default TrackGroupDetailContainer;
