import { Transform, Type } from 'class-transformer';
import produce, { immerable } from 'immer';
import { cloneDeep, pick } from 'lodash';

import { Countries, EMPTY_IMG, Statuses } from '@app/constants';
import {
  Availability,
  FileData,
  GenericModelFormData,
  Id,
  Identifier,
  LineData,
  LocaleData,
  LocaleName,
  Modify,
  RelatedRelease,
} from '@app/types';
import { Album } from '@models/Album';
import { ArtistFieldData, RoleArtist, roleArtistToFormData } from '@models/Artist';
import { DeliveryChannel } from '@models/DeliveryChannel';
import { GenreFieldData, OrderedGenre, orderedGenreToFormData } from '@models/Genre';
import { TrackGroup } from '@models/TrackGroup';
import {
  formatAvailabilities,
  getIdentifierValue,
  getLocaleName,
  getPrimaryAvailability,
  getPrimaryCountryData,
  getPrimaryGenre,
  isExplicit,
  normalizeAvailabilities,
  normalizeBoolean,
  sortCountriesByWw,
} from '@utils/common';
import { Identifiers } from '@utils/options';

export class TrackCountry implements ITrackCountry {
  country = Countries.WW;
  explicit = false;
  imprints: string[] = [];
  relatedReleases: RelatedRelease[] = [];
  cline?: LineData;
  pline?: LineData;

  @Type(() => OrderedGenre)
  genres: OrderedGenre[] | null = [];
}

export class Track implements ITrack {
  id!: Id;
  status!: Statuses;
  names: LocaleName[] = [];
  identifiers: Identifier[] = [{ type: Identifiers.ISRC, value: '' }];
  audioFiles: AudioFile[] = [];
  traceId = '';
  messageCreatedTimeId: Date | null = null;
  ref?: string;
  sequenceNumber?: number;
  disc?: number;
  overrideStartDate?: Date | null;
  isAtmos?: boolean;
  isAvailable?: boolean;

  @Type(() => TrackCountry)
  @Transform(({ value }: { value: TrackCountry[] }) => sortCountriesByWw(value))
  countryData: TrackCountry[] = [new TrackCountry()];

  @Transform(({ value }: { value: Availability[] }) => normalizeAvailabilities(value))
  availability: Availability[] = [];

  @Type(() => Album)
  album!: Album;

  @Type(() => TrackGroup)
  groups: TrackGroup[] = [];

  @Type(() => DeliveryChannel)
  deliveryChannel!: DeliveryChannel;

  @Type(() => RoleArtist)
  contributors: RoleArtist[] = [];

  get displayName() {
    return getLocaleName(this.names);
  }

  get primaryImageUrl(): string {
    return this.album?.primaryImageUrl || EMPTY_IMG;
  }

  get featuredArtistsName(): string {
    return RoleArtist.getFeaturedArtistsName(this.contributors);
  }

  get mainArtistsName(): string {
    return RoleArtist.getMainArtistsName(this.contributors);
  }

  get primaryCountryData(): TrackCountry {
    return getPrimaryCountryData(this.countryData);
  }

  get primaryGenre(): OrderedGenre | null {
    return getPrimaryGenre(this.primaryCountryData.genres || []);
  }

  get primaryAvailability(): Availability | null {
    return getPrimaryAvailability(this.availability);
  }

  get duration(): number {
    return this.audioFiles?.[0]?.duration || 0;
  }

  getIdentifierValue(type: Identifiers): string {
    return getIdentifierValue(type, this.identifiers);
  }

  [immerable] = true;
}

export interface ITrack {
  id: Id;
  names: LocaleName[];
  deliveryChannel: DeliveryChannel;
  album: Album;
  audioFiles: AudioFile[];
  countryData: TrackCountry[];
  identifiers: Identifier[];
  availability: Availability[];
  status: Statuses;
  ref?: string;
  disc?: number;
  traceId?: string;
  groups?: TrackGroup[];
  sequenceNumber?: number;
  messageCreatedTimeId?: Date | null;
  contributors?: RoleArtist[];
  overrideStartDate?: Date | null;
  isAtmos?: boolean;
  isAvailable?: boolean;
}

export interface ITrackCountry {
  country: Countries;
  explicit: boolean;
  imprints: string[];
  genres: OrderedGenre[] | null;
  relatedReleases: RelatedRelease[];
  cline?: LineData;
  pline?: LineData;
}

export interface AudioFile {
  fileData: FileData;
  previewDetails?: {
    duration: number;
    startPoint: number;
    expressionType: string;
  };
  duration?: number;
  fileType?: string;
  audioCodecType?: string;
  bitsPerSample?: number;
  samplingRate?: number;
  numberOfChannels?: number;
  isPreview?: boolean;
}

type EditCountry = Modify<
  TrackCountry,
  {
    genres: {
      id: Id;
      order: number;
    }[];
  }
>;

export type TrackEditData = Modify<
  Track,
  {
    countryData: EditCountry[];
    deliveryChannel: Id | null;
    groups: string[];
    album: Id | null;
    contributors: {
      id: Id;
      roles: string[];
      displayArtist: boolean;
    }[];
  }
>;

export interface TrackCountryFormData {
  country: Countries;
  imprints: { value: string }[];
  genres: GenreFieldData[] | null;
  explicit: boolean;
  relatedReleases: RelatedRelease[];
  cline?: LineData;
  pline?: LineData;
}

export interface TrackFormData {
  status: Statuses;
  names: LocaleData[];
  identifiers: Identifier[];
  countryData: TrackCountryFormData[];
  deliveryChannel: GenericModelFormData | null;
  album: GenericModelFormData | null;
  contributors: ArtistFieldData[];
  availability: Availability[];
  audioFiles: AudioFile[];
  sequenceNumber: number;
  disc: number;
}

export const getFormData = (trackData: Track): TrackFormData => {
  const formFields = pick(
    trackData,
    'status',
    'names',
    'identifiers',
    'countryData',
    'audioFiles',
    'deliveryChannel',
    'album',
    'contributors',
    'availability',
    'sequenceNumber',
    'disc',
    'ref'
  );

  const formData: unknown = produce<typeof formFields, TrackFormData>(formFields, (draft) => {
    draft.deliveryChannel = trackData.deliveryChannel && {
      id: trackData.deliveryChannel.id,
      displayName: trackData.deliveryChannel.name,
    };
    draft.album = trackData.album && {
      id: trackData.album.id,
      displayName: trackData.album.displayName,
    };
    draft.contributors = trackData.contributors.map(roleArtistToFormData);
    draft.countryData = trackData.countryData.map((country) => ({
      ...country,
      genres: country.genres?.map(orderedGenreToFormData) || null,
      imprints: country.imprints.map((imprint) => ({ value: imprint })),
    }));
  });

  return formData as TrackFormData;
};

export const formatForApi = (formData: TrackFormData): TrackEditData => {
  const formattedData: unknown = produce<TrackFormData, TrackEditData>(cloneDeep(formData), (draft) => {
    draft.deliveryChannel = formData.deliveryChannel?.id || null;
    draft.album = formData.album?.id || null;
    draft.contributors = formData.contributors?.map(({ id, roles, displayArtist }) => ({
      id,
      roles,
      displayArtist,
    }));
    draft.availability = formatAvailabilities(formData.availability);
    draft.availability.forEach((_item, idx) => {
      if (!draft.availability![idx].excludedCountries?.length) {
        Reflect.deleteProperty(draft.availability![idx], 'excludedCountries');
      }

      Reflect.deleteProperty(draft.availability![idx], 'excludedRegions');
      Reflect.deleteProperty(draft.availability![idx], 'UsageTypes');
    });

    const hasWWCountry = formData.countryData.find((value) => value.country === Countries.WW);
    const isTrackExplicit = isExplicit(draft.countryData);

    formData.countryData.forEach((country, idx) => {
      draft.countryData[idx].genres =
        country?.genres
          ?.map((el) => ({ id: el.id, order: el.order }))
          .sort((a, b) => {
            return a.order - b.order;
          }) || [];
      draft.countryData[idx].imprints = country.imprints.map((imprint) => imprint.value);
      // in case there's no WW, we check if some country has explicit true, if so we set true in all elements.
      draft.countryData[idx].explicit = hasWWCountry
        ? normalizeBoolean(draft.countryData[idx].explicit)
        : isTrackExplicit;

      Reflect.deleteProperty(draft.countryData[idx], 'excludedCountries');
      Reflect.deleteProperty(draft.countryData[idx], 'excludeRegions');

      if (!draft.countryData[idx].cline || !draft.countryData[idx].cline?.year) {
        Reflect.deleteProperty(draft.countryData[idx], 'cline');
      }

      if (!draft.countryData[idx].pline || !draft.countryData[idx].pline?.year) {
        Reflect.deleteProperty(draft.countryData[idx], 'pline');
      }
    });
  });

  return formattedData as TrackEditData;
};
