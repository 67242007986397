import axios from 'axios';
import { QueryFunctionContext, QueryKey } from 'react-query';

import { Errors } from '@app/constants';
import { MediaStreamData, MediaStreamRequestData } from '@models/File';

import { API_BASE_URL } from './constants';

export const STREAM_URL = `${API_BASE_URL}/v1/files/stream-url`;

class MediaStreamAPI {
  fetchMediaStream = async ({ queryKey }: QueryFunctionContext<QueryKey, MediaStreamData[]>) => {
    const [, { id, type }] = queryKey as ['media-stream', MediaStreamRequestData];

    try {
      const response = await axios.get(`${STREAM_URL}/${type}/${id}`);

      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) throw Error('Unexpected error');

      if (error.code === Errors.ConnectionAborted) {
        throw new Error('Connection Error');
      }

      const { statusCode } = error.response?.data || {};

      if (statusCode === Errors.NotFound || statusCode === Errors.BadRequest) {
        return null;
      }

      throw new Error('Unexpected error');
    }
  };
}

export default new MediaStreamAPI();
