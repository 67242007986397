import axios from 'axios';
import { omit } from 'lodash';

import { Errors } from '@app/constants';
import { AnyObject, PageParams, PagingType, SortParams } from '@app/types';
import { emptyPaginationData } from '@utils/pagination';

import { DEFAULT_ITEMS_PER_PAGE } from './constants';

export interface PaginationData<Model> {
  records: Model[];
  pagination: Omit<PagingType<Model>, 'records'>;
}

export default abstract class API {
  constructor(protected url: string) {}

  fetchPaginatedData = async <Model, Params extends AnyObject = AnyObject>(
    params: Params,
    pageParams: PageParams = { page: 1, itemsPerPage: DEFAULT_ITEMS_PER_PAGE, fetchAllSubsequentPages: false },
    populate: boolean | null = true,
    path?: string,
    sortParams?: SortParams,
    populateFields?: string
  ): Promise<PagingType<Model>> => {
    const { fetchAllSubsequentPages } = pageParams;

    pageParams = omit(pageParams, 'fetchAllSubsequentPages');

    const queryParams = Object.fromEntries(
      Object.entries({ ...params, populate }).filter(([, value]) => !!value || value === false)
    );
    const url = path ? `${this.url}${path}` : this.url;
    try {
      if (!fetchAllSubsequentPages) {
        const response = await axios.get(url, {
          params: {
            ...queryParams,
            page: pageParams?.page || 1,
            itemsPerPage: pageParams?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
            populateFields,
            ...sortParams,
          },
        });
        const { records, pagination } = response.data;
        return { records, ...pagination };
      }

      let pagination: PagingType<Model> | undefined;
      let records: Model[] = [];

      while (pagination === undefined || pagination.currentPage < pagination.totalPages) {
        const response = await axios.get(url, {
          params: {
            ...queryParams,
            page: pagination?.currentPage ? pagination.currentPage + 1 : 1,
            itemsPerPage: pageParams?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
            populateFields,
            ...sortParams,
          },
        });

        const { records: fetchedRecords, pagination: fetchedPagination } = response.data;
        records = [...records, ...fetchedRecords];
        pagination = fetchedPagination;
      }

      pagination.itemsPerPage = pagination.total;
      pagination.count = pagination.total;
      pagination.totalPages = 1;
      pagination.currentPage = 1;

      return { ...pagination, records };
    } catch (error) {
      if (!axios.isAxiosError(error)) throw new Error('Unexpected error');

      if (error.code === Errors.ConnectionAborted) {
        throw new Error(error.message);
      }

      const { statusCode } = error.response!.data;

      if (statusCode === Errors.BadRequest) {
        return emptyPaginationData;
      }

      throw new Error('Unexpected error');
    }
  };

  fetchData = async <Model>(id: string, populate = true): Promise<Model> => {
    const response = await axios.get<Model>(`${this.url}/${id}`, { params: { populate } });
    return response.data;
  };

  fetchRecords = async <Model, Params extends AnyObject = AnyObject>(
    params: Params,
    populate: boolean | null = true
  ): Promise<Model[]> => {
    const responseData = await this.fetchPaginatedData<Model, Params>(params, undefined, populate);
    return responseData.records;
  };
}
